import { request, service } from './';

export const commitLayerVersion = async (layerId, versionId) => {
  const uri = `/layers/${layerId}/versions/${versionId}/commit`;
  return await service('post', uri);
};

export const revertLayerVersion = async (layerId, versionId) => {
  const uri = `/layers/${layerId}/versions/${versionId}/revert`;
  return await service('post', uri);
};

export const addLayerVersion = async id => {
  const uri = `/layers/${id}/add`;
  return await service('post', uri);
};

export const getLayers = async () => {
  const uri = `/layers`;
  return await service('get', uri);
};

export const getLayer = async id => {
  const uri = `/layers/${id}`;
  return await service('get', uri);
};

export const getLayerVersions = async id => {
  const uri = `/layers/${id}/versions`;
  return await service('get', uri);
};

export const createLayer = async data => {
  const uri = `/layers`;
  return await service('post', uri, data);
};

export const deleteLayer = async id => {
  const uri = `/layers/${id}`;
  return await service('delete', uri);
};

export const updateLayer = async (id, data) => {
  const uri = `/layers/${id}`;
  return await service('put', uri, data);
};

export const signS3Asset = async body => {
  const uri = `/assets`;
  return await service('post', uri, body);
};

export const updateLayerVersion = async (id, versionId, data) => {
  const uri = `/layers/${id}/versions/${versionId}`;
  return await service('put', uri, data);
};

export const deleteLayerVersion = async (id, versionId) => {
  const uri = `/layers/${id}/versions/${versionId}`;
  return await service('delete', uri);
};

export const uploadToS3 = async (uri, data, file) => {
  try {
    const response = await request(uri, {
      method: 'post',
      body: data,
    });
    if (!response.ok) {
      const error = new Error(response.statusText);
      throw error;
    }
    return response;
  } catch (e) {
    const error = new Error(e);
    error.body = `Unexpected error while uploading ${file.name}`;
    throw error;
  }
};

export const downloadVersionShapefile = async (id, versionId) => {
  const uri = `/layers/${id}/versions/${versionId}/download`;
  return await service('get', uri);
};
