import React from 'react';
import { Radio } from 'antd';
import PropTypes from 'prop-types';

import styles from './Radio.scss';

export const RadioGroup = ({ children, label, ...props }) => {
  return (
    <div className={styles.Wrapper}>
      {label && <div className={styles.Label}>{label}</div>}
      <Radio.Group {...props}>{children}</Radio.Group>
    </div>
  );
};

RadioGroup.defaultProps = {
  label: null,
  children: null,
};

RadioGroup.propTypes = {
  label: PropTypes.node,
  children: PropTypes.node,
};

export const RadioButton = ({ children, ...props }) => {
  return <Radio.Button {...props}>{children}</Radio.Button>;
};

RadioButton.defaultProps = {
  children: null,
};

RadioButton.propTypes = {
  children: PropTypes.node,
};

export default {
  Group: RadioGroup,
  Button: RadioButton,
};
