import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';

import styles from './ItemActions.scss';
import ItemAction from './ItemAction';

export default class ItemActions extends Component {
  static propTypes = {
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
    className: PropTypes.string,
    editLabel: PropTypes.string,
    deleteLabel: PropTypes.string,
    actionStyle: PropTypes.object,
    isEditDisabled: PropTypes.func,
    isDeleteDisabled: PropTypes.func,
    actionClassName: PropTypes.string,
    item: PropTypes.object.isRequired,
    customButtons: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        label: PropTypes.node,
        onClick: PropTypes.func,
      }),
    ),
  };

  static defaultProps = {
    onEdit: null,
    onDelete: null,
    className: null,
    customButtons: [],
    actionStyle: null,
    editLabel: 'Edit',
    deleteLabel: 'Delete',
    actionClassName: null,
    isEditDisabled: () => false,
    isDeleteDisabled: () => false,
  };

  onDelete = (item, e) => {
    const { onDelete } = this.props;
    const isDeleteDisabled = this.props.isDeleteDisabled(item);
    if (isDeleteDisabled) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      onDelete(item, e);
    }
  };

  onClickCustomButton(button, disabled) {
    return (item, e) => {
      if (disabled) {
        e.preventDefault();
        e.stopPropagation();
      } else if (button && button.onClick) {
        button.onClick(item, e);
      }
    };
  }

  render() {
    const {
      item,
      onEdit,
      editLabel,
      onDelete,
      deleteLabel,
      customButtons,
      className,
      actionClassName,
      actionStyle,
    } = this.props;

    const isEditDisabled = this.props.isEditDisabled(item);
    const isDeleteDisabled = this.props.isDeleteDisabled(item);

    return (
      <div className={classNames(styles.IconButtonGroup, className)}>
        {customButtons.map(button => {
          const disabled =
            button.disabled && button.disabled.startsWith('!')
              ? !get(item, button.disabled.substring(1))
              : get(item, button.disabled);
          return (
            <ItemAction
              key={button.label}
              item={item}
              icon={button.icon}
              label={button.label}
              className={classNames(styles.IconButton, actionClassName, {
                [styles['IconButton--delete']]: button.icon === 'delete',
                [styles['IconButton--disabled']]: disabled,
              })}
              style={actionStyle}
              onClick={this.onClickCustomButton(button, disabled)}
              tooltip={disabled ? button.disabledTooltip : null}
            />
          );
        })}
        {onEdit && (
          <ItemAction
            item={item}
            icon="edit"
            label={editLabel}
            style={actionStyle}
            onClick={!isEditDisabled ? onEdit : undefined}
            className={classNames(actionClassName, styles.IconButton, {
              [styles['IconButton--disabled']]: isEditDisabled,
            })}
          />
        )}
        {onDelete && (
          <ItemAction
            item={item}
            icon="delete"
            label={deleteLabel}
            style={actionStyle}
            onClick={this.onDelete}
            className={classNames(
              actionClassName,
              styles.IconButton,
              styles['IconButton--delete'],
              { [styles['IconButton--disabled']]: isDeleteDisabled },
            )}
          />
        )}
      </div>
    );
  }
}
