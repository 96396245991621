import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../Avatar/index';
import Tooltip from '../Tooltip/index';
import Text from '../Text/index';
import styles from './AvatarSet.scss';

const AvatarSet = ({ users, limit, avatarProps }) => {
  const visibleUsers = limit ? users.slice(0, limit) : users;
  const truncateCount = limit ? users.length - limit : 0;
  return (
    <div className={styles.AvatarSet}>
      {visibleUsers.map((user, index) => {
        const name = `${user.first_name} ${user.last_name}`;
        return (
          <Tooltip key={`${user.id}_${index}`} content={name}>
            <div className={styles.Avatar} style={{ left: index * -9 }}>
              <Avatar {...avatarProps} name={name} />
            </div>
          </Tooltip>
        );
      })}
      {truncateCount > 0 && <Text className={styles.TruncateText}>+{truncateCount}</Text>}
    </div>
  );
};

AvatarSet.propTypes = {
  users: PropTypes.array.isRequired,
  limit: PropTypes.number,
  avatarProps: PropTypes.object,
};

AvatarSet.defaultProps = {
  limit: null,
  avatarProps: {},
};

export default AvatarSet;
