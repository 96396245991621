import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Tooltip as AntdTooltip } from 'antd';

export default class Tooltip extends PureComponent {
  static propTypes = {
    content: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    placement: PropTypes.string,
    overlayClassName: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    content: null,
    overlayClassName: null,
    placement: 'top',
  };

  render() {
    const { content, children, placement, overlayClassName, ...rest } = this.props;
    return (
      <AntdTooltip
        title={content}
        placement={placement}
        overlayClassName={overlayClassName}
        {...rest}
      >
        {children}
      </AntdTooltip>
    );
  }
}
