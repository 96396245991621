import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from '../Button';
import Icon from '../Icon';
import { fieldDisplayPropTypes } from './Field';
import styles from './PasswordField.scss';
import TextField from './TextField';

export default function PasswordField({ input, ...props }) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={styles.Container}>
      <TextField {...props} type={showPassword ? 'text' : 'password'} input={input} />
      <Button
        className={styles.Button}
        type={Button.INVISIBLE}
        htmlType="button"
        aria-label="Toggle Visibility"
        onClick={() => setShowPassword(!showPassword)}
      >
        {showPassword && <Icon name="toggle_visibility_off" />}
        {!showPassword && <Icon name="toggle_visibility_on" />}
      </Button>
    </div>
  );
}

PasswordField.propTypes = {
  ...fieldDisplayPropTypes,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }).isRequired,
};
