import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon/index';
import Text from '../Text/index';
import Tooltip from '../Tooltip/index';
import styles from './InfoTooltip.scss';

const InfoTooltip = ({
  className,
  content,
  color,
  fullWidthHover,
  icon,
  iconClassName,
  textClassName,
  title,
  placement,
  textSize,
  tooltipSize,
  ...tooltipProps
}) => {
  const titleComponent = title && (
    <Text
      color={color}
      weight="medium"
      as="span"
      size={textSize}
      heading
      className={textClassName}
    >
      {title}
    </Text>
  );

  const iconComponent = (
    <Icon
      className={classnames(styles.Tooltip__Info, iconClassName)}
      name={icon}
      size={tooltipSize}
    />
  );

  if (fullWidthHover) {
    return (
      <Tooltip content={content} placement={placement} {...tooltipProps}>
        <div className={classnames(styles.Container, styles.Pointer, className)}>
          {titleComponent}
          {iconComponent}
        </div>
      </Tooltip>
    );
  }
  return (
    <div className={classnames(styles.Container, className)}>
      {titleComponent}
      <Tooltip content={content} placement={placement} {...tooltipProps}>
        {iconComponent}
      </Tooltip>
    </div>
  );
};

InfoTooltip.propTypes = {
  className: PropTypes.string,
  color: Text.propTypes.color,
  content: PropTypes.any.isRequired,
  fullWidthHover: PropTypes.bool,
  icon: PropTypes.string,
  iconClassName: PropTypes.string,
  textClassName: PropTypes.string,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  title: PropTypes.string,
  textSize: Text.propTypes.size,
  tooltipSize: PropTypes.oneOf(['smaller', 'small', 'medium', 'large']),
};

InfoTooltip.defaultProps = {
  className: null,
  color: 'inherit',
  fullWidthHover: false,
  icon: 'tooltip',
  iconClassName: null,
  textClassName: null,
  placement: 'top',
  textSize: 'sm',
  title: null,
  tooltipSize: 'smaller',
};

export default InfoTooltip;
