import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import PhoneInput from 'react-phone-number-input';
import Field from './Field';
import 'react-phone-number-input/style.css';
import styles from './MaskedPhoneField.scss';

const MaskedPhoneField = ({
  input,
  className,
  placeholder,
  defaultCountry,
  countries,
  initialValueFormat,
  ...props
}) => (
  <Field {...props} className={classnames(styles.PhoneField, className)}>
    <PhoneInput
      placeholder={placeholder}
      initialValueFormat={initialValueFormat}
      countries={countries}
      defaultCountry={defaultCountry}
      {...input}
      onBlur={() => {
        input.onBlur();
      }}
    />
  </Field>
);

MaskedPhoneField.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }).isRequired,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.node,
  defaultCountry: PropTypes.string,
  initialValueFormat: PropTypes.string,
  countries: PropTypes.array,
};

MaskedPhoneField.defaultProps = {
  className: null,
  readOnly: false,
  disabled: false,
  placeholder: '',
  defaultCountry: 'US',
  initialValueFormat: 'national',
  countries: ['US', 'CA'],
};

export default MaskedPhoneField;
