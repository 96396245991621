import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import FieldError from '../FieldError/index';
import InfoTooltip from '../InfoTooltip/index';
import Option from './Option';
import styles from './MultiOptionPickerField.scss';

const MultiOptionPickerField = props => {
  const {
    id,
    className,
    componentLabel,
    disabled,
    errorPosition,
    input,
    label,
    labelTooltip,
    meta,
    options,
    optionClassName,
    selectedOptionClassName,
    showTick,
    single,
    spaceEvenly,
    direction,
  } = props;

  const errorContent = (
    <FieldError
      error={meta && meta.touched && meta.error}
      warning={meta && meta.touched && meta.warning}
    />
  );

  return (
    <div
      className={classnames(
        styles.MultiOptionPickerField,
        {
          [styles[`MultiOptionPickerField--Disabled`]]: disabled,
          [styles[`MultiOptionPickerField--ColumnDirection`]]: direction === 'column',
        },
        className,
      )}
    >
      {label && (
        <label htmlFor={id} className={styles.Label}>
          <span className={styles.Label__Wrapper}>
            <span>
              {label}
              {labelTooltip && (
                <InfoTooltip
                  className={styles.Label__Tooltip}
                  color="blue"
                  content={labelTooltip}
                />
              )}
            </span>
          </span>
        </label>
      )}
      {componentLabel}
      {errorPosition === 'top' && errorContent}
      <ul className={styles.OptionList}>
        {options.map(option => (
          <li
            key={option.value}
            className={classnames(styles.OptionList__Item, {
              [styles['OptionList__Item--evenly']]: spaceEvenly,
            })}
          >
            <Option
              className={optionClassName}
              selectedClassName={selectedOptionClassName}
              {...option}
              onClick={() => {
                let { value: values } = input;

                if (single) {
                  input.onChange([option.value]);
                } else {
                  // Determine whether to add or remove the value
                  if (values.includes(option.value)) {
                    values = values.filter(item => item !== option.value);
                  } else {
                    values = [...values, option.value];
                  }

                  input.onChange(values);
                }
              }}
              isSelected={input.value.includes(option.value)}
              showTick={showTick}
            />
          </li>
        ))}
      </ul>
      {errorPosition === 'bottom' && errorContent}
    </div>
  );
};

MultiOptionPickerField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  componentLabel: PropTypes.string,
  disabled: PropTypes.bool,
  errorPosition: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  label: PropTypes.string,
  labelTooltip: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.any,
    warning: PropTypes.bool,
  }),
  options: PropTypes.array,
  optionClassName: PropTypes.string,
  selectedOptionClassName: PropTypes.string,
  showTick: PropTypes.bool,
  single: PropTypes.bool,
  spaceEvenly: PropTypes.bool,
  direction: PropTypes.oneOf(['row', 'column']),
};

MultiOptionPickerField.defaultProps = {
  id: `MultiOptionPickerField_${Math.random()}`,
  className: null,
  componentLabel: null,
  disabled: false,
  errorPosition: 'bottom',
  input: {},
  label: null,
  labelTooltip: null,
  meta: {},
  options: [],
  optionClassName: null,
  selectedOptionClassName: null,
  showTick: true,
  single: false,
  spaceEvenly: false,
  direction: 'row',
};

export default MultiOptionPickerField;
