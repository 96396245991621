import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './AddressView.scss';

const CUT_OFF_MARGIN = 30;
const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;
const DOMAIN = 'https://maps.googleapis.com/maps/api/staticmap';

const createParameters = (width, height, lat, lng) => {
  return `size=${width}x${
    height + CUT_OFF_MARGIN
  }&center=${lat},${lng}&maptype=satellite&zoom=18&key=${API_KEY}`;
};

const AddressView = ({ className, lat, lng, width, height }) => {
  const parameters = createParameters(width, height, lat, lng);
  return (
    <div style={{ width, height }} className={classnames(styles.StreetView, className)}>
      <img src={`${DOMAIN}?${parameters}`} alt="Address" />
    </div>
  );
};

AddressView.propTypes = {
  className: PropTypes.string,

  /** The latitude of the address */
  lat: PropTypes.number.isRequired,

  /** The longitude of the address */
  lng: PropTypes.number.isRequired,

  /** The width of the street view image */
  width: PropTypes.number.isRequired,

  /** The width of the street view image */
  height: PropTypes.number.isRequired,
};

AddressView.defaultProps = {
  className: null,
};

export default AddressView;
