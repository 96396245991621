import React from 'react';
import MarkdownEditor from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';
import styles from './MarkdownEditor.scss';

export default props => {
  return (
    <MarkdownEditor
      options={{
        status: false,
        minHeight: '130px',
        toolbar: [
          'bold',
          'italic',
          'heading',
          '|',
          'unordered-list',
          'ordered-list',
          '|',
          'preview',
        ],
        tabIndex: 0,
        previewClass: 'editor-preview prose max-w-none',
      }}
      className={styles.Editor}
      {...props}
    />
  );
};
