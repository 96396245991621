import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon/index';
import styles from './Warning.scss';

export default class Warning extends PureComponent {
  static propTypes = {
    color: PropTypes.oneOf(['grey', 'orange']),
    children: PropTypes.node,
  };

  static defaultProps = {
    color: 'grey',
    children: null,
  };

  render() {
    const { color } = this.props;
    return (
      <div className={classnames(styles.Warning, styles[`Warning--${color}`])}>
        <div className={styles.Icon}>
          <Icon name="warning" />
        </div>
        <div className={styles.Content}>{this.props.children}</div>
      </div>
    );
  }
}
