import classNames from 'classnames';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import FieldError from '../FieldError';
import Text from '../Text/index';
import AttachmentBlock from './AttachmentBlock';
import ButtonGroup from './ButtonGroup';
import InlineAttachmentAction from './InlineAttachmentAction';
import LinkActions from './LinkActions';
import LinkDecorator from './LinkDecorator';
import styles from './RichTextField.scss';
import StyleActions from './StyleActions';
import { ContentStateConverter, HTMLConverter } from './transformer';

export function customBlockRenderFunc(block, config) {
  const blockType = block.getType();
  if (blockType === 'atomic') {
    const contentState = config.getEditorState().getCurrentContent();
    const entity = contentState.getEntity(block.getEntityAt(0));
    if (entity && entity.type === 'ATTACHMENT') {
      return {
        editable: false,
        component: AttachmentBlock,
      };
    }
  } else if (blockType === 'unstyled' && block.text === '') {
    // Check to see if this is an empty line-break in between image attachments.
    const contentState = config.getEditorState().getCurrentContent(block.key);
    const beforeBlock = contentState.getBlockAfter(block.key);
    const afterBlock = contentState.getBlockAfter(block.key);
    if (
      beforeBlock &&
      beforeBlock.getType() === 'atomic' &&
      afterBlock &&
      afterBlock.getType() === 'atomic'
    ) {
      return {
        editable: false,
        defaultBlockTag: null,
        component: () => null,
      };
    }
  }
  return undefined;
}

const TOOLBAR_OPTIONS = {
  options: ['inline', 'list'],
  inline: {
    component: ButtonGroup,
    options: ['bold', 'italic'],
  },
  list: {
    component: ButtonGroup,
    options: ['unordered', 'ordered'],
  },
};

export default class RichTextField extends Component {
  static propTypes = {
    id: PropTypes.string,
    meta: PropTypes.object,
    input: PropTypes.object,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    errorWeight: PropTypes.oneOf(['thin', 'heavy']),
    showRequired: PropTypes.bool,
    showOptionalTag: PropTypes.bool,
    enableAttachments: PropTypes.bool,
    onCreateAttachment: PropTypes.func,
    onFetchAttachments: PropTypes.func,
    inter: PropTypes.bool,
  };

  static defaultProps = {
    id: null,
    label: null,
    input: { value: '', onChange: () => {} },
    disabled: false,
    errorWeight: 'heavy',
    showRequired: false,
    showOptionalTag: false,
    enableAttachments: false,
    onCreateAttachment: () => false,
    onFetchAttachments: () => false,
    meta: { error: null, touched: false, pristine: true },
    inter: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      editorState: this.createEditorState(props.input.value),
    };
  }

  componentDidMount() {
    if (this.props.enableAttachments) {
      this.props.onFetchAttachments();
    }
  }

  componentWillReceiveProps(nextProps) {
    const { meta, input } = nextProps;
    const { input: oldInput } = this.props;
    if (meta.pristine && input.value !== oldInput.value) {
      if (nextProps.enableAttachments) {
        this.props.onFetchAttachments();
      }
      this.setState({
        editorState: this.createEditorState(input.value),
      });
    }
  }

  createEditorState(value) {
    return EditorState.createWithContent(convertFromHTML(HTMLConverter)(value));
  }

  onEditorStateChange = editorState => {
    this.setState({ editorState }, () => {
      const content = editorState.getCurrentContent();
      const html = convertToHTML(ContentStateConverter)(content);
      this.props.input.onChange(html);
    });
  };

  render() {
    const {
      meta,
      input,
      disabled,
      showRequired,
      enableAttachments,
      onCreateAttachment,
      showOptionalTag,
      errorWeight,
      inter,
    } = this.props;
    const { editorState } = this.state;
    const focused = meta && meta.active;
    const toolbarButtons = [<LinkActions />, <StyleActions disabled={disabled} />];
    const attachmentButtons = [
      <InlineAttachmentAction
        onCreateAttachment={onCreateAttachment}
        onFetchAttachments={this.props.onFetchAttachments}
      />,
    ];

    const toolbarCustomButtons = enableAttachments
      ? toolbarButtons.concat(attachmentButtons)
      : toolbarButtons;

    return (
      <div className={styles.Wrapper}>
        {this.props.label ? (
          <div className={styles.HeadingWrapper}>
            <label htmlFor={this.props.id} className={styles.Label}>
              <Text size="medium" weight="medium" color="grey" as="span" uppercase>
                {this.props.label}
                {showRequired && <span className={styles.Required}>*</span>}
              </Text>
            </label>
            {showOptionalTag && <span className={styles.HeadingOptional}>Optional</span>}
          </div>
        ) : null}
        <div
          className={classNames(styles.RichTextField, {
            [styles.Focused]: focused,
            [styles.Error]: Boolean(meta && meta.touched && meta.error),
            [styles.Warning]: Boolean(meta && meta.touched && meta.warning),
          })}
        >
          <Editor
            spellCheck={true}
            onBlur={input.onBlur}
            onFocus={input.onFocus}
            editorState={editorState}
            toolbar={TOOLBAR_OPTIONS}
            editorClassName={classNames(
              styles.Input,
              { [styles.Disabled]: disabled },
              { 'font-sans normal-nums': inter },
            )}
            toolbarClassName={classNames(styles.Toolbar, { [styles.Disabled]: disabled })}
            customDecorators={[LinkDecorator()]}
            customBlockRenderFunc={customBlockRenderFunc}
            onEditorStateChange={this.onEditorStateChange}
            toolbarCustomButtons={toolbarCustomButtons}
            handlePastedText={() => false} // intentionally noop to prevent incorrect paste formatting
            readOnly={disabled}
            required={showRequired}
          />
        </div>
        <FieldError
          error={meta && meta.touched && meta.error}
          warning={meta && meta.touched && meta.warning}
          thin={errorWeight === 'thin'}
        />
      </div>
    );
  }
}
