import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getSelectionEntity } from 'draftjs-utils';
import { getVisibleSelectionRect, RichUtils, EditorState } from 'draft-js';
import classnames from 'classnames';
import Icon from '../Icon/index';
import TextField from '../ReduxFormFields/TextField';
import IconButton from '../IconButton/index';
import { URL_REGEX } from '../../shared/utils/validation';
import styles from './RichTextField.scss';

export function getEditorPosition(dimensions) {
  if (!dimensions) return {};
  const { top } = dimensions;
  const left = dimensions.left + dimensions.width / 2;
  return { top, left };
}

const DEFAULT_EDITOR_STATE = {
  url: '',
  rect: null,
  showEditor: false,
  selectedKey: null,
  error: null,
  touched: false,
};

const validate = value => {
  if (value && !URL_REGEX.test(value)) {
    return "Invalid URL, make sure to include 'http/https/ftp'.";
  }
  return null;
};

export default class LinkActions extends Component {
  static propTypes = {
    editorState: PropTypes.object,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    editorState: undefined,
    onChange: undefined,
  };

  state = DEFAULT_EDITOR_STATE;

  componentWillReceiveProps(props) {
    this.onRequestOpen(props);
  }

  onChange = e => {
    const error = validate(e.target.value);
    this.setState({ url: e.target.value, error, touched: true });
  };

  onRequestOpen = props => {
    const { showEditor } = this.state;
    const { editorState } = props;
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const entityKey = getSelectionEntity(editorState);
    if (!showEditor && entityKey) {
      if (!selection.isCollapsed() && selection.getHasFocus()) {
        const entity = contentState.getEntity(entityKey);
        const { url } = entity.getData();
        const rect = getVisibleSelectionRect(window);
        if (rect && rect.width > 0) {
          this.setState({
            url,
            rect,
            showEditor: true,
            selectedKey: entityKey,
          });
        }
      }
    }
  };

  onRequestClose = () => {
    const { selectedKey, error } = this.state;
    const { editorState } = this.props;
    const contentState = editorState.getCurrentContent();
    const { url } = contentState.getEntity(selectedKey).getData();
    const invalidLink = error !== null || url.trim() === '';

    this.setState(DEFAULT_EDITOR_STATE, () => {
      if (invalidLink) {
        this.breakLink();
      }
    });
  };

  createLink = () => {
    const { url } = this.state;
    const { editorState, onChange } = this.props;
    const selection = editorState.getSelection();
    const rect = getVisibleSelectionRect(window);
    if (!selection.isCollapsed()) {
      const entityKey = editorState
        .getCurrentContent()
        .createEntity('LINK', 'MUTABLE', { url, id: 'remove-me' })
        .getLastCreatedEntityKey();
      if (!selection.isCollapsed()) {
        this.setState(
          {
            rect,
            showEditor: true,
            selectedKey: entityKey,
          },
          () => {
            onChange(
              RichUtils.toggleLink(editorState, editorState.getSelection(), entityKey),
            );
          },
        );
      }
    }
  };

  breakLink = () => {
    const { editorState, onChange } = this.props;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      this.setState(DEFAULT_EDITOR_STATE, () => {
        onChange(RichUtils.toggleLink(editorState, selection, null));
      });
    }
  };

  saveLink = e => {
    const { editorState, onChange } = this.props;
    const { selectedKey, url, error } = this.state;
    const disabled = error || !url;

    if (disabled) {
      e.preventDefault();
      return;
    }

    const contentState = editorState.getCurrentContent();
    const newContentState = contentState.replaceEntityData(selectedKey, { url });
    this.setState(DEFAULT_EDITOR_STATE, () => {
      onChange(EditorState.push(editorState, newContentState, 'activate-entity-data'));
    });
  };

  render() {
    const { showEditor, url, rect, error, touched } = this.state;
    const disabled = error !== null || url === '';
    return (
      <div className={styles.ButtonGroup}>
        <div
          alt="create-link"
          role="button"
          tabIndex="0"
          onClick={this.createLink}
          className={styles.ButtonGroupItem}
        >
          <Icon name="create_link" />
        </div>
        <div
          alt="break-link"
          role="button"
          tabIndex="0"
          onClick={this.breakLink}
          className={styles.ButtonGroupItem}
        >
          <Icon name="break_link" />
        </div>
        <ReactModal
          isOpen={showEditor}
          ariaHideApp={false}
          className={styles.LinkEditorBase}
          onRequestClose={this.onRequestClose}
          overlayClassName={styles.LinkEditorOverlay}
        >
          <div className={styles.LinkEditor} style={getEditorPosition(rect)}>
            <div className={styles.LinkEditor_Input}>
              <TextField
                errorPosition="bottom"
                meta={{ error, touched }}
                input={{
                  value: url,
                  ref: input => input && input.focus(),
                  placeholder: 'Enter a URL',
                  onChange: this.onChange,
                }}
              />
            </div>
            <div
              className={classnames(styles.LinkEditor_Button, {
                [styles['LinkEditor_Button--disabled']]: disabled,
              })}
            >
              <IconButton
                icon="create_link"
                disabled={disabled}
                onClick={this.saveLink}
              />
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}
