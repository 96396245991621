import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon/index';
import Text from '../Text/index';
import styles from './FieldError.scss';

const FieldError = ({ error, warning, thin, showIcon, className, iconClassName }) => {
  const showErrorText = typeof error === 'string' || typeof warning === 'string';
  return (
    <div
      className={classnames(
        styles.FieldError,
        {
          [styles.Error]: error,
          [styles.Warning]: warning,
          [styles['FieldError--showing']]: (error || warning) && showErrorText,
          [styles.Heavy]: !thin,
        },
        className,
      )}
    >
      {!thin && showIcon && (
        <Icon className={classnames(styles.Icon, iconClassName)} name="warning_fill" />
      )}
      <Text className={styles.Text} size="sm" color={thin ? 'inherit' : 'dark-charcoal'}>
        {error || warning}
      </Text>
    </div>
  );
};

FieldError.propTypes = {
  error: PropTypes.node,
  warning: PropTypes.node,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  thin: PropTypes.bool,
  showIcon: PropTypes.bool,
};

FieldError.defaultProps = {
  error: null,
  warning: null,
  className: null,
  iconClassName: null,
  thin: false,
  showIcon: true,
};

export default FieldError;
