import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Heading from '../Heading/index';
import TextField from '../ReduxFormFields/TextField';
import styles from './URLField.scss';

export default class URLField extends PureComponent {
  static propTypes = {
    domain: PropTypes.string,
    input: PropTypes.object,
    label: PropTypes.string,
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    domain: '',
    input: {},
    label: '',
    readOnly: false,
  };

  render() {
    return (
      <div className={styles.URLField__Container}>
        {this.props.label ? (
          <label>
            <Heading size="small" color="grey">
              {this.props.label}
            </Heading>
          </label>
        ) : null}
        <div className={styles.URLField__Inner}>
          <div className={styles.URLField__Domain}>{this.props.domain}</div>
          <div className={styles.URLField__TextField}>
            <TextField
              input={{
                ...this.props.input,
                readOnly: this.props.readOnly,
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
