import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { DatePicker as AntdDatePicker } from 'antd';
import styles from './RangePicker.scss';

const AntdRangePicker = AntdDatePicker.RangePicker;

export default function RangePicker({ dropdownClassName, ...props }) {
  return (
    <AntdRangePicker
      dropdownClassName={classNames(styles.RangePickerDropdown, dropdownClassName)}
      placeholder=""
      {...props}
    />
  );
}

RangePicker.propTypes = {
  dropdownClassName: PropTypes.string,
};

RangePicker.defaultProps = {
  dropdownClassName: null,
};
