import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';
import CircleIcon from '../CircleIcon';
import Text from '../Text';
import styles from './BackLink.scss';

const BackLink = ({
  backText,
  backUrl,
  className,
  history,
  icon,
  onClick,
  iconClassName,
}) => (
  <div
    className={classnames(styles.BackLink, className)}
    onClick={() => {
      if (onClick) {
        onClick();
      } else if (history) {
        history.push(backUrl);
      }
    }}
    role="button"
    tabIndex="0"
  >
    <div className={styles.Back__Icon}>
      <CircleIcon name={icon} type="inherit" noPadding iconClassName={iconClassName} />
    </div>
    <Text className={styles.Back__Text} size="md" weight="medium" color="grey">
      {backText}
    </Text>
  </div>
);

BackLink.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  backText: PropTypes.any.isRequired,
  backUrl: PropTypes.string,
  history: PropTypes.object,
  onClick: PropTypes.func,
  iconClassName: PropTypes.string,
};

BackLink.defaultProps = {
  className: null,
  backUrl: null,
  history: null,
  icon: 'arrow_left',
  onClick: null,
  iconClassName: null,
};

export default withRouter(BackLink);
