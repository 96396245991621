import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Spinner from '../Spinner/index';
import FileField from '../FileField/index';
import { fileMatchSize, fileAccepted } from '../../shared/utils/validation';
import styles from './AttachmentUpload.scss';

const SUPPORTED_MIME =
  'image/jpeg, image/png, application/pdf, application/zip, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/msexcel, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

const validate = value => {
  if (value && typeof value === 'object') {
    const array = [...value];
    if (!array.every(file => fileAccepted(file, SUPPORTED_MIME))) {
      return 'Invalid file type. Must be PNG, JPG, PDF, ZIP, DOC or XLS';
    } else if (!array.every(file => fileMatchSize(file, 1e8, 0))) {
      return 'File is too big. Must be smaller than 100mb.';
    }
  }

  return null;
};

export default class AttachmentUpload extends Component {
  static propTypes = {
    onFetch: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  state = {
    value: '',
    error: null,
    touched: false,
    inProgress: false,
  };

  onSubmit = async () => {
    try {
      this.setState({ inProgress: true, touched: true });
      await this.props.onSubmit(this.state.value);
      await this.props.onFetch();
      this.setState({ value: '', error: null, inProgress: false });
    } catch (exception) {
      let error = 'There was an unknown error';
      const { errors } = exception;

      if (errors) {
        error = errors.map(error => error.error)[0]; // eslint-disable-line
      }

      this.setState({ value: '', error, inProgress: false });
    }
  };

  onChange = data => {
    const error = validate(data);
    const value = error ? '' : data;
    this.setState({ value, error, touched: true });
  };

  render() {
    const { inProgress, error, value, touched } = this.state;
    return (
      <div className={styles.AttachmentUpload}>
        {inProgress ? (
          <div className={styles.AttachmentSpinner}>
            <Spinner />
          </div>
        ) : null}
        <div>
          <FileField
            {...this.props}
            enableButton={true}
            meta={{ error, touched }}
            onClick={this.onSubmit}
            input={{
              value,
              onBlur: this.onChange,
              onFocus: this.onChange,
              onChange: this.onChange,
            }}
          />
        </div>
      </div>
    );
  }
}
