import { DatePicker as AntdDatePicker } from 'antd';
import classnames from 'classnames';
import React from 'react';
import styles from './DatePicker.scss';

export default function DatePicker({ dropdownClassName, ...props }) {
  return (
    <AntdDatePicker
      placeholder=""
      {...props}
      inputReadOnly
      dropdownClassName={classnames(styles.Dropdown, dropdownClassName)}
    />
  );
}
