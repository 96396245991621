import React from 'react';
import PropTypes from 'prop-types';
import { InfiniteLoader, List, AutoSizer } from 'react-virtualized';
import { Skeleton } from 'antd';

const renderRowWithScrollingIndicator = rowRenderer => rowProps => {
  const { isScrolling, key, style } = rowProps;
  if (isScrolling) {
    return (
      <div key={key} style={style}>
        <Skeleton />
      </div>
    );
  }

  return rowRenderer(rowProps);
};

export default function InfiniteList({
  overscanRowCount,
  rowCount,
  rowHeight,
  rowRenderer,
  noRowsRenderer,
  isRowLoaded,
  loadMoreRows,
}) {
  return (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows}
          rowCount={rowCount}
        >
          {({ onRowsRendered, registerChild }) => (
            <List
              height={height}
              width={width}
              ref={registerChild}
              onRowsRendered={onRowsRendered}
              overscanRowCount={overscanRowCount}
              rowRenderer={renderRowWithScrollingIndicator(rowRenderer)}
              noRowsRenderer={noRowsRenderer}
              rowCount={rowCount}
              rowHeight={rowHeight}
            />
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
}

InfiniteList.propTypes = {
  rowCount: PropTypes.number.isRequired,
  rowHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.func]).isRequired,
  rowRenderer: PropTypes.func.isRequired,
  isRowLoaded: PropTypes.func.isRequired,
  loadMoreRows: PropTypes.func.isRequired,
  noRowsRenderer: PropTypes.func,
  overscanRowCount: PropTypes.number,
};

InfiniteList.defaultProps = {
  overscanRowCount: 10,
  noRowsRenderer: () => <span>No data!</span>,
};
