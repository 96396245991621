import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Text.scss';

const sizeClassnameMap = {
  xlarge: styles.XLarge,
  larger: styles.Larger,
  large: styles.Large,
  medium: styles.Medium,
  small: styles.Small,
  // Oversized
  xl6: styles.xl6,
  xl5: styles.xl5,
  // Headings
  xl4: styles.xl4,
  xl3: styles.xl3,
  xl2: styles.xl2,
  xl: styles.xl,
  // Body
  lg: styles.lg,
  'md-lg': styles['md-lg'],
  md: styles.md,
  sm: styles.sm,
  xs: styles.xs,
  xxs: styles.xxs,
};

const weightClassnameMap = {
  medium: styles.medium,
  thin: styles.thin,
  normal: styles.normal,
  'medium-bold': styles['medium-bold'],
  bold: styles.bold,
};

const colorClassnameMap = {
  'dark-blue': styles.DarkBlue,
  'light-blue': styles.LightBlue,
  blue: styles.Blue,
  'light-grey': styles.LightGrey,
  grey: styles.Grey,
  'dark-grey': styles.DarkGrey,
  'dark-charcoal': styles.DarkCharcoal,
  white: styles.White,
  dark: styles.Dark,
  red: styles.Red,
  green: styles.Green,
  orange: styles.Orange,
  inherit: styles.Inherit,
};

export default class Text extends Component {
  static propTypes = {
    /* Render a semantic html as the rendering element */
    as: PropTypes.oneOf([
      'a',
      'div',
      'p',
      'span',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'label',
    ]),
    children: PropTypes.node,
    color: PropTypes.oneOf([...Object.keys(colorClassnameMap), null]),
    fullWidth: PropTypes.bool,
    heading: PropTypes.bool,
    uppercase: PropTypes.bool,
    italic: PropTypes.bool,
    size: PropTypes.oneOf(Object.keys(sizeClassnameMap)),
    weight: PropTypes.oneOf(Object.keys(weightClassnameMap)),
    className: PropTypes.string,
  };

  static defaultProps = {
    as: 'div',
    children: null,
    className: null,
    color: 'dark-blue',
    fullWidth: false,
    heading: false,
    uppercase: false,
    italic: false,
    size: 'md',
    weight: null,
  };

  render() {
    const {
      as,
      color,
      size,
      className,
      heading,
      weight,
      italic,
      uppercase,
      fullWidth,
      ...rest
    } = this.props;
    return React.createElement(as, {
      className: classNames(
        styles.Text,
        colorClassnameMap[color],
        sizeClassnameMap[size],
        weightClassnameMap[weight],
        {
          [styles.Heading]: heading,
          [styles.Italic]: italic,
          [styles.FullWidth]: fullWidth,
          [styles.Uppercase]: uppercase,
        },
        className,
      ),
      ...rest,
    });
  }
}
