import classNames from 'classnames';
import React, { Component } from 'react';
import { Icon as AntDIcon } from 'antd';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import styles from './Icon.scss';

export const names = [
  'activity',
  'action_fee',
  'action_form',
  'action_inspection',
  'action_general',
  'action_none',
  'action_read',
  'action_upload',
  'action_info',
  'action_collection',
  'action_issue_document',
  'add',
  'add_below',
  'add_circle_blue',
  'address',
  'agency',
  'analytics',
  'applicant_fill',
  'archive_fill',
  'archive',
  'arrow_circle_left',
  'arrow_circle_right',
  'arrow_down',
  'arrow_left',
  'arrow_left_long',
  'arrow_left_short',
  'arrow_right',
  'arrow_right_long',
  'arrow_up',
  'attachments',
  'bold',
  'bookmark',
  'bubble',
  'break_link',
  'browse',
  'calendar',
  'calendar_today',
  'camino_admins',
  'cancel',
  'caret_circle_down',
  'caret_circle_up',
  'caret_first',
  'caret_last',
  'caret_left',
  'caret_right',
  'cash',
  'change',
  'chat',
  'check',
  'checked_box',
  'close_menu',
  'collapse',
  'comment',
  'complete',
  'connection',
  'copy',
  'create_link',
  'cross_fill',
  'delete',
  'department',
  'development',
  'discard',
  'dollar',
  'dollar-circle',
  'download',
  'dropdown',
  'edit',
  'email',
  'enter',
  'environment_user',
  'estimates',
  'expand',
  'export',
  'fax',
  'field_type_signature',
  'field_type_phone',
  'field_type_text',
  'field_type_date',
  'field_type_boolean',
  'field_type_checkbox',
  'field_type_numeric',
  'field_type_email',
  'field_type_radio',
  'field_type_currency',
  'filter',
  'folder',
  'folder_fill',
  'guide_builder',
  'grabber',
  'help',
  'help_inverse',
  'hide',
  'home',
  'image',
  'import',
  'in_progress',
  'inspections',
  'incomplete',
  'info',
  'info_fill',
  'instructions',
  'italic',
  'jump',
  'key',
  'link',
  'location_fill',
  'location_stroke',
  'lock',
  'lock_fill',
  'lock_group',
  'logout',
  'medal',
  'move',
  'not_allowed',
  'not_required',
  'note',
  'notification_bell',
  'notification_warning',
  'open_menu',
  'option_tick_fill',
  'option_tick',
  'ordered',
  'percentage',
  'phone',
  'picture',
  'preview_guide',
  'print',
  'production',
  'project_type',
  'question_selected',
  'questions',
  'recipient',
  'recording_on',
  'recording_off',
  'reorder',
  'report',
  'round_arrow',
  'save',
  'search',
  'send_email',
  'submissions',
  'settings',
  'share',
  'sort',
  'sort_column',
  'staging',
  'start',
  'switch',
  'tasks',
  'tick_select',
  'tick_fill',
  'time',
  'timeline',
  'too_complex',
  'tooltip',
  'triangle_left',
  'triangle_right',
  'tree',
  'tree_branch',
  'unordered',
  'user',
  'users',
  'view',
  'warning',
  'warning_fill',
  'zoom',
  'website',
  'file',
  'details',
  'map',
  'authorized',
  'buildings',
  'permit',
  'license',
  'certificate',
  'document',
  'documents',
  'dot_marker',
  'pin',
  'settings_cog',
  'scroll',
  'void',
  'void_alt',
  'accepting_applications',
  'guide_only',
  'hidden',
  'toggle_visibility_off',
  'toggle_visibility_on',
  'workflow',
  'toggle_visible_off',
  'gis_loader',
  'gis_rest',
  'gis_shapefile',
  'gis_zip',
  'upload',
];

const baseIconStyle = {
  fill: 'none',
  stroke: 'currentColor',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
  strokeWidth: 2,
};

export default class Icon extends Component {
  static propTypes = {
    name: PropTypes.oneOf(names).isRequired,
    size: PropTypes.oneOfType([
      PropTypes.oneOf(['smaller', 'small', 'medium', 'large', 'xlarge']),
      PropTypes.number,
    ]),
    className: PropTypes.string,
    useAntDIcon: PropTypes.bool,
    antDFilled: PropTypes.bool,
  };

  static defaultProps = {
    size: 'small',
    className: null,
    useAntDIcon: false,
    antDFilled: false,
  };

  renderIcon(name) {
    if (this.props.useAntDIcon) {
      return <AntDIcon type={name} theme={this.props.antDFilled ? 'filled' : null} />;
    }

    switch (name) {
      case 'activity':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <polyline points="20 12 16.92 12 14.45 20 9.55 4 7.08 12 4 12" />
            </g>
          </svg>
        );
      case 'action_fee':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M10.28,9.75c0,.37.38.58.93.72V9C10.6,9,10.28,9.32,10.28,9.75Z" />
              <path d="M12.14,12.53v1.68c.68-.09,1-.47,1-.86S12.71,12.7,12.14,12.53Z" />
              <path d="M11.57,4.57a7,7,0,1,0,7,7A7,7,0,0,0,11.57,4.57Zm.68,11.12v1.09H11.11V15.7a4.39,4.39,0,0,1-2.83-1.25l.92-1.29a3.64,3.64,0,0,0,1.91,1V12.29c-1.24-.3-2.58-.78-2.58-2.4a2.55,2.55,0,0,1,2.58-2.41V6.37h1.14V7.51a4.11,4.11,0,0,1,2.43,1.07l-.95,1.24A3.42,3.42,0,0,0,12.25,9v1.68c1.24.33,2.62.81,2.62,2.44C14.87,14.48,14,15.51,12.25,15.69Z" />
            </g>
          </svg>
        );
      case 'action_form':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M18.74,5.94l-.66-.67a.87.87,0,0,0-1.26,0l-8.3,8.49-.77,2.76,2.7-.79,8.29-8.49A.94.94,0,0,0,18.74,5.94Z" />
              <path d="M10.87,17.17,8.17,18A1.5,1.5,0,0,1,6.3,16.12l.78-2.77a1.58,1.58,0,0,1,.37-.64L9.12,11H7V10h3.1l2-2H7V7h6V7l2-2H6A1,1,0,0,0,5,6V18a1,1,0,0,0,1,1H16a1,1,0,0,0,1-1V11.16l-5.48,5.62A1.6,1.6,0,0,1,10.87,17.17Z" />
            </g>
          </svg>
        );

      case 'action_info':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M7,5A1,1,0,0,0,6,6V18a1,1,0,0,0,1,1H8V5Z" />
              <path d="M17,5H9V19h8a1,1,0,0,0,1-1V6A1,1,0,0,0,17,5Zm-1,7H11V11h5Zm0-3H11V8h5Z" />
            </g>
          </svg>
        );

      case 'action_inspection':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M19.7,18.3l-2-2c0,0-0.1,0-0.1-0.1c0.4-0.6,0.6-1.3,0.6-2c0-2.2-1.8-4-4-4s-4,1.8-4,4s1.8,4,4,4l0,0c0.7,0,1.4-0.2,2-0.6   c0,0,0,0.1,0.1,0.1l2,2c0.4,0.4,1,0.4,1.4,0c0,0,0,0,0,0C20.1,19.3,20.1,18.7,19.7,18.3C19.7,18.3,19.7,18.3,19.7,18.3z M14.2,16.2   c-1.1,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S15.3,16.2,14.2,16.2z" />
              <path d="M9.2,14.2c0-2.8,2.2-5,5-5c1,0,1.9,0.3,2.7,0.8l-6-5.8c-0.2-0.2-0.5-0.2-0.6,0L4.1,10c-0.2,0.2-0.2,0.4,0,0.6   c0.1,0.1,0.2,0.1,0.3,0.1h1.6c0,0.1,0,0.1,0,0.2v5.2C6.1,16.6,6.5,17,7,17c0,0,0,0,0,0h3.1C9.5,16.2,9.2,15.2,9.2,14.2z" />
            </g>
          </svg>
        );

      case 'action_general':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M17,7H14.5V6a1,1,0,0,0-1-1h-3a1,1,0,0,0-1,1V7H7A1,1,0,0,0,6,8V18a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V8A1,1,0,0,0,17,7ZM12,6a1,1,0,1,1-1,1A1,1,0,0,1,12,6Zm3.53,5.53-4,4a.74.74,0,0,1-.53.22.73.73,0,0,1-.53-.22l-2-2a.75.75,0,1,1,1.06-1.06L11,13.94l3.48-3.47a.75.75,0,0,1,1.06,1.06Z" />
            </g>
          </svg>
        );

      case 'action_upload':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M15.86,8.92,12.37,5.16a.51.51,0,0,0-.74,0L8.14,8.92a.5.5,0,0,0,.36.84H10V14.5a.5.5,0,0,0,.5.5h3a.5.5,0,0,0,.5-.5V9.76h1.5A.5.5,0,0,0,15.86,8.92Z" />
              <path d="M18,12H15.5v2.5a2,2,0,0,1-2,2h-3a2,2,0,0,1-2-2V12H6a1,1,0,0,0-1,1v5a1,1,0,0,0,1,1H18a1,1,0,0,0,1-1V13A1,1,0,0,0,18,12Z" />
            </g>
          </svg>
        );

      case 'action_none':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M7,5A1,1,0,0,0,6,6V18a1,1,0,0,0,1,1H8V5Z" />
              <path d="M17,5H9V19h8a1,1,0,0,0,1-1V6A1,1,0,0,0,17,5Zm-1,7H11V11h5Zm0-3H11V8h5Z" />
            </g>
          </svg>
        );

      case 'action_collection':
        return (
          <svg viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <path d="M18.74,7.13a.85.85,0,0,0,0-1.23l-.64-.64a.85.85,0,0,0-1.23,0l-8.11,8.1L8,16l2.64-.76Z" />
              <path d="M18,13H15l-3,3h5.5a.5.5,0,0,1,0,1H10l-1.54.44A1.4,1.4,0,0,1,8,17.5a1.5,1.5,0,0,1-1.44-1.91L7.3,13H6a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H18a1,1,0,0,0,1-1V14A1,1,0,0,0,18,13Z" />
            </g>
          </svg>
        );

      case 'action_issue_document':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <polyline points="18 9 14 9 14 5" />
              <path d="M6,17.62V19H9A4,4,0,0,1,6,17.62Z" />
              <path d="M13,10V5H6v7.38A4,4,0,1,1,9,19h9V10Z" />
              <rect x="8" y="12" width="2" height="6" />
              <rect x="6" y="14" width="6" height="2" />
            </g>
          </svg>
        );

      case 'add':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <circle cx="12" cy="12" r="8" />
            <path d="M16.38 12H7.62M12 7.62v8.76" />
          </svg>
        );

      case 'add_below':
        return (
          <svg viewBox="0 0 24 24">
            <circle cx="9.5" cy="9.5" r="5.5" />
            <line x1="11.98" y1="9.5" x2="7.02" y2="9.5" />
            <line x1="9.5" y1="7.03" x2="9.5" y2="11.97" />
            <line x1="17.45" y1="10.66" x2="17.45" y2="19.17" />
            <polyline points="20 17.5 17.45 20 14.89 17.5" />
          </svg>
        );

      case 'add_circle_blue':
        return (
          <svg viewBox="0 0 24 24">
            <defs>
              <linearGradient
                x1="50%"
                y1="0%"
                x2="50%"
                y2="98.5853041%"
                id="add_circle_blue"
              >
                <stop stopColor="#1E7BDE" offset="0%" />
                <stop stopColor="#1765B8" offset="100%" />
              </linearGradient>
            </defs>
            <circle fill="url(#add_circle_blue)" cx="12" cy="12" r="10" />
            <path style={baseIconStyle} d="M16.375,12 L7.625,12" />
            <path style={baseIconStyle} d="M12,7.625 L12,16.375" />
          </svg>
        );

      case 'address':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M4 4.09h8V19.9H4zM12 8h8v11.91h-8zM7.13 7.31h.81M7.13 10.81h.81M14.94 10.81h.81M7.13 14.31h.81M14.94 14.31h.81" />
          </svg>
        );

      case 'agency':
        return (
          <svg viewBox="0 0 24 24">
            <path
              style={{ fill: 'inherit', stroke: 'inherit' }}
              d="M17.54,12H14a.45.45,0,0,1-.46-.46V6.46A.45.45,0,0,0,13,6H6.46A.45.45,0,0,0,6,6.46V17.54a.45.45,0,0,0,.46.46H17.54a.45.45,0,0,0,.46-.46V12.46A.45.45,0,0,0,17.54,12ZM9,15H7.48V13.47H9Zm0-3H7.48V10.47H9ZM9,9H7.48V7.47H9Zm3,6H10.49V13.47H12Zm0-3H10.49V10.47H12Zm0-3H10.49V7.47H12Z"
            />
          </svg>
        );

      case 'analytics':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <polyline
                style={baseIconStyle}
                points="4 16.5 8.91 11.63 12.37 15.06 20 7.5"
              />
              <polyline style={baseIconStyle} points="14.73 7.5 20 7.5 20 12.72" />
            </g>
          </svg>
        );

      case 'applicant_fill':
        return (
          <svg viewBox="0 0 24 24">
            <path
              style={{
                ...baseIconStyle,
                fill: 'currentcolor',
                stroke: 'none',
              }}
              d="M12,13.6a10,10,0,0,0-7.49,3A2.07,2.07,0,0,0,6.2,20H17.8a2.07,2.07,0,0,0,1.69-3.41,10,10,0,0,0-7.49-3"
            />
            <path
              style={{
                ...baseIconStyle,
                fill: 'currentcolor',
                stroke: 'none',
              }}
              d="M15.73,7.73A3.73,3.73,0,1,1,12,4a3.73,3.73,0,0,1,3.73,3.73"
            />
          </svg>
        );

      case 'archive_fill':
        return (
          <svg viewBox="0 0 24 24">
            <path
              d="M19 5H5a1 1 0 00-1 1v2a1 1 0 001 1v9a1 1 0 001 1h12a1 1 0 001-1V9a1 1 0 001-1V6a1 1 0 00-1-1zm-4.5 9h-5a1 1 0 010-2h5a1 1 0 010 2z"
              style={{
                ...baseIconStyle,
                fill: 'currentcolor',
                stroke: 'none',
              }}
            />
          </svg>
        );
      case 'archive':
        return (
          <svg viewBox="0 0 24 24">
            <rect
              x="4"
              y="5"
              width="16"
              height="4"
              rx="1"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2px"
            />
            <path
              d="M5 9h14v9a1 1 0 01-1 1H6a1 1 0 01-1-1V9zM9.5 13h5"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2px"
            />
          </svg>
        );

      case 'arrow_circle_left':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <circle cx="12" cy="12" r="8" />
            <path d="M12 16l-4-4 4-4M8 12h8" />
          </svg>
        );
      case 'arrow_circle_right':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <circle cx="12" cy="12" r="8" />
            <path d="M12 8l4 4-4 4M16 12H8" />
          </svg>
        );
      case 'arrow_down':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M16 12l-4 4-4-4M12 16V8" />
          </svg>
        );
      case 'arrow_left':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M12 16l-4-4 4-4M8 12h8" />
          </svg>
        );
      case 'arrow_left_long':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M7.96 16L4 12l3.96-4M4.18 12H20" />
          </svg>
        );
      case 'arrow_left_short':
        return (
          <svg viewBox="0 0 24 24">
            <polyline style={baseIconStyle} points="12 16 8 12 12 8" />
            <line style={baseIconStyle} x1="8" y1="12" x2="16" y2="12" />
          </svg>
        );

      case 'arrow_right':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M12 8l4 4-4 4M16 12H8" />
          </svg>
        );
      case 'arrow_right_long':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M16.04 8L20 12l-3.96 4M19.82 12H4" />
          </svg>
        );
      case 'arrow_up':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M8 12l4-4 4 4M12 8v8" />
          </svg>
        );
      case 'attachments':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M13.09,14.21,8.43,9.58a1.39,1.39,0,0,1,.05-1.95h0a1.39,1.39,0,0,1,1.95,0l7.23,7.18.82.83a2.57,2.57,0,0,1,0,3.65h0a2.61,2.61,0,0,1-3.67,0L14,18.41,5.85,10.3a3.69,3.69,0,0,1,0-5.23h0a3.75,3.75,0,0,1,5.26,0l7.22,7.19" />
          </svg>
        );
      case 'bold':
        return (
          <svg viewBox="0 0 24 24">
            <path d="M16 11.59a3.77 3.77 0 0 0 1.92-3.43 3.82 3.82 0 0 0-2.42-3.73A6.91 6.91 0 0 0 12.7 4H5.5v2.23h1.88v11.56H5.5V20h7.55a8.2 8.2 0 0 0 2.34-.27 4.44 4.44 0 0 0 3.11-4.35 3.71 3.71 0 0 0-2.5-3.79zm-5.73-5.22h2.43a3 3 0 0 1 1.3.24 2 2 0 0 1 1 1.83 1.88 1.88 0 0 1-2 2.1h-2.73zm4 11.08a3 3 0 0 1-1.13.18h-2.87v-4.75h3a2.13 2.13 0 0 1 2.27 2.32 2.35 2.35 0 0 1-1.23 2.25z" />
          </svg>
        );
      case 'bookmark':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <polygon points="17 20 12 16 7 20 7 4 17 4 17 20" />
            </g>
          </svg>
        );
      case 'bubble':
        return (
          <svg viewBox="0 0 24 24">
            <path d="M15.27,5H8.73A4.67,4.67,0,0,0,4,9.57v2.88A4.67,4.67,0,0,0,8.73,17H14l2.74,1.91a.37.37,0,0,0,.59-.29v-2.1A4.58,4.58,0,0,0,20,12.45V9.57A4.67,4.67,0,0,0,15.27,5Z" />
          </svg>
        );

      case 'browse':
        return (
          <>
            <svg viewBox="0 0 24 24">
              <g>
                <line style={baseIconStyle} x1="5" y1="7" x2="19" y2="7" />
                <line style={baseIconStyle} x1="5" y1="12" x2="17" y2="12" />
                <line style={baseIconStyle} x1="5" y1="17" x2="15" y2="17" />
              </g>
            </svg>
          </>
        );

      case 'break_link':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M9.12 14.88l5.75-5.76M14.69 13.08a3.24 3.24 0 0 0 1.69-.89l2.67-2.67a3.24 3.24 0 0 0-4.58-4.58L11.8 7.61a3.21 3.21 0 0 0-.89 1.74M9.33 10.92a3.19 3.19 0 0 0-1.72.89l-2.67 2.67a3.24 3.24 0 0 0 4.58 4.58l2.67-2.67a3.25 3.25 0 0 0 .89-1.72" />
            <g>
              <path d="M6.07 6.14l2.04 2.04M9.67 4v2.62M4.02 9.65h2.62" />
            </g>
            <g>
              <path d="M17.95 17.85l-2.04-2.04M14.36 19.99v-2.62M20 14.34h-2.62" />
            </g>
          </svg>
        );
      case 'calendar':
        return (
          <svg viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <rect
                className="cls-1"
                x="4"
                y="7.82"
                width="16"
                height="12.18"
                rx="2"
                ry="2"
              />
              <line className="cls-1" x1="8" y1="4" x2="8" y2="11.1" />
              <line className="cls-1" x1="16" y1="4" x2="16" y2="11.1" />
            </g>
          </svg>
        );
      case 'calendar_today':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M17.5,6.5H17v-1a1,1,0,0,0-2,0v1H9v-1a1,1,0,0,0-2,0v1H6.5a2,2,0,0,0-2,2v9a2,2,0,0,0,2,2h11a2,2,0,0,0,2-2v-9A2,2,0,0,0,17.5,6.5Zm-11,11v-7h11v7Z" />
              <polygon points="10.5 11.5 7.5 11.5 7.5 14.5 10.5 14.5 10.5 11.5 10.5 11.5" />
            </g>
          </svg>
        );
      case 'cancel':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <circle className="cls-1" cx="12" cy="12" r="8" />
              <line className="cls-1" x1="15.09" y1="8.91" x2="8.91" y2="15.09" />
              <line className="cls-1" x1="8.91" y1="8.91" x2="15.09" y2="15.09" />
            </g>
          </svg>
        );
      case 'camino_admins':
        return (
          <svg viewBox="0 0 140 140">
            <polygon points="69.99 32.21 69.99 57.4 114.1 32.19 114.07 7 69.99 32.21" />
            <polygon points="69.99 82.61 69.99 107.79 114.1 82.61 114.07 57.4 69.99 82.61" />
            <polygon points="69.99 57.4 25.9 32.19 25.9 57.4 69.99 82.61 69.99 57.4" />
            <polygon points="25.9 107.79 69.99 133 69.99 107.79 25.9 82.61 25.9 107.79" />
          </svg>
        );
      case 'caret_circle_down':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <circle cx="12" cy="12" r="8" />
            <path d="M8 11.02l4 3.96 4-3.96" />
          </svg>
        );
      case 'caret_circle_up':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <circle cx="12" cy="12" r="8" />
            <path d="M16 13.98l-4-3.96-4 3.96" />
          </svg>
        );
      case 'caret_first':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M9.5 16V8M14.5 8l-3.95 4 3.95 4" />
          </svg>
        );
      case 'caret_last':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M14.5 8v8M9.5 16l3.95-4L9.5 8" />
          </svg>
        );
      case 'caret_left':
        return (
          <svg viewBox="0 0 24 24">
            <path style={baseIconStyle} d="M13.98 8l-3.96 4 3.96 4" />
          </svg>
        );
      case 'caret_right':
        return (
          <svg viewBox="0 0 24 24">
            <path style={baseIconStyle} d="M10.02 16l3.96-4-3.96-4" />
          </svg>
        );
      case 'cash':
        return (
          <svg viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_2-2" data-name="Layer 2">
                <path
                  style={baseIconStyle}
                  d="M9.71,15.48H18a2,2,0,0,0,2-2v-6a2,2,0,0,0-2-2H6a2,2,0,0,0-2,2V9"
                />
                <line style={baseIconStyle} x1="6.28" y1="15.48" x2="4" y2="15.48" />
                <line style={baseIconStyle} x1="6.28" y1="12.46" x2="4" y2="12.46" />
                <line style={baseIconStyle} x1="6.28" y1="18.5" x2="4" y2="18.5" />
                <polyline
                  style={baseIconStyle}
                  points="18.29 15.48 18.29 18.5 9.71 18.5"
                />
                <ellipse style={baseIconStyle} cx="12" cy="10.51" rx="2.29" ry="2.21" />
              </g>
            </g>
          </svg>
        );
      case 'change':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M20 10.26l-1.91 1.85-1.91-1.85" />
            <path d="M18.09 11.92V6H5.91v2.73" />
            <g>
              <path d="M4 13.74l1.91-1.85 1.91 1.85" />
              <path d="M5.91 12.08V18h12.18v-2.73" />
            </g>
          </svg>
        );
      case 'chat':
        return (
          <svg viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <path d="M17.63,6H6.38A1.84,1.84,0,0,0,4.5,7.79V15a1.84,1.84,0,0,0,1.88,1.8h4.55L15.21,20V16.77h2.42A1.84,1.84,0,0,0,19.5,15V7.79A1.84,1.84,0,0,0,17.63,6Z" />
            </g>
          </svg>
        );
      case 'check':
        return (
          <svg viewBox="0 0 17 17">
            <polyline style={baseIconStyle} points="5.57 8.77 7.35 10.63 11.43 6.37" />
          </svg>
        );
      case 'checked_box':
        return (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <rect x="4" y="7" width="12" height="12" rx="2" />
              <polyline points="8 12.35 10.45 14.99 16.05 8.95 21 4" />
            </g>
          </svg>
        );
      case 'close_menu':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M16 8l-8 8M8 8l8 8" />
          </svg>
        );
      case 'collapse':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <polyline points="18.03 13.05 18.03 18 13.1 18" />
              <polyline points="6.08 10.95 6.08 6 10.96 6" />
              <polyline points="19.03 9.95 14.1 9.95 14.1 5" />
              <line x1="14.1" y1="9.95" x2="19.03" y2="5" />
              <polyline points="5.03 14.05 9.96 14.05 9.96 19" />
              <line x1="9.96" y1="14.05" x2="5.03" y2="19" />
            </g>
          </svg>
        );
      case 'comment':
        return (
          <svg viewBox="0 0 40 40">
            <g>
              <circle style={{ fill: '#bcbcbc' }} cx="20" cy="20" r="19" />
              <path
                style={{ fill: '#a0a0a0' }}
                d="M20.57,14.22H15.5a7.24,7.24,0,0,0-2.59,14.05V31.4l3.8-2.66h3.86a7.26,7.26,0,1,0,0-14.52Z"
              />
              <path
                style={{ fill: '#fff' }}
                d="M31.88,17.76A7.32,7.32,0,0,0,24.5,10.5H19.43a7.26,7.26,0,1,0,0,14.52h3.86l3.8,2.66V24.55A7.27,7.27,0,0,0,31.88,17.76Z"
              />
              <path
                style={{ fill: '#a0a0a0' }}
                d="M26.15,17.35H17.49a1,1,0,1,1,0-2h8.66a1,1,0,1,1,0,2Z"
              />
              <path
                style={{ fill: '#a0a0a0' }}
                d="M21.32,20.84H17.49a1,1,0,1,1,0-2h3.83a1,1,0,1,1,0,2Z"
              />
            </g>
          </svg>
        );
      case 'complete':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <circle cx="12" cy="12" r="8" />
            <path d="M8.12 12.34l2.36 2.36 5.4-5.4" />
          </svg>
        );
      case 'connection':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <rect
              x="13.93"
              y="13.93"
              width="6.04"
              height="6.11"
              rx="3.02"
              ry="3.02"
              transform="rotate(-90 16.95 16.98)"
            />
            <rect
              x="13.93"
              y="3.97"
              width="6.04"
              height="6.11"
              rx="3.02"
              ry="3.02"
              transform="rotate(-90 16.95 7.02)"
            />
            <path d="M13.89 16.98H9.94V7.02h3.95M9.94 11.96H4" />
          </svg>
        );
      case 'copy':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M4 7.83h12V20H4z" />
            <path d="M8 5.71V4h12v12.17h-2.15" />
          </svg>
        );
      case 'create_link':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M9.12 14.88l5.76-5.76M14.7 13.08a3.24 3.24 0 0 0 1.69-.89l2.67-2.67a3.24 3.24 0 0 0-4.58-4.58l-2.67 2.67a3.14 3.14 0 0 0-.89 1.74M9.34 10.92a3.21 3.21 0 0 0-1.73.89l-2.67 2.67a3.24 3.24 0 0 0 4.58 4.58l2.67-2.67a3.19 3.19 0 0 0 .89-1.72" />
          </svg>
        );

      case 'cross_fill':
        return (
          <svg viewBox="0 0 24 24">
            <path
              style={{
                ...baseIconStyle,
                fill: 'currentcolor',
                stroke: 'none',
              }}
              d="M12,4.5A7.5,7.5,0,1,0,19.5,12,7.5,7.5,0,0,0,12,4.5Zm3.71,9.83a1,1,0,0,1,0,1.38,1,1,0,0,1-1.38,0L12,13.38,9.67,15.71a1,1,0,0,1-1.38,0,1,1,0,0,1,0-1.38L10.62,12,8.29,9.67A1,1,0,0,1,9.67,8.29L12,10.62l2.33-2.33a1,1,0,0,1,1.38,1.38L13.38,12Z"
            />
          </svg>
        );

      case 'delete':
        return (
          <svg viewBox="0 0 24 24">
            <path d="M18,7.67v10A2.16,2.16,0,0,1,16,20H8a2.16,2.16,0,0,1-2-2.28v-10" />
            <path d="M9.89,7.67V6.13A2.13,2.13,0,0,1,12,4h0a2.13,2.13,0,0,1,2.11,2.13V7.67" />
            <line x1="4" y1="7.67" x2="20" y2="7.67" />
            <line x1="14.11" y1="11" x2="14.11" y2="16.25" />
            <line x1="9.89" y1="11" x2="9.89" y2="16.25" />
          </svg>
        );
      case 'department':
        return (
          <svg viewBox="0 0 24 24">
            <polygon
              className="cls-1"
              points="12.72 20 4 20 4 7.06 8.36 4 12.72 7.06 12.72 20"
            />
            <polyline className="cls-1" points="12.79 11.2 20 11.2 20 20 12.79 20" />
            <line className="cls-1" x1="6.12" y1="8.45" x2="10.6" y2="8.45" />
            <line className="cls-1" x1="6.12" y1="11.2" x2="10.6" y2="11.2" />
            <line className="cls-1" x1="6.12" y1="13.94" x2="10.6" y2="13.94" />
            <line className="cls-1" x1="6.12" y1="16.68" x2="10.6" y2="16.68" />
          </svg>
        );
      case 'development':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M9,16V8h2.44a3.74,3.74,0,0,1,1.87.47,3.27,3.27,0,0,1,1.29,1.34,4.09,4.09,0,0,1,.46,2v.36a4.18,4.18,0,0,1-.45,2,3.31,3.31,0,0,1-1.29,1.33,3.63,3.63,0,0,1-1.86.48Zm1.63-6.6v5.29h.79A1.78,1.78,0,0,0,12.86,14a2.75,2.75,0,0,0,.51-1.79v-.42a2.85,2.85,0,0,0-.5-1.83,1.77,1.77,0,0,0-1.46-.62Z" />
              <rect
                style={baseIconStyle}
                x="4"
                y="4"
                width="16"
                height="16"
                rx="2"
                ry="2"
              />
            </g>
          </svg>
        );

      case 'discard':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <circle cx="12" cy="12" r="8" />
            <path d="M16.38 12H7.62" />
          </svg>
        );
      case 'dollar':
        return (
          <svg viewBox="0 0 24 24">
            <path
              style={baseIconStyle}
              d="M8,14.68c0,1.47,1.79,2.67,4,2.67s4-1.2,4-2.67S14.21,12,12,12,8,10.8,8,9.32s1.79-2.67,4-2.67,4,1.2,4,2.67"
            />
            <line style={baseIconStyle} x1="12.03" y1="4" x2="12.03" y2="20" />
          </svg>
        );
      case 'dollar-circle':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <circle cx="12" cy="12" r="8" />
            <path d="M9.53,13.65c0,.91,1.11,1.65,2.47,1.65s2.47-.74,2.47-1.65S13.36,12,12,12s-2.47-.74-2.47-1.65S10.64,8.7,12,8.7s2.47.74,2.47,1.65" />
            <line x1="12.02" y1="7.07" x2="12.02" y2="16.93" />
          </svg>
        );
      case 'download':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <g id="Layer_2" data-name="Layer 2">
              <polygon
                className="cls-1"
                points="13.91 10.74 13.91 4 10.09 4 10.09 10.74 7.65 10.74 12 15.69 16.35 10.74 13.91 10.74"
              />
              <path
                className="cls-1"
                d="M6,8.15H6a2,2,0,0,0-2,2V18a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V10.15a2,2,0,0,0-2-2h0"
              />
            </g>
          </svg>
        );
      case 'dropdown':
        return (
          <svg viewBox="0 0 24 24">
            <path
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M16 11.5l-4 3-4-3"
            />
          </svg>
        );
      case 'edit':
        return (
          <svg viewBox="0 0 24 24">
            <path d="M7.84,18.9,4,20l1.1-3.84L16.9,4.37a1.27,1.27,0,0,1,1.79,0l.94.94a1.27,1.27,0,0,1,0,1.79Z" />
            <line x1="15.1" y1="6.17" x2="17.83" y2="8.9" />
          </svg>
        );
      case 'email':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <rect x="4" y="6" width="16" height="12" rx="1.22" ry="1.22" />
            <path d="M4 10.29l8 2.18 8-2.18" />
          </svg>
        );
      case 'enter':
        return (
          <svg viewBox="0 0 24 24">
            <polyline points="8.21 12.08 4.5 15.54 8.21 19" />
            <polyline points="4.67 15.54 19.5 15.54 19.5 5" />
          </svg>
        );
      case 'environment_user':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M16 7.71a.41.41 0 0 0-.31.09A3.72 3.72 0 0 0 12 4a3.72 3.72 0 0 0-3.64 3.81.4.4 0 0 0-.36-.1c-.37 0-.6.66-.51 1.38s.46 1.27.83 1.23a2 2 0 0 0 .44-.06 3.27 3.27 0 0 0 3.21 2 3.24 3.24 0 0 0 3.19-2l.44.05c.37 0 .74-.51.83-1.23s-.1-1.33-.43-1.37zM12.87 20H19l-.14-.44-.78-2.48a4.06 4.06 0 0 0-3.63-2.85h-4.9a4.06 4.06 0 0 0-3.63 2.85L5 20h7.87z" />
          </svg>
        );
      case 'estimates':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <circle cx="12" cy="13.21" r="6.79" />
            <circle cx="11.73" cy="13.2" r=".33" />
            <path d="M14.47 16.01l-2.78-2.79V8.74" />
            <g>
              <path d="M10.06 4h3.88M11.06 4v2.17M13.05 4v2.17" />
            </g>
          </svg>
        );
      case 'expand':
        return (
          <svg viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <polyline points="18.03 13.05 18.03 18 13.1 18" />
              <polyline points="6.08 10.95 6.08 6 10.96 6" />
              <polyline points="14.1 5 19.03 5 19.03 9.95" />
              <line x1="19.03" y1="5" x2="14.1" y2="9.95" />
              <polyline points="9.96 19 5.03 19 5.03 14.05" />
              <line x1="5.03" y1="19" x2="9.96" y2="14.05" />
            </g>
          </svg>
        );
      case 'export':
        return (
          <svg viewBox="0 0 24 24">
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2px"
              d="M9 16v-4.56A2.44 2.44 0 0111.44 9H20"
            />
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2px"
              d="M15 4l5 5.03L15.07 14"
            />
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2px"
              d="M8.89 4H6.67A2.66 2.66 0 004 6.67v10.66A2.66 2.66 0 006.67 20h10.66A2.66 2.66 0 0020 17.33v-1.78"
            />
          </svg>
        );
      case 'fax':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M7.61 15.85H4V7.46h16v8.39h-3.79M6.54 7.46V4.97h10.53v2.49" />
            <path d="M7.61 12.06h8.6v7.91h-8.6zM9.94 14.86h3.93M9.94 17.32h3.93" />
          </svg>
        );
      case 'field_type_signature':
        return (
          <svg viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <path d="M11.45,16.23,8.75,17l.77-2.76,8.3-8.49a.87.87,0,0,1,1.26,0l.66.67a.94.94,0,0,1,0,1.3Z" />
              <line x1="4" y1="18.5" x2="19.76" y2="18.5" />
            </g>
          </svg>
        );
      case 'field_type_phone':
        return (
          <svg viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <path d="M12.19,15.19a2.74,2.74,0,0,0,3.12.33l.13-.07a1,1,0,0,1,.94-.1c.34.21,3.16,2.08,3.39,2.25a.81.81,0,0,1,0,1.11C19.47,19.17,18,21,17.34,21s-3.28-.08-8.27-5.07S4,8.29,4,7.65,5.82,5.52,6.29,5.19a.81.81,0,0,1,1.1,0c.19.26,2,3.07,2.25,3.39a1,1,0,0,1-.1.94l-.07.13a2.72,2.72,0,0,0,.34,3.12c.31.35.69.75,1.16,1.22S11.84,14.87,12.19,15.19Z" />
              <path d="M15.38,13.14a1,1,0,0,1-1-1,1.5,1.5,0,0,0-1.5-1.5,1,1,0,0,1,0-2,3.5,3.5,0,0,1,3.5,3.5A1,1,0,0,1,15.38,13.14Z" />
              <path d="M18.88,13.14a1,1,0,0,1-1-1,5,5,0,0,0-5-5,1,1,0,0,1,0-2,7,7,0,0,1,7,7A1,1,0,0,1,18.88,13.14Z" />
            </g>
          </svg>
        );
      case 'field_type_text':
        return (
          <svg viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <path d="M9.79,15.08H6.1l-.45,1.34H3.17L6.5,7.58H9.37l3.36,8.84H10.24ZM6.7,13.16H9.19L7.94,9.57Z" />
              <path d="M17.12,15.78a2.65,2.65,0,0,1-2,.8,2.12,2.12,0,0,1-2.2-2.11,2,2,0,0,1,2.2-2,2.58,2.58,0,0,1,2,.75v-.81c0-.56-.48-.93-1.3-.93a2.88,2.88,0,0,0-1.83.68l-.73-1.29a4.56,4.56,0,0,1,2.91-1c1.55,0,3,.57,3,2.55v4H17.12Zm0-1.58A1.47,1.47,0,0,0,16,13.75c-.54,0-1,.24-1,.75s.48.74,1,.74a1.41,1.41,0,0,0,1.15-.47Z" />
            </g>
          </svg>
        );
      case 'field_type_date':
        return (
          <svg viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <path d="M17.5,6.5H17v-1a1,1,0,0,0-2,0v1H9v-1a1,1,0,0,0-2,0v1H6.5a2,2,0,0,0-2,2v9a2,2,0,0,0,2,2h11a2,2,0,0,0,2-2v-9A2,2,0,0,0,17.5,6.5Zm-11,11v-7h11v7Z" />
              <polygon points="10.5 11.5 7.5 11.5 7.5 14.5 10.5 14.5 10.5 11.5 10.5 11.5" />
            </g>
          </svg>
        );
      case 'field_type_boolean':
        return (
          <svg viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <path d="M15,5.75a6.19,6.19,0,0,0-3,.77,6.25,6.25,0,1,0,0,11A6.25,6.25,0,1,0,15,5.75Zm-3,8.48a3.69,3.69,0,0,1,0-4.46,3.69,3.69,0,0,1,0,4.46ZM5.25,12A3.75,3.75,0,0,1,9,8.25a3.52,3.52,0,0,1,.92.13,6.19,6.19,0,0,0,0,7.24,3.52,3.52,0,0,1-.92.13A3.75,3.75,0,0,1,5.25,12ZM15,15.75a3.52,3.52,0,0,1-.92-.13,6.19,6.19,0,0,0,0-7.24A3.52,3.52,0,0,1,15,8.25a3.75,3.75,0,0,1,0,7.5Z" />
            </g>
          </svg>
        );
      case 'field_type_checkbox':
        return (
          <svg viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <path d="M18,5H6A1,1,0,0,0,5,6V18a1,1,0,0,0,1,1H18a1,1,0,0,0,1-1V6A1,1,0,0,0,18,5Zm-1.32,5.23-5.38,5a1,1,0,0,1-.68.27,1,1,0,0,1-.68-.27L7.32,12.8a1,1,0,0,1,1.36-1.47l1.94,1.8,4.7-4.36a1,1,0,0,1,1.36,1.46Z" />
            </g>
          </svg>
        );
      case 'field_type_numeric':
        return (
          <svg viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <path d="M6.91,10.5,5.36,12,4,10.64,7.23,7.58H9.3v8.87H6.91Z" />
              <path d="M11.74,14.66c4-2.77,4.76-3.39,4.76-4.26,0-.62-.59-.93-1.31-.93a3.48,3.48,0,0,0-2.43,1L11.44,8.92a5.2,5.2,0,0,1,3.75-1.47c2.17,0,3.74,1.16,3.74,3,0,1.35-1,2.51-3.49,4H19v2H11.74Z" />
            </g>
          </svg>
        );
      case 'field_type_email':
        return (
          <svg viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <path d="M4.35,9.56,12,12.43l7.65-2.87A1,1,0,0,1,20,9.51V8a2,2,0,0,0-2-2H6A2,2,0,0,0,4,8V9.51A1,1,0,0,1,4.35,9.56Z" />
              <path d="M12.35,14.44a1.05,1.05,0,0,1-.7,0L4,11.57V16a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V11.57Z" />
            </g>
          </svg>
        );
      case 'field_type_radio':
        return (
          <svg viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <path d="M18,5H6A1,1,0,0,0,5,6V18a1,1,0,0,0,1,1H18a1,1,0,0,0,1-1V6A1,1,0,0,0,18,5ZM12,17a5,5,0,1,1,5-5A5,5,0,0,1,12,17Z" />
              <path d="M12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4.5A1.5,1.5,0,1,1,13.5,12,1.5,1.5,0,0,1,12,13.5Z" />
            </g>
          </svg>
        );
      case 'field_type_currency':
        return (
          <svg viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <path d="M13.14,11V8c1,.26,1.64.84,1.64,1.37a1.11,1.11,0,1,0,2.22,0c0-1.82-1.63-3.28-3.86-3.66V4.12a1.11,1.11,0,1,0-2.22,0V5.7C8.65,6.06,7,7.54,7,9.37s1.65,3.3,3.92,3.67v3c-1-.25-1.7-.84-1.7-1.38a1.11,1.11,0,1,0-2.22,0c0,1.83,1.65,3.31,3.92,3.67v1.58a1.11,1.11,0,1,0,2.22,0V18.29c2.23-.38,3.86-1.84,3.86-3.66S15.37,11.35,13.14,11ZM9.22,9.37c0-.54.68-1.13,1.7-1.38v2.75C9.9,10.49,9.22,9.9,9.22,9.37ZM13.14,16V13.27c1,.26,1.64.84,1.64,1.36S14.12,15.74,13.14,16Z" />
            </g>
          </svg>
        );
      case 'filter':
        return (
          <svg viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <line x1="11.5" y1="8.45" x2="19" y2="8.45" />
              <line x1="5" y1="8.45" x2="6.5" y2="8.45" />
              <circle cx="9" cy="8.5" r="2.5" />
              <line x1="12.5" y1="15.55" x2="5" y2="15.55" />
              <line x1="19" y1="15.55" x2="17.5" y2="15.55" />
              <circle cx="15" cy="15.5" r="2.5" />
            </g>
          </svg>
        );
      case 'folder':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path
                style={baseIconStyle}
                d="M18.05,7.42H12L9.66,5H6A2,2,0,0,0,4,7v10.1A2,2,0,0,0,6,19h12.1A2,2,0,0,0,20,17.05V9.37A2,2,0,0,0,18.05,7.42Z"
              />
              <polyline style={baseIconStyle} points="20 11 8 11 8 19" />
            </g>
          </svg>
        );
      case 'folder_fill':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path
                style={{
                  ...baseIconStyle,
                  fill: 'currentcolor',
                }}
                d="M17.67,7.75H12L9.8,5.5H6.33A1.82,1.82,0,0,0,4.5,7.31v9.38A1.82,1.82,0,0,0,6.33,18.5H17.67a1.82,1.82,0,0,0,1.83-1.81V9.56A1.82,1.82,0,0,0,17.67,7.75Z"
              />
            </g>
          </svg>
        );
      case 'guide_builder':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M16 10.29V19a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8.84a1 1 0 0 1 1-1h9" />
            <path d="M10.22 16.17l-3.08.9.86-3.13 9.5-9.64a1 1 0 0 1 1.5 0l.75.77a1.05 1.05 0 0 1 0 1.47zM16.06 5.77L18.26 8" />
          </svg>
        );
      case 'grabber':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <circle cx="9.5" cy="5" r="1" />
              <circle cx="14.5" cy="5" r="1" />
              <circle cx="9.5" cy="19" r="1" />
              <circle cx="14.5" cy="19" r="1" />
              <circle cx="9.5" cy="15.5" r="1" />
              <circle cx="14.5" cy="15.5" r="1" />
              <circle cx="9.5" cy="12" r="1" />
              <circle cx="14.5" cy="12" r="1" />
              <circle cx="9.5" cy="8.5" r="1" />
              <circle cx="14.5" cy="8.5" r="1" />
            </g>
          </svg>
        );
      case 'help':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M10.54 9.23A1.47 1.47 0 1 1 12 10.92v.52M12 13.9v.07" />
            <path d="M15.27 5H8.73A4.67 4.67 0 0 0 4 9.57v2.88A4.67 4.67 0 0 0 8.73 17H14l2.74 1.91a.37.37 0 0 0 .59-.29v-2.1A4.58 4.58 0 0 0 20 12.45V9.57A4.67 4.67 0 0 0 15.27 5z" />
          </svg>
        );
      case 'help_inverse':
        return (
          <svg viewBox="0 0 24 24">
            <circle style={baseIconStyle} cx="12" cy="12" r="8" />
            <line style={baseIconStyle} x1="12" y1="16.25" x2="12" y2="16.28" />
            <path style={baseIconStyle} d="M9.3,10.45a2.7,2.7,0,1,1,2.7,2.7" />
          </svg>
        );
      case 'hide':
        return (
          <svg viewBox="0 0 24 24">
            <path d="M19.72,18.37,5.63,4.28A1,1,0,0,0,4.28,5.63L7.22,8.57A8.1,8.1,0,0,0,4.47,12a8.14,8.14,0,0,0,10.58,4.4l3.32,3.32a1,1,0,0,0,1.35,0A1,1,0,0,0,19.72,18.37ZM12,15.5A3.5,3.5,0,0,1,8.5,12,3.37,3.37,0,0,1,9,10.3l1.05,1a2,2,0,0,0-.11.65A2.12,2.12,0,0,0,12,14.11a2,2,0,0,0,.65-.11l1,1.05A3.37,3.37,0,0,1,12,15.5Z" />
            <path d="M12,8.5A3.5,3.5,0,0,1,15.5,12a4.62,4.62,0,0,1-.06.65l2.14,2.14a8.22,8.22,0,0,0,2-2.8A8.17,8.17,0,0,0,12,7a8,8,0,0,0-2,.25l1.32,1.31A4.62,4.62,0,0,1,12,8.5Z" />
          </svg>
        );
      case 'home':
        return (
          <svg viewBox="0 0 24 24">
            <polyline points="18.04 12.09 18.04 19 5.96 19 5.96 12.09" />
            <polygon points="20 12.09 4 12.09 6.44 5 17.56 5 20 12.09" />
            <path d="M9.9,19V16.49a2.11,2.11,0,0,1,2.1-2.1h0a2.11,2.11,0,0,1,2.1,2.1V19" />
          </svg>
        );
      case 'image':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <rect x="4" y="5.5" width="16" height="13" rx="1" ry="1" />
            <path d="M7.05 16.16l3.56-3.68 2.93 3.03 1.9-1.96 1.51 1.56" />
            <ellipse cx="14.71" cy="9.54" rx="1.63" ry="1.69" />
          </svg>
        );
      case 'import':
        return (
          <svg viewBox="0 0 24 24">
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2px"
              d="M20 4h-4.56A2.44 2.44 0 0013 6.44V15"
            />
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2px"
              d="M8 10l5.03 5L18 10.07"
            />
            <path
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2px"
              d="M8.89 4H6.67A2.66 2.66 0 004 6.67v10.66A2.66 2.66 0 006.67 20h10.66A2.66 2.66 0 0020 17.33v-1.78"
            />
          </svg>
        );
      case 'in_progress':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M17.22 16.05A7.25 7.25 0 1 1 18.46 12" />
            <circle cx="11.23" cy="12" r=".34" />
            <path d="M20 11.21l-1.54 1.54-1.55-1.54M14.06 14.89l-2.87-2.88V7.4" />
          </svg>
        );
      case 'inspections':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path
                style={baseIconStyle}
                d="M15.9,14.41V18a2,2,0,0,1-2,2h-8a2,2,0,0,1-2-2V6a2,2,0,0,1,2-2h8a2,2,0,0,1,2,2v4"
              />
              <ellipse style={baseIconStyle} cx="12.52" cy="11.23" rx="3.61" ry="3.62" />
              <polyline
                style={baseIconStyle}
                points="19.94 18.47 15.28 13.79 15.18 13.69"
              />
            </g>
          </svg>
        );
      case 'incomplete':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <circle cx="12" cy="12" r="8" />
            <path d="M15.09 8.91l-6.18 6.18M8.91 8.91l6.18 6.18" />
          </svg>
        );
      case 'info':
        return (
          <svg viewBox="0 0 24 24">
            <circle cx="12" cy="12" r="8" />
            <circle cx="12" cy="7.98" r="0.25" />
            <line x1="12" y1="11.02" x2="12" y2="16.52" />
            <line x1="9.84" y1="16.52" x2="14.16" y2="16.52" />
          </svg>
        );

      case 'info_fill':
        return (
          <svg viewBox="0 0 24 24">
            <path
              style={{
                fill: 'currentColor',
                stroke: '#fff',
                strokeWidth: 0,
              }}
              d="M12,4.5A7.5,7.5,0,1,0,19.5,12,7.5,7.5,0,0,0,12,4.5Zm-.08,2.28A1,1,0,1,1,11,7.9,1.06,1.06,0,0,1,11.92,6.78ZM13.8,16.6H10.2a.84.84,0,0,1-.83-.84.83.83,0,0,1,.83-.83h1V11.18a.83.83,0,0,1,1.66,0v3.75h1a.83.83,0,0,1,.83.83A.84.84,0,0,1,13.8,16.6Z"
            />
          </svg>
        );

      case 'instructions':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <polyline points="20 16.84 20 19.5 4 19.5 4 16.84" />
            <path d="M12,4.92v11.5a19.26,19.26,0,0,0-8,0V4.92a19.26,19.26,0,0,1,8,0" />
            <path d="M20,4.92v11.5a19.26,19.26,0,0,0-8,0V4.92a19.26,19.26,0,0,1,8,0" />
          </svg>
        );
      case 'italic':
        return (
          <svg viewBox="0 0 24 24">
            <path d="M8.14 18.68h2.25l1.48-13.36H9.61L9.75 4H16l-.15 1.32h-2.26L12.1 18.68h2.25L14.2 20H8z" />
          </svg>
        );
      case 'key':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <ellipse style={baseIconStyle} cx="8.07" cy="9.09" rx="4.02" ry="4.03" />
              <line style={baseIconStyle} x1="6.65" y1="7.68" x2="5.22" y2="6.24" />
              <polyline
                style={baseIconStyle}
                points="20.05 16.93 17.94 19.06 10.9 11.96"
              />
              <line style={baseIconStyle} x1="17.69" y1="14.55" x2="15.58" y2="16.68" />
              <line style={baseIconStyle} x1="13.22" y1="14.3" x2="14.28" y2="13.25" />
            </g>
          </svg>
        );
      case 'jump':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M15.26 11.16l3.7 3.5-3.7 3.51" />
            <path d="M18.79 14.66H4V4M20 20V4" />
          </svg>
        );
      case 'link':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path
                style={baseIconStyle}
                d="M17.3,12.93V17a2,2,0,0,1-2,2H7a2,2,0,0,1-2-2V8.84a2,2,0,0,1,2-2h4.17"
              />
              <polyline style={baseIconStyle} points="13.32 5 19 5 19 10.6" />
              <line style={baseIconStyle} x1="18.8" y1="5.2" x2="9.54" y2="14.33" />
            </g>
          </svg>
        );
      case 'location_fill':
        return (
          <svg viewBox="0 0 24 24">
            <path
              style={{
                ...baseIconStyle,
                fill: 'currentcolor',
                stroke: 'none',
              }}
              d="M16.69,5.91a6.71,6.71,0,0,0-9.38,0,6.87,6.87,0,0,0,.11,9.73l4.21,4.21a.53.53,0,0,0,.74,0l4.21-4.22A6.85,6.85,0,0,0,16.69,5.91Zm-4.26,6.86A2.2,2.2,0,1,1,14.16,11,2.21,2.21,0,0,1,12.43,12.77Z"
            />
          </svg>
        );
      case 'location_stroke':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M16.6 5.94a6.42 6.42 0 0 0-9.2 0 6.73 6.73 0 0 0 0 9.37L12 20l4.6-4.69a6.73 6.73 0 0 0 0-9.37z" />
            <ellipse cx="12" cy="10.52" rx="2.13" ry="2.17" />
          </svg>
        );
      case 'lock':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M16.46 20H7.54a1 1 0 0 1-1-1v-7.42a1 1 0 0 1 1-1h8.92a1 1 0 0 1 1 1V19a1 1 0 0 1-1 1zM8 10.47V7.9A4 4 0 0 1 12 4a4 4 0 0 1 4 3.9v2.57" />
            <circle cx="12" cy="14.81" r="1.6" />
          </svg>
        );
      case 'lock_fill':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M17,9.5V7.65A4.86,4.86,0,0,0,12,3,4.86,4.86,0,0,0,7,7.65V9.5A3,3,0,0,0,5.5,12.07V18a3,3,0,0,0,3,3h7a3,3,0,0,0,3-3V12.07A3,3,0,0,0,17,9.5Zm-5,7.24a2,2,0,1,1,2-2A2,2,0,0,1,12,16.74Zm3-7.67H9V7.65A2.86,2.86,0,0,1,12,5a2.86,2.86,0,0,1,3,2.65Z" />
          </svg>
        );
      case 'lock_group':
        return (
          <svg viewBox="0 0 24 24">
            <path d="M15.71,20.39H8.29a.86.86,0,0,1-.86-.84V13.38a.85.85,0,0,1,.86-.84h7.42a.86.86,0,0,1,.87.84v6.15A.88.88,0,0,1,15.71,20.39Z" />
            <path d="M8.65,12.46V10.32A3.3,3.3,0,0,1,12,7.07h0a3.31,3.31,0,0,1,3.36,3.25v2.14" />
            <circle cx="12" cy="16.07" r="1.33" />
            <path d="M16,9.85h3.71a.86.86,0,0,1,.86.84v6.16a.86.86,0,0,1-.86.85h-3.1" />
            <path d="M16,4.39h0a3.3,3.3,0,0,1,3.36,3.24V9.77" />
            <path d="M12.65,7.13A3.33,3.33,0,0,1,16,4.39" />
            <path d="M8.06,9.85H4.34a.86.86,0,0,0-.86.84v6.16a.87.87,0,0,0,.86.85H7.45" />
            <path d="M8.06,4.39h0A3.3,3.3,0,0,0,4.7,7.63V9.77" />
            <path d="M11.37,7.13A3.32,3.32,0,0,0,8.06,4.39" />
          </svg>
        );
      case 'logout':
        return (
          <svg viewBox="0 0 24 24">
            <line style={baseIconStyle} x1="4" y1="12" x2="12.5" y2="12" />
            <polyline style={baseIconStyle} points="10.84 9.45 13.34 12 10.84 14.55" />
            <path
              style={baseIconStyle}
              d="M7.53,16.06V18A2.05,2.05,0,0,0,9.58,20H18A2.05,2.05,0,0,0,20,18V6.05A2.05,2.05,0,0,0,18,4H9.58a2.05,2.05,0,0,0-2,2.05V7.94"
            />
          </svg>
        );
      case 'medal':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M13.12 16.49l-.16.16a1.36 1.36 0 0 1-1.92 0l-.16-.16a3.79 3.79 0 0 0-2.31-.95h-.23A1.36 1.36 0 0 1 7 14.18V14a3.77 3.77 0 0 0-1-2.31l-.16-.16a1.35 1.35 0 0 1 0-1.91L6 9.41A3.77 3.77 0 0 0 7 7.1v-.23a1.36 1.36 0 0 1 1.34-1.36h.23a3.79 3.79 0 0 0 2.31-1l.12-.12a1.38 1.38 0 0 1 1.92 0l.16.17a3.79 3.79 0 0 0 2.31 1h.23A1.36 1.36 0 0 1 17 6.87v.23a3.77 3.77 0 0 0 1 2.31l.16.16a1.35 1.35 0 0 1 0 1.91l-.16.16A3.77 3.77 0 0 0 17 14v.23a1.36 1.36 0 0 1-1.35 1.36h-.23a3.79 3.79 0 0 0-2.31.95" />
            <circle cx="12" cy="10.52" r="3.7" />
            <path d="M10.73 16.45L9.71 20l-1.63-2.63-2.78 1.36 1.58-5.48M13.24 16.38L14.29 20l1.63-2.63 2.78 1.36-1.59-5.48" />
          </svg>
        );
      case 'move':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M13.64 12h4.33M16.53 10.53L18 12l-1.47 1.47" />
            <g>
              <path d="M10.36 12H6.03M7.47 13.47L6 12l1.47-1.47" />
            </g>
            <g>
              <path d="M12 13.64v4.33M13.47 16.53L12 18l-1.47-1.47" />
              <g>
                <path d="M12 10.36V6.03M10.53 7.47L12 6l1.47 1.47" />
              </g>
            </g>
          </svg>
        );

      case 'not_allowed':
        return (
          <svg viewBox="0 0 32 32">
            <path d="M24.24,21.37V7A1.72,1.72,0,0,0,22.51,5.3H6.89V25.19A1.52,1.52,0,0,0,8.4,26.7h.91" />
            <path d="M24.24,21.49H9.2v0a2.63,2.63,0,0,1,0,5.15v0H25.65a2.62,2.62,0,0,0,0-5.21H24.24" />
            <path d="M6.35,5.3A2.49,2.49,0,0,0,4,7.9a2.49,2.49,0,0,0,2.35,2.61" />
            <circle cx="15.55" cy="13.42" r="4.2" />
            <line x1="14.05" y1="11.92" x2="17.05" y2="14.92" />
            <line x1="17.05" y1="11.92" x2="14.05" y2="14.92" />
          </svg>
        );
      case 'not_required':
        return (
          <svg viewBox="0 0 32 32">
            <path d="M24.24,21.37V7A1.72,1.72,0,0,0,22.51,5.3H6.89V25.19A1.52,1.52,0,0,0,8.4,26.7h.91" />
            <path d="M24.24,21.49H9.2v0a2.63,2.63,0,0,1,0,5.15v0H25.65a2.62,2.62,0,0,0,0-5.21H24.24" />
            <path d="M6.35,5.3A2.49,2.49,0,0,0,4,7.9a2.49,2.49,0,0,0,2.35,2.61" />
            <circle cx="15.52" cy="13.6" r="4.2" />
            <polyline points="13.48 13.96 14.92 15.4 17.56 12.76" />
          </svg>
        );
      case 'note':
        return (
          <svg viewBox="0 0 40 40">
            <g>
              <circle style={{ fill: '#bcbcbc' }} cx="20" cy="20" r="19" />
              <path
                style={{ fill: '#a0a0a0' }}
                d="M24.08,20.07v9.05a2.29,2.29,0,0,1-2.29,2.28H12.32A2.29,2.29,0,0,1,10,29.12V19.43a2.27,2.27,0,0,1,2.28-2.26h9.51"
              />
              <path
                style={{ fill: '#fff' }}
                d="M29.66,11.64l-.81-.82a1.08,1.08,0,0,0-1.54,0L25.18,13v-.1a.78.78,0,0,0-1.56,0v.9H22.23v-.9a.78.78,0,0,0-1.56,0v.9H19.28v-.9a.78.78,0,0,0-.78-.77.77.77,0,0,0-.77.77v.9H16.22v-.9a.78.78,0,0,0-1.56,0v.93a2.83,2.83,0,0,0-2.57,2.81v8.84A2.86,2.86,0,0,0,15,28.32h8.65a2.87,2.87,0,0,0,2.87-2.86v-9l3.18-3.22A1.12,1.12,0,0,0,29.66,11.64ZM23.61,26.77H15a1.32,1.32,0,0,1-1.32-1.31V16.62a1.3,1.3,0,0,1,1-1.26v.23a.78.78,0,1,0,1.56,0v-.26h1.51v.26a.77.77,0,0,0,.77.78.78.78,0,0,0,.78-.78v-.26h1.39v.26a.78.78,0,1,0,1.56,0v-.26h.63l-5.69,5.76-1,3.34,3.3-1L24.92,18v7.47A1.31,1.31,0,0,1,23.61,26.77Z"
              />
            </g>
          </svg>
        );
      case 'notification_bell':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M17.16 14.85h-.06V11A5.05 5.05 0 1 0 7 11v3.82h-.06a1.45 1.45 0 0 0 0 2.89h10.22a1.45 1.45 0 0 0 0-2.89zM14.21 17.84a2.16 2.16 0 0 1-4.32 0" />
            <path d="M11 6v-.9A1.07 1.07 0 0 1 12.05 4a1.07 1.07 0 0 1 1.07 1.07V6" />
          </svg>
        );
      case 'notification_warning':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M12.05 10.44v4.12" />
            <circle cx="12.05" cy="17.19" r=".38" />
            <path d="M12.05 4l-8 16h16l-8-16z" />
          </svg>
        );
      case 'open_menu':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M8 8h8M8 12h8M8 16h8" />
          </svg>
        );

      case 'option_tick_fill':
        return (
          <svg viewBox="0 0 24 24">
            <path
              d="M12,4.5A7.5,7.5,0,1,0,19.5,12,7.5,7.5,0,0,0,12,4.5ZM16.42,10l-4.94,5.33a1,1,0,0,1-1.47,0L7.58,12.72A1,1,0,0,1,9,11.36l1.71,1.83L15,8.65A1,1,0,1,1,16.42,10Z"
              style={{
                fill: 'currentcolor',
                stroke: 'rgb(255, 255, 255)',
                strokeWidth: 0,
              }}
            />
          </svg>
        );

      case 'option_tick':
        return (
          <svg viewBox="0 0 24 24">
            <circle
              cx="12"
              cy="12"
              r="8"
              style={{
                fill: 'none',
                stroke: 'currentcolor',
                strokeWidth: 1,
              }}
            />
          </svg>
        );

      case 'ordered':
        return (
          <svg viewBox="0 0 24 24">
            <path d="M9.84 6.37h9.64a.52.52 0 1 0 0-1H9.84a.52.52 0 1 0 0 1zM19.48 11.45H9.84a.52.52 0 1 0 0 1h9.64a.52.52 0 1 0 0-1zM19.48 17.56H9.84a.52.52 0 1 0 0 1h9.64a.52.52 0 1 0 0-1zM4.91 7.71V5c-.16.08-.37.16-.52.22v-.78c.25-.13.55-.28.83-.44h.59v3.71zM6.67 13.73H4.12v-.63a12.8 12.8 0 0 0 1.24-1.48 1.13 1.13 0 0 0 .25-.6.31.31 0 0 0-.34-.33c-.25 0-.36.19-.36.51L4 11.06A1.21 1.21 0 0 1 5.28 10a1.12 1.12 0 0 1 1.24 1 1.79 1.79 0 0 1-.38 1c-.25.34-.56.66-.82 1h1.35zM4.89 17.71a1.51 1.51 0 0 0 .4 0 .42.42 0 0 0 .34-.41.37.37 0 0 0-.41-.37.39.39 0 0 0-.39.37l-.75-.33a1.13 1.13 0 0 1 1.14-.78c.65 0 1.31.38 1.31 1a.94.94 0 0 1-.46.79 1 1 0 0 1 .61.91A1.22 1.22 0 0 1 5.31 20 1.36 1.36 0 0 1 4 19.15l.76-.34a.58.58 0 0 0 .57.44.4.4 0 0 0 .44-.4c0-.34-.26-.48-.64-.48a1.29 1.29 0 0 0-.24 0z" />
          </svg>
        );
      case 'percentage':
        return (
          <svg viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <line x1="18" y1="6" x2="6" y2="18" />
              <circle cx="16" cy="16" r="2" />
              <circle cx="8" cy="8" r="2" />
            </g>
          </svg>
        );
      case 'phone':
        return (
          <svg viewBox="0 0 24 24">
            <path
              d="M13.74 15.42s1.35-.78 1.7-1a1 1 0 0 1 .94-.1c.34.21 3.16 2.08 3.39 2.25a.8.8 0 0 1 0 1.1c-.3.51-1.77 2.33-2.43 2.33s-3.28-.08-8.27-5.07S4 7.3 4 6.66 5.82 4.52 6.29 4.2a.81.81 0 0 1 1.1 0c.19.26 2 3.07 2.25 3.4a1 1 0 0 1-.1.94c-.19.35-1 1.7-1 1.7A16.8 16.8 0 0 0 11 13a16.8 16.8 0 0 0 2.74 2.42z"
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
          </svg>
        );
      case 'picture':
        return (
          <svg viewBox="0 0 24 24">
            <rect
              x="4.25"
              y="5.92"
              width="15.5"
              height="12.17"
              rx="1"
              ry="1"
              stroke="#000"
            />
            <path stroke="#000" d="M7.21 15.89l3.44-3.44 2.84 2.83 1.84-1.83 1.46 1.46" />
            <circle cx="14.63" cy="9.7" r="1.58" stroke="#000" />
          </svg>
        );
      case 'preview_guide':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M5.61 16.3a1.32 1.32 0 0 1 1.26-1.37h11.52" />
            <path d="M11.67 17.67h6.72V4.92a.88.88 0 0 0-.84-.92H6.45a.88.88 0 0 0-.84.92V16.3a1.33 1.33 0 0 0 1.26 1.37h1.64M8.78 4v10.93" />
            <path d="M11.42 17.94V20l-1.32-.77-1.32.77v-3.32h2.64v1.26" />
          </svg>
        );

      case 'print':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path
                style={baseIconStyle}
                d="M16.75,16.5H19a.93.93,0,0,0,1-.83V8.33a.93.93,0,0,0-1-.83H5a.93.93,0,0,0-1,.83v7.34a.93.93,0,0,0,1,.83H7.21"
              />
              <line style={baseIconStyle} x1="16.5" y1="16.5" x2="16.75" y2="16.5" />
              <path
                style={baseIconStyle}
                d="M15.93,13H8.07a.62.62,0,0,0-.57.65v5.7a.62.62,0,0,0,.57.65h7.86a.62.62,0,0,0,.57-.65v-5.7A.62.62,0,0,0,15.93,13Z"
              />
              <polyline style={baseIconStyle} points="7.5 7.5 7.5 4 16.5 4 16.5 7.5" />
              <line style={baseIconStyle} x1="6.5" y1="10" x2="9.5" y2="10" />
            </g>
          </svg>
        );

      case 'production':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <rect
                style={baseIconStyle}
                x="4"
                y="4"
                width="16"
                height="16"
                rx="2"
                ry="2"
              />
              <path d="M11.06,13.17V16H9.43V8h3.09a3.67,3.67,0,0,1,1.57.32,2.5,2.5,0,0,1,1.05.93,2.62,2.62,0,0,1,.36,1.37,2.29,2.29,0,0,1-.8,1.84,3.29,3.29,0,0,1-2.2.67Zm0-1.32h1.46a1.42,1.42,0,0,0,1-.31,1.09,1.09,0,0,0,.34-.87,1.22,1.22,0,0,0-1.29-1.31h-1.5Z" />
            </g>
          </svg>
        );
      case 'project_type':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M6.1 11.92a14.18 14.18 0 0 0-2 7.34.67.67 0 0 0 .68.68 14.25 14.25 0 0 0 7.33-2.05.74.74 0 0 0 .12-1.14l-4.99-4.94a.75.75 0 0 0-1.14.11zM17.53 6.45L7.9 16.09" />
            <rect
              x="15.9"
              y="4.9"
              width="4.55"
              height="1.82"
              rx=".89"
              ry=".89"
              transform="rotate(45 18.174 5.808)"
            />
          </svg>
        );
      case 'question_selected':
        return (
          <svg viewBox="0 0 24 24">
            <circle cx="12" cy="12" r="8" />
            <path
              stroke="#fff"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M11 15.38L13.43 18 19 12"
              transform="translate(-3 -2)"
            />
          </svg>
        );
      case 'questions':
        return (
          <svg viewBox="0 0 24 24">
            <path
              style={baseIconStyle}
              d="M16.24,5H11A3.76,3.76,0,0,0,7.27,8.73v2.35A3.76,3.76,0,0,0,11,14.81h4.2l2.18,1.56a.29.29,0,0,0,.47-.23V14.42A3.72,3.72,0,0,0,20,11.08V8.73A3.76,3.76,0,0,0,16.24,5Z"
            />
            <path
              style={baseIconStyle}
              d="M13.06,14.81v.38a2.68,2.68,0,0,1-2.68,2.66h-3L5.85,19a.22.22,0,0,1-.34-.17V17.57A2.65,2.65,0,0,1,4,15.19V13.52a2.67,2.67,0,0,1,2.68-2.65h.59"
            />
          </svg>
        );
      case 'recipient':
        return (
          <svg viewBox="0 0 24 24">
            <ellipse cx="10.17" cy="7.76" rx="3.79" ry="3.76" />
            <path d="M10.17 13.88A6.14 6.14 0 0 0 4 20h12.34a6.14 6.14 0 0 0-6.17-6.12z" />
            <g>
              <path d="M17.16 9.49v5.65M20 12.31h-5.69" />
            </g>
          </svg>
        );

      case 'recording_on':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <circle style={baseIconStyle} cx="7" cy="12" r="3" />
              <circle style={baseIconStyle} cx="17" cy="12" r="3" />
              <line style={baseIconStyle} x1="7" y1="15" x2="17" y2="15" />
            </g>
          </svg>
        );

      case 'recording_off':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <circle style={baseIconStyle} cx="7" cy="12" r="3" />
              <circle style={baseIconStyle} cx="17" cy="12" r="3" />
              <line style={baseIconStyle} x1="7" y1="15" x2="17" y2="15" />
              <line style={baseIconStyle} x1="5" y1="5" x2="19" y2="19" />
            </g>
          </svg>
        );

      case 'reorder':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M5.97 14.18v5.78M7.93 18.04L5.97 20 4 18.04M5.97 9.82V4.04M4 5.96L5.97 4l1.96 1.96M20 5.96h-9.34M20 9.93h-9.34M20 13.9h-9.34M20 17.87h-9.34" />
          </svg>
        );
      case 'report':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M15.11 5.33h3.56V20H5.33V5.33h3.56" />
            <path d="M15.67 7H8.33l1-3h5.34l1 3zM15.67 17.42v-6.96M12 17.42V12.7M8.33 17.42v-2.36" />
          </svg>
        );

      case 'round_arrow':
        return (
          <svg viewBox="0 0 24 24">
            <path style={baseIconStyle} d="M18.07,8.51a7,7,0,1,0,0,6.94" />
            <polyline style={baseIconStyle} points="19 5.59 19 9.25 15.2 9.25" />
          </svg>
        );

      case 'save':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <g id="Layer_2" data-name="Layer 2">
              <path
                className="cls-1"
                d="M14.85,4H5A1,1,0,0,0,4,5V19a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V9.15Z"
              />
              <polyline
                className="cls-1"
                points="7.92 20 7.92 14.31 16.08 14.31 16.08 20"
              />
              <polyline className="cls-1" points="7.92 4 7.92 8.37 14.2 8.37 14.2 4" />
              <line className="cls-1" x1="10.58" y1="8.37" x2="10.58" y2="4" />
            </g>
          </svg>
        );
      case 'search':
        return (
          <svg viewBox="0 0 24 24">
            <circle style={baseIconStyle} cx="11.13" cy="11.13" r="7.13" />
            <path style={baseIconStyle} d="M16.18 16.18L20 20" />
          </svg>
        );
      case 'send_email':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M20 8.77v9.56a1.22 1.22 0 0 1-1.22 1.22H5.22A1.22 1.22 0 0 1 4 18.33V8.77" />
            <path d="M4 11.84l8 2.19 8-2.19" />
            <g>
              <path d="M14.53 4.45L17 6.95l-2.47 2.5M16.89 6.95H7" />
            </g>
          </svg>
        );
      case 'submissions':
        return (
          <svg viewBox="0 0 24 24">
            <path
              style={baseIconStyle}
              d="M4,13.8v4.93a1.09,1.09,0,0,0,1,1.18H19a1.12,1.12,0,0,0,1-1.21V13.8"
            />
            <path
              style={baseIconStyle}
              d="M8.47,8.85H5.64S4,13.8,4,13.8H9.25a2.75,2.75,0,1,0,5.5,0H20s-1.59-5-1.64-5H15.53"
            />
            <polyline
              style={baseIconStyle}
              points="8.47 13.8 8.47 3.91 15.53 3.91 15.53 13.8"
            />
          </svg>
        );
      case 'settings':
        return (
          <svg viewBox="0 0 24 24">
            <path
              style={baseIconStyle}
              d="M19.7,9.05l-3.19,3.19-3.17-.78-.77-3.17L15.76,5.1a5.47,5.47,0,0,0-5.62,1.25,5.55,5.55,0,0,0-.9,6.45L4.29,17.75a1,1,0,0,0,0,1.38l1.38,1.39a1,1,0,0,0,1.39,0l5-4.95a5.55,5.55,0,0,0,6.45-.9A5.47,5.47,0,0,0,19.7,9.05Z"
            />
            <path style={baseIconStyle} d="M5.72,16.31a36.33,36.33,0,0,1,2.77,2.78" />
          </svg>
        );
      case 'share':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <ellipse cx="17.61" cy="6.45" rx="2.39" ry="2.45" />
            <ellipse cx="17.61" cy="17.55" rx="2.39" ry="2.45" />
            <ellipse cx="6.39" cy="12.02" rx="2.39" ry="2.45" />
            <path d="M8.54 10.95l6.92-3.44M15.46 16.49l-6.92-3.41" />
          </svg>
        );
      case 'sort':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M17.45 9.19v8.5M20 16.02l-2.55 2.5-2.56-2.5M4 5.48h16M4 9.44h10.4M4 13.39h7.12" />
          </svg>
        );
      case 'sort_column':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M8 16.02L12 20l4-3.98M16 7.98L12 4 8 7.98" />
          </svg>
        );
      case 'staging':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <rect
                style={baseIconStyle}
                x="4"
                y="4"
                width="16"
                height="16"
                rx="2"
                ry="2"
              />
              <path d="M13.4,13.88a.84.84,0,0,0-.33-.71,4.13,4.13,0,0,0-1.17-.52,7.67,7.67,0,0,1-1.34-.54,2.2,2.2,0,0,1-1.35-2A1.91,1.91,0,0,1,9.57,9a2.35,2.35,0,0,1,1-.79,3.88,3.88,0,0,1,1.52-.28,3.48,3.48,0,0,1,1.51.31,2.37,2.37,0,0,1,1,.86A2.26,2.26,0,0,1,15,10.38H13.41a1.08,1.08,0,0,0-.35-.85,1.4,1.4,0,0,0-1-.3,1.49,1.49,0,0,0-.93.25.8.8,0,0,0-.33.67.77.77,0,0,0,.39.65,4.87,4.87,0,0,0,1.14.49,4.84,4.84,0,0,1,2,1A2,2,0,0,1,15,13.87a1.91,1.91,0,0,1-.78,1.62,3.41,3.41,0,0,1-2.09.58,4.11,4.11,0,0,1-1.67-.33,2.65,2.65,0,0,1-1.14-.92A2.31,2.31,0,0,1,9,13.47H10.6c0,.87.52,1.31,1.57,1.31a1.48,1.48,0,0,0,.9-.24A.75.75,0,0,0,13.4,13.88Z" />
            </g>
          </svg>
        );

      case 'start':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <circle style={baseIconStyle} cx="12" cy="12" r="8" />
              <path d="M11.12,15.76l4.42-3a.93.93,0,0,0,0-1.6l-4.42-3C10.28,7.68,9,8.16,9,9V15C9,15.84,10.28,16.32,11.12,15.76Z" />
            </g>
          </svg>
        );

      case 'switch':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <polyline style={baseIconStyle} points="8.67 14.45 5.7 17.23 8.67 20" />
              <path style={baseIconStyle} d="M5.83,17.23H15.7a2,2,0,0,0,2-2V11.41" />
              <polyline style={baseIconStyle} points="14.73 9.55 17.7 6.78 14.73 4" />
              <path
                style={baseIconStyle}
                d="M17.57,6.77H7.63A1.93,1.93,0,0,0,5.7,8.7v3.89"
              />
            </g>
          </svg>
        );
      case 'tasks':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <line style={baseIconStyle} x1="16" y1="10.36" x2="20" y2="10.36" />
              <line style={baseIconStyle} x1="16" y1="6.03" x2="20" y2="6.03" />
              <line style={baseIconStyle} x1="4" y1="19" x2="20" y2="19" />
              <line style={baseIconStyle} x1="16" y1="14.68" x2="20" y2="14.68" />
              <rect
                style={baseIconStyle}
                x="4.06"
                y="5.94"
                width="9"
                height="9"
                rx="1"
                ry="1"
              />
              <polyline style={baseIconStyle} points="6.52 9.54 8.24 12 14.5 3" />
            </g>
          </svg>
        );
      case 'time':
        return (
          <svg viewBox="0 0 24 24">
            <defs>
              <style>
                {
                  '.cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}'
                }
              </style>
            </defs>
            <title>time</title>
            <g id="Layer_2" data-name="Layer 2">
              <ellipse className="cls-1" cx="12" cy="13.21" rx="7.03" ry="6.79" />
              <ellipse className="cls-1" cx="11.72" cy="13.2" rx="0.34" ry="0.33" />
              <polyline className="cls-1" points="14.56 16.01 11.68 13.22 11.68 8.74" />
              <line className="cls-1" x1="4.25" y1="6.83" x2="7.09" y2="4" />
              <line className="cls-1" x1="19.75" y1="6.83" x2="16.91" y2="4" />
            </g>
          </svg>
        );
      case 'timeline':
        return (
          <svg viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <line x1="3.98" y1="7.75" x2="11.46" y2="7.75" />
              <line x1="8.26" y1="12" x2="15.74" y2="12" />
              <line x1="12.5" y1="16.25" x2="19.98" y2="16.25" />
            </g>
          </svg>
        );
      case 'tick_select':
        return (
          <svg viewBox="0 0 32 32">
            <circle className="cls-1" cx="16" cy="16" r="12" />
            <polyline className="cls-2" points="10.18 16.51 13.72 20.05 21.82 11.95" />
          </svg>
        );
      case 'tick_fill':
        return (
          <svg viewBox="0 0 24 24">
            <path
              style={{
                fill: 'currentColor',
                stroke: '#fff',
                strokeWidth: 0,
              }}
              d="M12,4.5A7.5,7.5,0,1,0,19.5,12,7.5,7.5,0,0,0,12,4.5ZM16.42,10l-4.94,5.33a1,1,0,0,1-1.47,0L7.58,12.72A1,1,0,0,1,9,11.36l1.71,1.83L15,8.65A1,1,0,1,1,16.42,10Z"
            />
          </svg>
        );
      case 'too_complex':
        return (
          <svg viewBox="0 0 32 32">
            <path d="M24.24,21.37V7A1.72,1.72,0,0,0,22.51,5.3H6.89V25.19A1.52,1.52,0,0,0,8.4,26.7h.91" />
            <path d="M24.24,21.49H9.2v0a2.63,2.63,0,0,1,0,5.15v0H25.65a2.62,2.62,0,0,0,0-5.21H24.24" />
            <path d="M6.35,5.3A2.49,2.49,0,0,0,4,7.9a2.49,2.49,0,0,0,2.35,2.61" />
            <circle cx="15.55" cy="13.26" r="4.2" />
            <path d="M14.54,12a1,1,0,1,1,1,1.17v.35" />
            <line x1="15.55" y1="15.26" x2="15.55" y2="15.3" />
          </svg>
        );
      case 'tooltip':
        return (
          <svg viewBox="0 0 24 24">
            <path d="M12 4a8 8 0 1 0 8 8 8 8 0 0 0-8-8zm1 12.3a1 1 0 1 1-2.06 0 1.07 1.07 0 0 1 1-1.08 1 1 0 0 1 1 1zm0-3.3a1 1 0 0 1-2-.12v-.79a1 1 0 0 1 1-1 1.16 1.16 0 1 0-1.14-1.37 1 1 0 1 1-2-.37A3.23 3.23 0 1 1 13 13z" />
          </svg>
        );
      case 'triangle_left':
        return (
          <svg viewBox="0 0 24 24">
            <path
              style={{
                ...baseIconStyle,
                fill: 'currentColor',
              }}
              d="M13,8.46c0-.49-.28-.61-.62-.26L9.25,11.37a.93.93,0,0,0,0,1.26l3.13,3.17c.34.35.62.23.62-.26Z"
            />
          </svg>
        );
      case 'triangle_right':
        return (
          <svg viewBox="0 0 24 24">
            <path
              style={{
                ...baseIconStyle,
                fill: 'currentColor',
              }}
              d="M10,15.54c0,.49.28.61.62.26l3.13-3.17a.93.93,0,0,0,0-1.26L10.62,8.2C10.28,7.85,10,8,10,8.46Z"
            />
          </svg>
        );

      case 'tree':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M4 13.89h6.04V20H4zM13.96 13.89H20V20h-6.04zM7.02 13.89V9.94h9.96v3.95M12.04 9.94V4" />
          </svg>
        );
      case 'tree_branch':
        return (
          <svg viewBox="0 0 24 24">
            <path
              stroke="#000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M4 4v16h16"
            />
          </svg>
        );
      case 'unordered':
        return (
          <svg viewBox="0 0 24 24">
            <path d="M9.12 6h10.32a.56.56 0 1 0 0-1.11H9.12a.56.56 0 0 0 0 1.11zM19.44 11.45H9.12a.55.55 0 0 0 0 1.1h10.32a.55.55 0 1 0 0-1.1zM5.47 4A1.47 1.47 0 1 0 7 5.47 1.47 1.47 0 0 0 5.47 4z" />
            <ellipse cx="5.47" cy="12" rx="1.47" ry="1.46" />
            <path d="M5.47 17.07A1.47 1.47 0 1 0 7 18.53a1.47 1.47 0 0 0-1.53-1.46zM19.44 18H9.12a.56.56 0 0 0 0 1.11h10.32a.56.56 0 1 0 0-1.11z" />
          </svg>
        );

      case 'user':
        return (
          <svg viewBox="0 0 24 24">
            <ellipse style={baseIconStyle} cx="12" cy="7.15" rx="3.11" ry="3.15" />
            <path
              style={baseIconStyle}
              d="M12,13.57a9.06,9.06,0,0,0-7,3A2.09,2.09,0,0,0,6.56,20H17.44A2.09,2.09,0,0,0,19,16.57,9.06,9.06,0,0,0,12,13.57Z"
            />
          </svg>
        );

      case 'users':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <ellipse style={baseIconStyle} cx="9.18" cy="11.24" rx="2.58" ry="2.56" />
              <path
                style={baseIconStyle}
                d="M9.18,16C6.32,16,4,17.8,4,20H14.37C14.37,17.8,12.05,16,9.18,16Z"
              />
              <ellipse style={baseIconStyle} cx="14.82" cy="6.56" rx="2.58" ry="2.56" />
              <path style={baseIconStyle} d="M15.19,15.32H20c0-2.2-2.32-4-5.18-4h-.43" />
            </g>
          </svg>
        );

      case 'view':
        return (
          <svg viewBox="0 0 24 24">
            <ellipse style={baseIconStyle} cx="12" cy="12" rx="8" ry="5.25" />
            <circle style={baseIconStyle} cx="12" cy="12" r="2.13" />
          </svg>
        );

      case 'warning':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <circle cx="12" cy="12" r="8" />
            <path d="M12 7.08v5.5" />
            <circle cx="12" cy="16.08" r=".5" />
          </svg>
        );
      case 'warning_fill':
        return (
          <svg viewBox="0 0 24 24">
            <path
              style={{
                fill: 'currentColor',
                stroke: '#fff',
                strokeWidth: 0,
              }}
              d="M12,4a8,8,0,1,0,8,8A8,8,0,0,0,12,4ZM11,7.19a1,1,0,0,1,2,0v5.5a1,1,0,0,1-2,0Zm1,10.62a1,1,0,1,1,1-1A1,1,0,0,1,12,17.81Z"
            />
          </svg>
        );
      case 'zoom':
        return (
          <svg viewBox="0 0 24 24">
            <defs />
            <path d="M20 20l-4.38-4.38" />
            <circle cx="10.81" cy="10.81" r="6.81" />
            <path d="M10.81 6.83v7.96M14.79 10.81H6.83" />
          </svg>
        );
      case 'website':
        return (
          <svg viewBox="0 0 24 24">
            <path d="M9.08,19a7.7,7.7,0,1,1,9.82-9.9" />
            <polygon points="20 12.74 9.08 9.06 12.76 20 14.42 16.27 18.11 19.98 19.98 18.11 16.28 14.41 20 12.74" />
          </svg>
        );
      case 'file':
        return (
          <svg viewBox="0 0 24 24">
            <polyline points="18.5 9.89 18.5 20 5.5 20 5.5 4 12.61 4" />
            <polygon points="12.61 4 12.61 9.89 18.5 9.89 12.61 4" />
          </svg>
        );
      case 'details':
        return (
          <svg viewBox="0 0 24 24">
            <polyline points="17.78 15.39 17.78 20 5.5 20 5.5 5.33 8.77 5.33" />
            <line x1="14.5" y1="5.33" x2="17.78" y2="5.33" />
            <polygon points="15.01 7 8.26 7 9.18 4 14.09 4 15.01 7" />
            <path d="M20.27,7.77l-.57-.59a.76.76,0,0,0-1.09,0L11.44,14.6,10.77,17l2.33-.69L20.27,8.9A.8.8,0,0,0,20.27,7.77Z" />
          </svg>
        );
      case 'map':
        return (
          <svg viewBox="0 0 24 24">
            <line x1="9.33" y1="20" x2="9.33" y2="8" />
            <line x1="14.67" y1="4" x2="14.67" y2="16" />
            <polygon points="9.33 8 4 4 4 16 9.33 20 14.67 16 20 20 20 8 14.67 4 9.33 8" />
          </svg>
        );
      case 'authorized':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M6.62,15.5C8.13,18.11,12,20,12,20s6-2.92,6-6.51V7C15,7,12.58,5.88,12.08,4.4L12,4l-.14.4C11.32,5.87,8.92,7,6,7v6.51A4.07,4.07,0,0,0,6.62,15.5Z" />
              <polyline points="9.5 12.25 11.02 14 14.5 10" />
            </g>
          </svg>
        );
      case 'buildings':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path
                style={baseIconStyle}
                d="M12.46,20,11.83,6.18C11.77,5,11,4,10.17,4H6.28c-.86,0-1.6,1-1.66,2.18L4,19.79V20H17.18a2,2,0,0,0,2-2V13a2,2,0,0,0-2-2H12.05"
              />
              <line style={baseIconStyle} x1="7.1" y1="7.75" x2="9.1" y2="7.75" />
              <line style={baseIconStyle} x1="7.1" y1="11" x2="9.1" y2="11" />
              <line style={baseIconStyle} x1="7.1" y1="14.25" x2="9.1" y2="14.25" />
            </g>
          </svg>
        );
      case 'permit':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M15,4h1.6A2,2,0,0,1,18.5,6.09V17.91A2,2,0,0,1,16.63,20H7.37A2,2,0,0,1,5.5,17.91V6.09A2,2,0,0,1,7.37,4H15Z" />
              <polyline points="8.09 4 8.09 12 10.38 10 12.66 12 12.66 4" />
              <line x1="8.09" y1="16.13" x2="13.96" y2="16.13" />
            </g>
          </svg>
        );
      case 'license':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M9.54,7.13H18A1.86,1.86,0,0,1,20,8.68v8.77A1.85,1.85,0,0,1,18,19H7.79a1.85,1.85,0,0,1-2-1.55V12.89" />
              <polygon points="8.14 5 9.42 7.6 12.28 8.02 10.21 10.04 10.7 12.89 8.14 11.55 5.58 12.89 6.07 10.04 4 8.02 6.86 7.6 8.14 5" />
              <line x1="9.73" y1="15.77" x2="16" y2="15.77" />
            </g>
          </svg>
        );
      case 'certificate':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M13.09,16.49l-.16.16a1.29,1.29,0,0,1-1.86,0l-.16-.16a3.62,3.62,0,0,0-2.24-.95H8.45a1.34,1.34,0,0,1-1.31-1.36V14a3.85,3.85,0,0,0-.93-2.31l-.16-.16a1.38,1.38,0,0,1,0-1.91l.16-.16A3.85,3.85,0,0,0,7.14,7.1V6.87A1.34,1.34,0,0,1,8.45,5.51h.22a3.62,3.62,0,0,0,2.24-1l.16-.17a1.3,1.3,0,0,1,1.86,0l.16.17a3.62,3.62,0,0,0,2.24,1h.22a1.34,1.34,0,0,1,1.31,1.36V7.1a3.85,3.85,0,0,0,.93,2.31l.16.16a1.38,1.38,0,0,1,0,1.91l-.16.16A3.85,3.85,0,0,0,16.86,14v.23a1.34,1.34,0,0,1-1.31,1.36h-.22a3.62,3.62,0,0,0-2.24.95" />
              <ellipse cx="11.95" cy="10.48" rx="2.58" ry="2.66" />
              <polyline points="10.77 16.45 9.78 20 8.2 17.37 5.5 18.73 7.03 13.25" />
              <polyline points="13.21 16.38 14.22 20 15.8 17.37 18.5 18.73 16.97 13.25" />
            </g>
          </svg>
        );
      case 'document':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M18.5,9.89v8.2A1.92,1.92,0,0,1,16.59,20H7.41A1.92,1.92,0,0,1,5.5,18.09V5.91A1.92,1.92,0,0,1,7.41,4h5.2" />
              <polygon points="12.61 4 12.61 9.89 18.5 9.89 12.61 4" />
            </g>
          </svg>
        );
      case 'documents':
        return (
          <svg viewBox="0 0 24 24">
            <g id="Layer_2" data-name="Layer 2">
              <rect x="5" y="8" width="10" height="12" />
              <line x1="8" y1="11" x2="12" y2="11" />
              <line x1="8" y1="14" x2="12" y2="14" />
              <polyline points="9 8 9 4 19 4 19 16 15 16" />
            </g>
          </svg>
        );
      case 'dot_marker':
        return (
          <svg width="33px" height="33px" viewBox="0 0 33 33">
            <g fillRule="evenodd" transform="translate(-704.000000, -504.000000)">
              <g transform="translate(705.000000, 505.000000)">
                <circle cx="15.5" cy="15.5" r="15.5" />
                <circle cx="15.5" cy="15.5" r="6.5" />
              </g>
            </g>
          </svg>
        );
      case 'pin':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <line x1="12" y1="12" x2="12" y2="20" />
              <line x1="9.75" y1="20" x2="14.25" y2="20" />
              <circle cx="12" cy="8" r="4" />
            </g>
          </svg>
        );
      case 'settings_cog':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path
                strokeWidth="2px"
                d="M20.56,10.3l-2.09-.46A.56.56,0,0,1,18.11,9l1.17-1.82a.56.56,0,0,0-.08-.7L17.55,4.83a.56.56,0,0,0-.7-.08L15.05,5.9a.57.57,0,0,1-.86-.35l-.46-2.11A.56.56,0,0,0,13.18,3H10.85a.57.57,0,0,0-.55.44L9.84,5.53A.56.56,0,0,1,9,5.89L7.17,4.72a.56.56,0,0,0-.7.08L4.83,6.45a.56.56,0,0,0-.08.7L5.9,9a.57.57,0,0,1-.35.86l-2.11.46a.56.56,0,0,0-.44.55v2.33a.57.57,0,0,0,.44.55l2.09.46a.56.56,0,0,1,.36.85L4.72,16.83a.56.56,0,0,0,.08.7l1.65,1.64a.56.56,0,0,0,.7.08L9,18.1a.56.56,0,0,1,.85.35l.47,2.11a.56.56,0,0,0,.55.44h2.33a.55.55,0,0,0,.54-.44l.47-2.09a.56.56,0,0,1,.85-.36l1.82,1.17a.56.56,0,0,0,.7-.08l1.64-1.65a.56.56,0,0,0,.08-.7l-1.15-1.8a.56.56,0,0,1,.35-.85l2.11-.47a.56.56,0,0,0,.44-.55V10.85A.57.57,0,0,0,20.56,10.3ZM12,14.82A2.82,2.82,0,1,1,14.82,12,2.82,2.82,0,0,1,12,14.82Z"
              />
            </g>
          </svg>
        );
      case 'scroll':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M6.62,8H4.8A1.91,1.91,0,0,1,3,6H3A1.91,1.91,0,0,1,4.8,4H15.58a1.91,1.91,0,0,1,1.8,2V16" />
              <path d="M9.32,16H19.2A1.91,1.91,0,0,1,21,18h0a1.91,1.91,0,0,1-1.8,2H9.32" />
              <path d="M6.62,4V17.12A2.8,2.8,0,0,0,9.32,20h0a1.91,1.91,0,0,0,1.79-2h0a1.91,1.91,0,0,0-1.79-2" />
            </g>
          </svg>
        );
      case 'void':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <circle strokeWidth="2px" cx="12" cy="12" r="8" />
              <line strokeWidth="2px" x1="17.41" y1="6.59" x2="6.34" y2="17.66" />
            </g>
          </svg>
        );
      case 'void_alt':
        return (
          <svg viewBox="0 0 24 24">
            <path d="M12 5a7 7 0 00-5 11.93A7 7 0 1012 5zm0 2.8a4.18 4.18 0 011.8.42L8.22 13.8a4.17 4.17 0 013.78-6zm0 8.4a4.18 4.18 0 01-1.8-.42l5.58-5.58a4.17 4.17 0 01-3.78 6z" />
          </svg>
        );
      case 'accepting_applications':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M17.88,14.9l-2.81-2.8,2.29-2.29a.42.42,0,0,0-.18-.7L6.54,6A.41.41,0,0,0,6,6.54L9.11,17.18a.42.42,0,0,0,.7.18l2.29-2.29,2.8,2.81a.43.43,0,0,0,.6,0l2.38-2.38A.43.43,0,0,0,17.88,14.9Z" />
            </g>
          </svg>
        );
      case 'guide_only':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <rect x="6" y="4" width="12" height="16" rx="1" />
              <polygon points="12 14 10 12 8 14 8 6 12 6 12 14" />
            </g>
          </svg>
        );
      case 'hidden':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <ellipse cx="12" cy="12" rx="8" ry="5.5" />
              <circle cx="12" cy="12" r="2.25" />
              <line x1="20" y1="4" x2="4" y2="20" />
            </g>
          </svg>
        );
      case 'toggle_visibility_off':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <path d="M19.72,18.37,5.63,4.28A1,1,0,0,0,4.28,5.63L7.22,8.57A8.1,8.1,0,0,0,4.47,12a8.14,8.14,0,0,0,10.58,4.4l3.32,3.32a1,1,0,0,0,1.35,0A1,1,0,0,0,19.72,18.37ZM12,15.5A3.5,3.5,0,0,1,8.5,12,3.37,3.37,0,0,1,9,10.3l1.05,1a2,2,0,0,0-.11.65A2.12,2.12,0,0,0,12,14.11a2,2,0,0,0,.65-.11l1,1.05A3.37,3.37,0,0,1,12,15.5Z" />
              <path d="M12,8.5A3.5,3.5,0,0,1,15.5,12a4.62,4.62,0,0,1-.06.65l2.14,2.14a8.22,8.22,0,0,0,2-2.8A8.17,8.17,0,0,0,12,7a8,8,0,0,0-2,.25l1.32,1.31A4.62,4.62,0,0,1,12,8.5Z" />
            </g>
          </svg>
        );
      case 'toggle_visibility_on':
        return (
          <svg viewBox="0 0 24 24">
            <path d="M12 7a8.17 8.17 0 00-7.51 5 8.15 8.15 0 0015 0A8.15 8.15 0 0012 7zm0 8.47A3.49 3.49 0 1115.49 12 3.49 3.49 0 0112 15.49z" />
            <circle cx="12" cy="12" r="2.1" transform="rotate(-9.22 12.013 12.016)" />
          </svg>
        );
      case 'workflow':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <circle style={baseIconStyle} cx="6" cy="6" r="2" />
              <circle style={baseIconStyle} cx="18" cy="18" r="2" />
              <polyline
                style={baseIconStyle}
                points="6 8.8 6 16 12 16 12 8 18 8 18 15.2"
              />
            </g>
          </svg>
        );
      case 'toggle_visible_off':
        return (
          <svg viewBox="0 0 24 24">
            <path d="M19.72,18.37,5.63,4.28A1,1,0,0,0,4.28,5.63L7.22,8.57A8.1,8.1,0,0,0,4.47,12a8.14,8.14,0,0,0,10.58,4.4l3.32,3.32a1,1,0,0,0,1.35,0A1,1,0,0,0,19.72,18.37ZM12,15.5A3.5,3.5,0,0,1,8.5,12,3.37,3.37,0,0,1,9,10.3l1.05,1a2,2,0,0,0-.11.65A2.12,2.12,0,0,0,12,14.11a2,2,0,0,0,.65-.11l1,1.05A3.37,3.37,0,0,1,12,15.5Z" />
            <path d="M12,8.5A3.5,3.5,0,0,1,15.5,12a4.62,4.62,0,0,1-.06.65l2.14,2.14a8.22,8.22,0,0,0,2-2.8A8.17,8.17,0,0,0,12,7a8,8,0,0,0-2,.25l1.32,1.31A4.62,4.62,0,0,1,12,8.5Z" />
          </svg>
        );
      case 'gis_loader':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <circle
                style={{ fill: '#8892a8', stroke: 'none' }}
                cx="12.04"
                cy="4.13"
                r="2.5"
              />
              <circle style={{ fill: '#8892a8', stroke: 'none' }} cx="4" cy="12" r="2" />
              <circle
                style={{ fill: '#8892a8', stroke: 'none' }}
                cx="6"
                cy="6.63"
                r="2.25"
              />
              <circle
                style={{ fill: '#8892a8', stroke: 'none' }}
                cx="6"
                cy="17.84"
                r="1.75"
              />
              <circle
                style={{ fill: '#8892a8', stroke: 'none' }}
                cx="12"
                cy="20"
                r="1.5"
              />
              <circle
                style={{ fill: '#8892a8', stroke: 'none' }}
                cx="17.75"
                cy="17.34"
                r="1.25"
              />
              <circle style={{ fill: '#8892a8', stroke: 'none' }} cx="20" cy="12" r="1" />
              <circle
                style={{ fill: '#8892a8', stroke: 'none' }}
                cx="18.5"
                cy="7.38"
                r="0.75"
              />
            </g>
          </svg>
        );
      case 'gis_rest':
        return (
          <svg viewBox="0 0 40 40">
            <path
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              style={{ fill: 'none', stroke: '#494e63' }}
              d="M27,25h3a5,5,0,0,0,0-10h-.44A7.38,7.38,0,0,0,30,12.5,7.5,7.5,0,0,0,15.42,10c-.14,0-.28,0-.42,0a5,5,0,0,0-5,5,5,5,0,0,0,0,10h3"
            />
            <polygon
              strokeWidth={1.5}
              strokeLinecap="round"
              strokeLinejoin="round"
              style={{ fill: '#8ebaff', stroke: '#494e63' }}
              points="23 28 23 19 17 19 17 28 13 28 20 36 27 28 23 28"
            />
          </svg>
        );
      case 'gis_shapefile':
        return (
          <svg viewBox="0 0 40 40">
            <g>
              <line
                fill="none"
                strokeWidth={2}
                stroke="#494e63"
                strokeLinecap="round"
                strokeLinejoin="round"
                x1="32.5"
                y1="12.5"
                x2="31.03"
                y2="12.21"
              />
              <line
                fill="none"
                strokeWidth={2}
                stroke="#494e63"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="3.21 3.21"
                x1="27.88"
                y1="11.58"
                x2="10.55"
                y2="8.11"
              />
              <line
                fill="none"
                strokeWidth={2}
                stroke="#494e63"
                strokeLinecap="round"
                strokeLinejoin="round"
                x1="8.97"
                y1="7.79"
                x2="7.5"
                y2="7.5"
              />
              <polyline
                fill="none"
                strokeWidth={2}
                stroke="#494e63"
                strokeLinecap="round"
                strokeLinejoin="round"
                points="7.5 7.5 9.5 32.5 27.5 29.5 32.5 12.5"
              />
              <circle
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ fill: '#8ebaff', stroke: '#494e63' }}
                strokeWidth={1.5}
                cx="7.5"
                cy="7.5"
                r="2.5"
              />
              <circle
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ fill: '#8ebaff', stroke: '#494e63' }}
                strokeWidth={1.5}
                cx="32.5"
                cy="12.5"
                r="2.5"
              />
              <circle
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ fill: '#8ebaff', stroke: '#494e63' }}
                strokeWidth={1.5}
                cx="9.5"
                cy="32.5"
                r="2.5"
              />
              <circle
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ fill: '#8ebaff', stroke: '#494e63' }}
                strokeWidth={1.5}
                cx="27.5"
                cy="29.5"
                r="2.5"
              />
            </g>
          </svg>
        );
      case 'gis_zip':
        return (
          <svg viewBox="0 0 60 60">
            <g>
              <path
                style={{ fill: '#8892a8', stroke: 'none' }}
                d="M53,18.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-14-14h0a1.07,1.07,0,0,0-.28-.19l-.09,0A1.31,1.31,0,0,0,38.06,4H15.77A4.78,4.78,0,0,0,11,8.77V34.14H9a3,3,0,0,0-3,3v8a3,3,0,0,0,3,3h2v3.09A4.78,4.78,0,0,0,15.77,56H48.23A4.78,4.78,0,0,0,53,51.23V19S53,19,53,18.94ZM39,7.41,49.59,18H41.77A2.77,2.77,0,0,1,39,15.23ZM20,26a1,1,0,0,1,1,1c0,.82-.68,2-1,2s-1-1.18-1-2A1,1,0,0,1,20,26ZM8,45.14v-8a1,1,0,0,1,1-1H28a1,1,0,0,1,1,1v8a1,1,0,0,1-1,1H9A1,1,0,0,1,8,45.14ZM48.23,54H15.77A2.77,2.77,0,0,1,13,51.23V48.14H28a3,3,0,0,0,3-3v-8a3,3,0,0,0-3-3H13V8.77A2.77,2.77,0,0,1,15.77,6H19V8H18a1,1,0,0,0,0,2h1v2H18a1,1,0,0,0,0,2h1v2H18a1,1,0,0,0,0,2h1v2H18a1,1,0,0,0,0,2h1v2.18A3,3,0,0,0,17,27c0,1.59,1.2,4,3,4s3-2.41,3-4a3,3,0,0,0-2-2.82V21h1a1,1,0,0,0,0-2H21V17h1a1,1,0,0,0,0-2H21V13h1a1,1,0,0,0,0-2H21V9h1a1,1,0,0,0,0-2H21V6H37v9.23A4.78,4.78,0,0,0,41.77,20H51V51.23A2.77,2.77,0,0,1,48.23,54Z"
              />
              <polygon
                style={{ fill: '#8892a8', stroke: 'none' }}
                points="15.8 43.34 12.3 43.34 15.72 38.78 15.72 37.14 9.59 37.14 9.59 38.94 13 38.94 9.59 43.49 9.59 45.14 15.8 45.14 15.8 43.34"
              />
              <rect
                x="17"
                y="37.14"
                width="2.06"
                height="8"
                style={{ fill: '#8892a8', stroke: 'none' }}
              />
              <path
                style={{ fill: '#8892a8', stroke: 'none' }}
                d="M22.56,42.43h2a2.65,2.65,0,1,0,0-5.29h-4v8h2.06Zm0-3.55h1.67a.9.9,0,0,1,1,.91.89.89,0,0,1-1,.89H22.56Z"
              />
            </g>
          </svg>
        );
      case 'upload':
        return (
          <svg viewBox="0 0 24 24">
            <g>
              <polygon points="10.09 8.95 10.09 15.69 13.91 15.69 13.91 8.95 16.35 8.95 12 4 7.65 8.95 10.09 8.95" />
              <path d="M6,12H6c-1.1,0-2,.6-2,1.35v5.3C4,19.4,4.9,20,6,20H18c1.1,0,2-.6,2-1.35v-5.3c0-.75-.9-1.35-2-1.35" />
            </g>
          </svg>
        );
      default:
        return null;
    }
  }

  render() {
    const { name, size, className, useAntDIcon, ...restProps } = this.props;
    const sizeClass = typeof size === 'string' ? size : null;
    return (
      <div
        className={classNames(styles.Icon, styles[sizeClass], className)}
        style={!sizeClass ? { height: size, width: size } : null}
        {...omit(restProps, ['antDFilled'])}
      >
        {this.renderIcon(name, useAntDIcon)}
      </div>
    );
  }
}
