import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './LegendItem.scss';

/**
 * Component for a legend's icon
 */
export default class LegendItem extends Component {
  static propTypes = {
    /** The color of the legend icon */
    color: PropTypes.string.isRequired,

    /** The size of the legend icon */
    size: PropTypes.number,
  };

  static defaultProps = {
    size: 14,
  };

  render() {
    const { color, size, ...restProps } = this.props;
    return (
      <div className={styles.LegendItem} style={{ width: size }} {...restProps}>
        <div
          className={styles.LegendItem__dot}
          style={{ background: color, height: size, width: size }}
        />
      </div>
    );
  }
}
