import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Table as AntdTable } from 'antd';
import { Sorter, Column } from './types';
import styles from './Table.scss';

export const StaticTable = ({
  dataSource,
  enableResizableColumnsAndHorizScroll,
  loading,
  locale,
  rowKey,
  onRow,
  sorter,
  columns,
  className,
  rowClassName,
  thinRows,
  ...restProps
}) => {
  return (
    <AntdTable
      columns={columns}
      className={classNames(styles.Table, className, {
        [styles.NormalRows]: !thinRows,
        [styles.ThinRows]: thinRows,
      })}
      dataSource={dataSource}
      pagination={false}
      loading={loading}
      locale={locale}
      onChange={sorter && ((_pagination, _filters, sort) => sorter(sort))}
      sortDirections={['descend', 'ascend']}
      onRow={onRow}
      rowClassName={row =>
        classNames(
          typeof rowClassName === 'function' ? rowClassName(row) : rowClassName,
          { [styles.ClickableRows]: !!onRow },
        )
      }
      rowKey={rowKey}
      sorter={sorter}
      {...restProps}
    />
  );
};

StaticTable.propTypes = {
  dataSource: PropTypes.array,
  enableResizableColumnsAndHorizScroll: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.shape(Column)),
  sorter: Sorter,
  enablePagination: PropTypes.bool,
  paginationMeta: PropTypes.object,
  onChangePage: PropTypes.func,
  loading: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      spinning: PropTypes.bool,
      indicator: PropTypes.node,
    }),
  ]),
  locale: PropTypes.object,
  rowKey: PropTypes.func,
  onRow: PropTypes.func,
  className: PropTypes.string,
  rowClassName: PropTypes.string,
  thinRows: PropTypes.bool,
};

StaticTable.defaultProps = {
  dataSource: [],
  columns: [],
  enablePagination: false,
  enableResizableColumnsAndHorizScroll: true,
  paginationMeta: { current_page: 1, total_count: 1 },
  onChangePage: undefined,
  loading: false,
  locale: null,
  rowKey: undefined,
  sorter: undefined,
  onRow: undefined,
  className: undefined,
  rowClassName: undefined,
  thinRows: false,
};

export default StaticTable;
