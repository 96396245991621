import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';
import { RawButton as Button } from '../Button';
import ButtonGroup from '../ButtonGroup';
import CircleIcon from '../CircleIcon';
import Heading from '../Heading';
import Modal from '../Modal';
import Text from '../Text';
import styles from './AlertDanger.scss';

const Context = createContext();

export default function AlertDanger({
  children,
  icon,
  status,
  showCancel,
  showConfirm,
  confirmIcon,
  cancelText,
  confirmText,
  isLoading,
  onCancel,
  onConfirm,
}) {
  const color = status === 'danger' ? 'red' : 'blue';

  return (
    <Context.Provider value={{ color }}>
      <Modal
        className={styles.Modal}
        contentClassName={styles.ModalContent}
        isOpen={true}
        onRequestClose={onCancel}
        contentLabel="Delete Result"
      >
        <div className={styles.Icon}>
          <CircleIcon
            name={icon}
            type={status === 'danger' ? 'error' : 'primary'}
            size="small"
          />
        </div>
        {children}
        {(showCancel || showConfirm) && (
          <div className={styles.Actions}>
            <ButtonGroup fullWidth alignRight>
              {showCancel && (
                <Button autoFocus type={Button.OUTLINE} onClick={onCancel}>
                  {cancelText}
                </Button>
              )}
              {showConfirm && (
                <Button
                  type={status === 'danger' ? Button.DANGER : Button.PRIMARY}
                  icon={confirmIcon}
                  loading={isLoading}
                  onClick={onConfirm}
                >
                  {confirmText}
                </Button>
              )}
            </ButtonGroup>
          </div>
        )}
      </Modal>
    </Context.Provider>
  );
}

AlertDanger.defaultProps = {
  icon: 'delete',
  status: 'danger',
  confirmIcon: 'delete',
  cancelText: 'Cancel',
  confirmText: 'Delete',
  showCancel: true,
  showConfirm: true,
  isLoading: false,
  onCancel: () => {},
  onConfirm: () => {},
};

AlertDanger.propTypes = {
  icon: PropTypes.string,
  status: PropTypes.oneOf(['danger', 'info']),
  confirmIcon: PropTypes.string,
  cancelText: PropTypes.string,
  showCancel: PropTypes.bool,
  showConfirm: PropTypes.bool,
  isLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
};

AlertDanger.Header = AlertDangerHeader;
AlertDanger.Text = AlertDangerText;

function AlertDangerHeader({ children }) {
  const { color } = useContext(Context);

  return (
    <Heading color={color} size="medium" className={styles.Header}>
      {children}
    </Heading>
  );
}

function AlertDangerText({ children }) {
  return (
    <Text as="p" className={styles.Text}>
      {children}
    </Text>
  );
}
