import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import useFilePreview from '../../hooks/useFilePreview';
import Button from '../Button';
import FieldError from '../FieldError';
import Icon from '../Icon';
import Text from '../Text';
import styles from './FileField.scss';

export function filename(path) {
  return path.replace(/^.*(\\|\/|:)/, '').split(/[?#]/)[0];
}

export function extension(path) {
  return filename(path).split('.').pop();
}

const Preview = ({ selected, enableUploads, allowed, getRootProps, getInputProps }) => {
  const missing = !selected || (selected && allowed.includes(extension(selected.name)));
  const text = selected ? extension(selected.name) : null;
  const filePreview = useFilePreview(selected);
  const preview = selected && (selected.preview || filePreview);
  return (
    <div {...getRootProps({ className: styles.Preview })}>
      <input {...getInputProps()} />
      <div className={styles.Image}>
        {enableUploads && (
          <div className={styles.Overlay}>
            <Icon name="export" />
          </div>
        )}
        {missing ? (
          <div className={styles.Placeholder}>{text}</div>
        ) : (
          <img src={preview} alt={selected.name} />
        )}
      </div>
    </div>
  );
};

Preview.propTypes = {
  selected: PropTypes.object,
  enableUploads: PropTypes.bool,
  allowed: PropTypes.array.isRequired,
  getRootProps: PropTypes.func.isRequired,
  getInputProps: PropTypes.func.isRequired,
};

Preview.defaultProps = {
  selected: null,
  enableUploads: false,
};

export default class FileField extends Component {
  static propTypes = {
    meta: PropTypes.object,
    onClick: PropTypes.func,
    input: PropTypes.object,
    label: PropTypes.string,
    multiple: PropTypes.bool,
    buttonText: PropTypes.string,
    enableButton: PropTypes.bool,
    enableUploads: PropTypes.bool,
    enableFileName: PropTypes.bool,
    showRequired: PropTypes.bool,
    showOptionalTag: PropTypes.bool,
    errorWeight: PropTypes.oneOf(['thin', 'heavy']),
  };

  static defaultProps = {
    multiple: false,
    enableUploads: true,
    enableButton: false,
    enableFileName: true,
    onClick: () => false,
    buttonText: 'Confirm',
    meta: {
      error: null,
      touched: false,
      pristine: true,
    },
    input: {
      value: '',
    },
    label: null,
    showRequired: false,
    showOptionalTag: false,
    errorWeight: 'heavy',
  };

  onDrop = (accepted, rejected, e) => {
    const { dataTransfer } = e;
    const {
      input: { onChange, onBlur },
    } = this.props;
    const data = (dataTransfer && dataTransfer.files) || accepted || null;
    onBlur(data);
    onChange(data);
  };

  isSelected = () => {
    const {
      input,
      meta: { pristine },
    } = this.props;
    return pristine
      ? {
          name: input.value,
          preview: input.value,
          extension: extension(input.value),
        }
      : (input && input.value && input.value[0]) || null;
  };

  onClick = e => {
    e.preventDefault();
    if (this.isSelected()) {
      this.props.onClick(e);
    }
  };

  render() {
    const {
      input,
      enableUploads,
      showRequired,
      showOptionalTag,
      meta,
      errorWeight,
    } = this.props;
    const selected = this.isSelected();

    return (
      <div className={styles.FileField}>
        <input type="hidden" disabled {...input} />
        <div className={styles.HeadingWrapper}>
          <label htmlFor={this.props.id} className={styles.Label}>
            <Text size="medium" weight="medium" color="grey" as="span" uppercase>
              {this.props.label}
              {showRequired && <span className={styles.Required}>*</span>}
            </Text>
          </label>
          {showOptionalTag && <span className={styles.HeadingOptional}>Optional</span>}
        </div>
        <div className={styles.Content}>
          <div>
            <Dropzone
              ref={this.props.dropzoneRef}
              onDrop={this.onDrop}
              className={styles.Dropzone}
              disabled={!this.props.enableUploads}
              multiple={this.props.multiple}
            >
              {({ getRootProps, getInputProps }) => (
                <Preview
                  selected={selected}
                  enableUploads={enableUploads}
                  allowed={['pdf', 'zip', 'doc', 'docx', 'xls', 'xlsx']}
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                />
              )}
            </Dropzone>
            {this.props.enableButton ? (
              <div>
                <Button
                  icon="export"
                  size="small"
                  disabled={!selected}
                  type={Button.OUTLINE}
                  className={styles.Button}
                  onClick={this.onClick}
                >
                  {this.props.buttonText}
                </Button>
              </div>
            ) : null}
          </div>
          <div className={styles.Details}>
            {this.props.enableFileName && selected ? (
              <div className={styles.FileName}>{filename(selected.name)}</div>
            ) : null}
          </div>
        </div>
        <FieldError
          error={meta && meta.touched && meta.error}
          warning={meta && meta.touched && meta.warning}
          thin={errorWeight === 'thin'}
        />
      </div>
    );
  }
}
