import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Radio } from 'antd';
import isArray from 'lodash/isArray';
import SelectField from '../ReduxFormFields/SelectField';
import Heading from '../Heading/index';
import styles from './FilterItem.scss';

const filterItemPropTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any,
    }),
  ).isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

function FilterSelect({ options, value, placeholder, onChange }) {
  return (
    <SelectField
      placeholder={placeholder}
      options={options}
      input={{ value, onChange }}
      className={styles.Select}
    />
  );
}
FilterSelect.propTypes = {
  ...filterItemPropTypes,
};

const RadioGroup = Radio.Group;

function FilterRadio({ options, value, onChange }) {
  function handleOnChange(e) {
    const newValue = e.target.value;
    const selectedOption = options.find(option => option.value === newValue);
    onChange(selectedOption.select ? [newValue, null] : newValue);
  }
  const [radioValue, selectValue] = isArray(value) ? value : [value, null];
  return (
    <RadioGroup onChange={handleOnChange} value={radioValue}>
      {options.map(({ label, value: optionValue, select }) => (
        <React.Fragment key={optionValue}>
          <Radio className={styles.Radio} value={optionValue}>
            {label}
          </Radio>
          {select && radioValue === optionValue && (
            <FilterSelect
              placeholder={select.placeholder}
              options={select.options}
              value={selectValue}
              onChange={newValue => {
                onChange([radioValue, newValue]);
              }}
            />
          )}
        </React.Fragment>
      ))}
    </RadioGroup>
  );
}

FilterRadio.propTypes = {
  ...filterItemPropTypes,
};

const CheckboxGroup = Checkbox.Group;

function FilterCheckbox({ options, value, onChange, ...restProps }) {
  return (
    <CheckboxGroup
      onChange={checkedValues => onChange(checkedValues)}
      value={value}
      {...restProps}
    >
      {options.map(({ label, value: optionValue }) => (
        <Checkbox className={styles.Checkbox} value={optionValue} key={optionValue}>
          {label}
        </Checkbox>
      ))}
    </CheckboxGroup>
  );
}

FilterCheckbox.propTypes = {
  ...filterItemPropTypes,
};

const typeToComponent = {
  checkbox: FilterCheckbox,
  radio: FilterRadio,
  select: FilterSelect,
};

export default function FilterItem({
  name,
  header,
  options,
  type,
  value,
  onChange,
  ...restProps
}) {
  const SelectComponent = typeToComponent[type] || FilterSelect;
  return (
    <div className={styles.Container}>
      <Heading size="smaller" color="grey" className={styles.FilterHeader}>
        {header}
      </Heading>
      <SelectComponent
        value={value}
        options={options}
        onChange={newValue => onChange(name, newValue)}
        {...restProps}
      />
    </div>
  );
}

FilterItem.propTypes = {
  name: PropTypes.string.isRequired,
  header: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['radio', 'select', 'checkbox']),
  ...filterItemPropTypes,
};

FilterItem.defaultProps = {
  type: 'select',
};
