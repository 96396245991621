import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Heading.scss';

const colorClassnameMap = {
  blue: styles.Blue,
  grey: styles.Grey,
  'dark-grey': styles.DarkGrey,
  'light-blue': styles.LightBlue,
  'dark-blue': styles.DarkBlue,
  red: styles.Red,
  green: styles.Green,
  orange: styles.Orange,
};

const Tags = {
  larger: 'h1',
  large: 'h2',
  medium: 'h3',
  small: 'h4',
  smaller: 'h5',
};

export default class Heading extends Component {
  static propTypes = {
    children: PropTypes.node,
    color: PropTypes.oneOf(Object.keys(colorClassnameMap)),
    size: PropTypes.oneOf(Object.keys(Tags)),
    className: PropTypes.string,
  };

  static defaultProps = {
    color: 'blue',
    size: 'large',
    children: null,
    className: null,
  };

  render() {
    const { className, children, size, color } = this.props;
    const HeadingComponent = Tags[size];
    return (
      <HeadingComponent
        className={classnames(styles.Heading, colorClassnameMap[color], className)}
      >
        {children}
      </HeadingComponent>
    );
  }
}
