import React from 'react';
import Icon from '../Icon';
import {
  meetsLengthRequirement,
  containsTwoDigits,
  containsTwoLetters,
  containsSpecialCharacter,
} from '../../shared/utils/validation';
import styles from './PasswordForm.scss';

export const validatePasswordRequirements = passwordInput => {
  const lengthRequirement = meetsLengthRequirement(passwordInput || '');
  const digitRequirement = containsTwoDigits(passwordInput || '');
  const letterRequirement = containsTwoLetters(passwordInput || '');
  const specialCharacterRequirement = containsSpecialCharacter(passwordInput || '');

  const requirementsMet =
    lengthRequirement &&
    digitRequirement &&
    letterRequirement &&
    specialCharacterRequirement;

  return (
    <div>
      <div className={styles.PasswordRequirements}>
        Password requirements
        {!requirementsMet && (
          <Icon className={styles.Warning} size={18} name="warning_fill" />
        )}
      </div>
      <div className={styles.Requirement}>
        {lengthRequirement ? (
          <Icon className={styles.Checkmark} size={22} name="tick_fill" />
        ) : (
          <div className={styles.EmptyCheckmark} />
        )}
        Be a minimum of 10 characters
        <br />
      </div>
      <div className={styles.Requirement}>
        {letterRequirement ? (
          <Icon className={styles.Checkmark} size={22} name="tick_fill" />
        ) : (
          <div className={styles.EmptyCheckmark} />
        )}
        Include at least 2 letters
        <br />
      </div>
      <div className={styles.Requirement}>
        {digitRequirement ? (
          <Icon className={styles.Checkmark} size={22} name="tick_fill" />
        ) : (
          <div className={styles.EmptyCheckmark} />
        )}
        Include at least 2 numbers
        <br />
      </div>
      <div className={styles.Requirement}>
        {specialCharacterRequirement ? (
          <Icon className={styles.Checkmark} size={22} name="tick_fill" />
        ) : (
          <div className={styles.EmptyCheckmark} />
        )}
        Include at least 1 special character
        <br />
      </div>
    </div>
  );
};
