import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Dropdown from '../Dropdown/index';
import Text from '../Text/index';
import Field, { fieldDisplayPropTypes, fieldDisplayDefaultProps } from './Field';
import Badge from '../Badge/index';
import Icon from '../Icon/index';
import styles from './SelectField.scss';

export const SelectFieldSelectedOption = ({ label, showCurrentTag }) => (
  <div className={styles.Selected} role="option" aria-selected={true}>
    {label}
    {showCurrentTag && (
      <Badge color="light-blue" fill={true} className={styles.CurrentBadge}>
        CURRENT
      </Badge>
    )}
  </div>
);

SelectFieldSelectedOption.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  showCurrentTag: PropTypes.bool.isRequired,
};

export const SelectFieldOption = ({ input, meta, option, showCurrentTag }) => (
  <div
    className={styles.Option}
    role="option"
    tabIndex="0"
    onClick={() => {
      if (input.onBlur) input.onBlur(option.value);
      if (input.onChange) input.onChange(option.value);
    }}
    onKeyDown={e => {
      switch (e.key) {
        case 'Enter':
        case ' ':
          return e.target.click();

        default:
          return null;
      }
    }}
    aria-selected={option.value === input.value}
  >
    <div className={styles.Label}>
      {option.label}
      {option.description && (
        <div>
          <Text size="medium" color="dark-grey">
            {option.description}
          </Text>
        </div>
      )}
    </div>
    {showCurrentTag && meta.initial === option.value && (
      <Badge color="light-blue" fill={true} className={styles.CurrentBadge}>
        CURRENT
      </Badge>
    )}
  </div>
);

SelectFieldOption.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.any,
  }).isRequired,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    initial: PropTypes.any,
    error: PropTypes.string,
    touched: PropTypes.bool,
    pristine: PropTypes.bool,
  }).isRequired,
  showCurrentTag: PropTypes.bool.isRequired,
};

export const SelectField = props => {
  const {
    input,
    meta,
    id,
    options,
    optionGroups,
    disabled,
    overlayClassName,
    placeholder,
    customTriggerStyle,
    customTriggerClassName,
    customSelectedTriggerClassName,
    showCurrentTag,
    ...restProps
  } = props;

  // Always use groups even if ther are no groups passed in
  const _optionGroups = optionGroups || [{ label: '', options }];

  return (
    <Field {...props} borderless fieldType="SelectField">
      <Dropdown
        id={id}
        disabled={disabled}
        renderTrigger={active => {
          const selected = _optionGroups
            .reduce((allOptions, group) => [...allOptions, ...group.options], [])
            .find(option => option.value === input.value);
          const triggerLabel = selected ? (
            <SelectFieldSelectedOption
              label={selected.selectedLabel || selected.label}
              showCurrentTag={showCurrentTag && meta.initial === selected.value}
            />
          ) : (
            placeholder
          );

          return (
            <div
              className={classnames(
                {
                  [styles.Trigger]: !active,
                  [styles['Trigger--active']]: active,
                  [styles.Disabled]: disabled,
                  [styles.Error]:
                    customTriggerStyle === null &&
                    Boolean(meta && meta.touched && meta.error),
                  [styles.Warning]:
                    customTriggerStyle === null &&
                    Boolean(meta && meta.touched && meta.warning),
                  [customTriggerStyle]: customTriggerStyle !== null,
                  [customSelectedTriggerClassName]: Boolean(selected),
                },
                customTriggerClassName,
              )}
              role="button"
              tabIndex="0"
              onKeyDown={e => {
                switch (e.key) {
                  // Close out select on escape
                  case 'Escape':
                    if (active) {
                      return e.target.click();
                    }
                    break;
                  case 'Enter':
                  case ' ':
                    return e.target.click();
                  default:
                    return null;
                }
              }}
            >
              {triggerLabel}
              <div className={styles.TriggerIcon}>
                <Icon name="dropdown" />
              </div>
            </div>
          );
        }}
        overlayClassName={overlayClassName}
        overlayStyle={{ width: '100%' }}
        {...Field.filterProps(restProps)}
      >
        <div className={styles.Content} tabIndex="-1">
          {_optionGroups.map((group, index) => (
            <div
              key={group.label || index}
              className={classnames(styles.OptionsGroup, {
                [styles.IsSingle]: _optionGroups.length === 1,
                [styles.HasLabel]: !!group.label,
              })}
            >
              {group.label && (
                <Text
                  color="dark-grey"
                  size="medium"
                  weight="medium"
                  className={styles.OptionsGroupLabel}
                >
                  {group.label}
                </Text>
              )}
              {group.options.map((option, index) => (
                <SelectFieldOption
                  option={option}
                  input={input}
                  meta={meta}
                  showCurrentTag={showCurrentTag}
                  key={option.value || index}
                />
              ))}
            </div>
          ))}
        </div>
      </Dropdown>
    </Field>
  );
};

SelectField.propTypes = {
  ...fieldDisplayPropTypes,
  id: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  meta: PropTypes.shape({
    initial: PropTypes.any,
    error: PropTypes.string,
    touched: PropTypes.bool,
    pristine: PropTypes.bool,
  }),
  overlayClassName: PropTypes.string,
  customTriggerStyle: PropTypes.string,
  customTriggerClassName: PropTypes.string,
  customSelectedTriggerClassName: PropTypes.string,
  options: PropTypes.array,
  optionGroups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          description: PropTypes.string,
        }).isRequired,
      ).isRequired,
    }),
  ),
  disabled: PropTypes.bool,
  placeholder: PropTypes.node,
  showCurrentTag: PropTypes.bool,
};

SelectField.defaultProps = {
  ...fieldDisplayDefaultProps,
  id: null,
  meta: {
    error: null,
    touched: false,
    pristine: true,
  },
  input: {
    value: '',
    onBlur: () => false,
    onChange: () => false,
  },
  overlayClassName: null,
  customTriggerStyle: null,
  customTriggerClassName: null,
  customSelectedTriggerClassName: null,
  options: [],
  optionGroups: null,
  disabled: false,
  showCurrentTag: false,
  placeholder: '',
};

export default SelectField;
