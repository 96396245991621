/*global Weglot*/

const { useEffect } = require('react');

const { REACT_APP_WEGLOT } = process.env;

const useTranslations = ({ enabled }) => {
  useEffect(() => {
    // Return if disabled or weglot has already been injected into the page
    if (!enabled || document.getElementById('weglot')) return;

    const script = document.createElement('script');
    script.src = 'https://cdn.weglot.com/weglot.min.js';
    script.id = 'weglot';

    document.head.appendChild(script);

    script.onload = () => {
      Weglot.initialize({
        api_key: REACT_APP_WEGLOT,
        dynamic: 'html',
        cache: true,
        // Don't translate these elements
        excluded_blocks: [
          {
            value: '[contenteditable="true"]',
          },
        ],
      });
    };
  }, [enabled]);
};

export default useTranslations;
