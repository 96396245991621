import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon/index';
import Text from '../Text/index';
import styles from './Option.scss';

const Option = props => {
  const {
    className,
    label,
    description,
    isSelected,
    onClick,
    selectedClassName,
    showTick,
  } = props;
  return (
    <div
      onClick={onClick}
      className={classnames(
        styles.Option,
        {
          [styles['Option--selected']]: isSelected,
          [selectedClassName]: isSelected,
          [styles['Option--noTick']]: !showTick,
        },
        className,
      )}
      role="button"
      tabIndex="0"
    >
      <Text className={styles.Option__Label}>{label}</Text>
      {description && (
        <Text className={styles.Option__Description} size="sm" color="light-grey">
          {description}
        </Text>
      )}
      {showTick ? (
        <div className={styles.OptionTick}>
          {isSelected ? <Icon name="option_tick_fill" /> : <Icon name="option_tick" />}
        </div>
      ) : (
        <div className={styles.NoTick} />
      )}
    </div>
  );
};

Option.propTypes = {
  className: PropTypes.string,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  description: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  selectedClassName: PropTypes.string,
  showTick: PropTypes.bool.isRequired,
};

Option.defaultProps = {
  className: null,
  label: null,
  description: null,
  isSelected: false,
  selectedClassName: null,
};

export default Option;
