import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card as AntdCard } from 'antd';
import styles from './Card.scss';

// Antd's Card component will use any property you pass in that
// overrides style (or other) properties. The way around this is
// to build an object + spread on the Antd component if a value
// exists.
function getSafeNullProps({ headStyle, bodyStyle }) {
  const props = {};
  if (headStyle) {
    props.headStyle = headStyle;
  }
  if (bodyStyle) {
    props.bodyStyle = bodyStyle;
  }
  return props;
}

export default function Card({
  title,
  children,
  extra,
  className,
  headStyle,
  bodyStyle,
  ...restProps
}) {
  return (
    <AntdCard
      title={title}
      extra={extra}
      className={classNames(styles.Card, className)}
      {...getSafeNullProps({ headStyle, bodyStyle })}
      {...restProps}
    >
      {children}
    </AntdCard>
  );
}

Card.propTypes = {
  /** The title of the card */
  title: PropTypes.any,

  /** The extra content to be displayed in the header */
  extra: PropTypes.object,

  /** The contents of the card */
  children: PropTypes.node.isRequired,

  /** The style to be applied to the header of the card */
  headStyle: PropTypes.object,

  /** The style to be applied to the interior of the card */
  bodyStyle: PropTypes.object,

  /** A css class name to apply to the card container */
  className: PropTypes.string,
};

Card.defaultProps = {
  title: null,
  extra: null,
  className: null,
  headStyle: null,
  bodyStyle: null,
};
