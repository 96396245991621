import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';
import classNames from 'classnames';
import Spinner from '../../Spinner/index';
import styles from './EditablePDFView.scss';
import PDFJSRender from '../shared/PDFJSRender';
import ErrorContent from '../shared/ErrorContent';
import Autoscale from '../shared/Autoscale';

const fieldTarget = {
  hover(props, monitor, component) {
    if (
      !component ||
      !monitor.isOver() ||
      !component.props.resetSelection ||
      component.props.selectedItemId === null
    ) {
      return;
    }
    component.props.resetSelection();
  },
  drop(props, monitor, component) {
    const item = monitor.getItem();
    const { scale } = component.state;
    if (item.id) {
      const delta = monitor.getDifferenceFromInitialOffset();
      props.updateItem(item.id, {
        left: Math.round(delta.x + item.left),
        top: Math.round(delta.y + item.top),
      });
    } else {
      const offset = monitor.getClientOffset();
      const rect = component.containerRef.getBoundingClientRect();
      props.addItem({
        type: item.field_type,
        top: offset.y - rect.top,
        left: offset.x - rect.left,
        height: item.height / scale,
        width: item.width / scale,
        scale,
      });
    }
  },
};

class EditablePDFContainer extends Component {
  static propTypes = {
    file: PropTypes.string.isRequired,
    schema: PropTypes.array.isRequired,
    renderItem: PropTypes.func.isRequired,
    selectedItemId: PropTypes.any,
    children: PropTypes.node,
    connectDropTarget: PropTypes.func.isRequired,
    isFieldSelected: PropTypes.func,
    resetSelection: PropTypes.func,
    onLoad: PropTypes.func,

    // Used by `fieldTarget`
    addItem: PropTypes.func.isRequired, // eslint-disable-line
    updateItem: PropTypes.func, // eslint-disable-line
  };

  static defaultProps = {
    selectedItemId: null,
    children: null,
    resetSelection: null,
    onLoad: null,
    isFieldSelected: () => false,
    updateItem: () => {},
  };

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.state = {
      scale: 1,
      loaded: false,
      error: null,
    };
  }

  onDocumentLoad = ({ scale }) => {
    this.setState({ scale, loaded: true, error: null }, () => {
      if (this.props.onLoad) {
        this.props.onLoad({ scale });
      }
    });
  };

  onDocumentError = error => {
    this.setState({ error });
  };

  render() {
    const {
      schema,
      renderItem,
      children,
      connectDropTarget,
      file,
      selectedItemId,
      resetSelection,
      isFieldSelected,
    } = this.props;
    const { scale, loaded, error } = this.state;

    return connectDropTarget(
      <div
        className={styles.Container}
        ref={ref => {
          this.containerRef = ref;
        }}
      >
        <div
          className={classNames(styles.PDFOverlay, {
            [styles.SelectedField]: !!selectedItemId,
            [styles.Loaded]: loaded,
            [styles.Hide]: !!error,
          })}
          onClick={resetSelection}
          role="button"
          tabIndex="0"
        >
          <Autoscale>
            {width => (
              <PDFJSRender
                file={file}
                width={width}
                onLoad={this.onDocumentLoad}
                onError={this.onDocumentError}
              />
            )}
          </Autoscale>
        </div>
        {error && <ErrorContent error={error} />}
        {!error &&
          (loaded ? (
            <Fragment>
              {schema.map(field =>
                renderItem(field, {
                  selected: isFieldSelected(field, selectedItemId),
                  scale,
                }),
              )}
              {children}
            </Fragment>
          ) : (
            <div className={styles.Spinner}>
              <Spinner inverse />
            </div>
          ))}
      </div>,
    );
  }
}

export default DropTarget('Field', fieldTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))(EditablePDFContainer);
