import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import classnames from 'classnames';
import Field, { fieldDisplayPropTypes, fieldDisplayDefaultProps } from './Field';
import styles from './TextAreaField.scss';

export const TextareaField = props => {
  const {
    input,
    id,
    readOnly,
    placeholder,
    disabled,
    charactersRemaining,
    autosize,
  } = props;

  return (
    <Field {...props}>
      <Input.TextArea
        autoSize={autosize}
        placeholder={placeholder}
        {...input}
        className={classnames(styles.Textarea, input.className)}
        id={id}
        readOnly={readOnly}
        disabled={disabled}
      />
      {!!charactersRemaining || charactersRemaining === 0 ? (
        <div className={styles.CharactersRemaining}>
          {charactersRemaining} characters remaining
        </div>
      ) : null}
    </Field>
  );
};

TextareaField.propTypes = {
  ...fieldDisplayPropTypes,
  id: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
  charactersRemaining: PropTypes.number,
  autosize: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.node,
  showRequired: PropTypes.bool,
};

TextareaField.defaultProps = {
  ...fieldDisplayDefaultProps,
  id: null,
  type: null,
  charactersRemaining: null,
  autosize: false,
  readOnly: false,
  disabled: false,
  placeholder: '',
  showRequired: false,
};

export default TextareaField;
