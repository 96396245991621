import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Text from '../Text';
import styles from './EmptyTableResults.scss';

const EmptyTableResults = ({ children, className, imageSrc, text, textColor }) => {
  return (
    <div className={classnames(styles.Container, className)}>
      {imageSrc && <img className={styles.Image} src={imageSrc} alt="No results found" />}
      <Text size="large" color={textColor} weight="medium">
        {text}
      </Text>
      {children}
    </div>
  );
};

EmptyTableResults.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  imageSrc: PropTypes.string,
  text: PropTypes.string.isRequired,
  textColor: PropTypes.string,
};

EmptyTableResults.defaultProps = {
  children: null,
  className: null,
  imageSrc: null,
  textColor: 'grey',
};

export default EmptyTableResults;
