import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon/index';
import Tooltip from '../Tooltip/index';

export default class ItemAction extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onClick: PropTypes.any.isRequired,
    icon: PropTypes.string.isRequired,
    label: PropTypes.node.isRequired,
    className: PropTypes.string.isRequired,
    style: PropTypes.object,
    tooltip: PropTypes.string,
  };

  static defaultProps = {
    style: null,
    tooltip: null,
  };

  onClick = e => {
    const { item, onClick } = this.props;
    if (onClick) {
      onClick(item, e);
    }
  };

  render() {
    const { icon, label, className, style, tooltip } = this.props;
    return (
      <Tooltip content={tooltip} placement="left">
        <span
          className={className}
          onClick={this.onClick}
          role="button"
          tabIndex="0"
          style={style}
        >
          <Icon name={icon} />
          {label}
        </span>
      </Tooltip>
    );
  }
}
