import PropTypes from 'prop-types';
import React, { Component } from 'react';

const SIZE_TITLES = {
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
  full: 'Full Size',
};

export default class AttachmentBlock extends Component {
  static propTypes = {
    block: PropTypes.object.isRequired,
    contentState: PropTypes.object.isRequired,
  };

  state = { imageSize: { width: 0, height: 0 } };

  onImageLoad = ({ target }) => {
    this.setState({
      imageSize: { width: target.naturalWidth, height: target.naturalHeight },
    });
  };

  render() {
    const { block, contentState } = this.props;
    const { imageSize } = this.state;
    const entity = contentState.getEntity(block.getEntityAt(0));
    const { src, href, size = 'small' } = entity.getData();
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        data-attachment
        style={{ display: 'inline-block' }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#2A2E36',
            color: 'white',
            fontSize: '12px',
            lineHeight: '14px',
            fontFamily: 'Roboto',
            padding: '3px',
          }}
        >
          {SIZE_TITLES[size]} ({imageSize.width} x {imageSize.height})
        </div>
        <img
          alt="thumb"
          src={src}
          style={{ minWidth: 100, maxWidth: '100%', objectFit: 'cover' }}
          onLoad={this.onImageLoad}
        />
      </a>
    );
  }
}
