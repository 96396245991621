import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { AtomicBlockUtils } from 'draft-js';
import Icon from '../Icon/index';
import Popup from '../Popup/index';
import Text from '../Text/index';
import FileUpload from '../FileUpload/index';
import styles from './RichTextField.scss';

export default class InlineAttachmentAction extends Component {
  static propTypes = {
    editorState: PropTypes.object,
    onChange: PropTypes.func,
    onCreateAttachment: PropTypes.func.isRequired,
  };

  static defaultProps = {
    editorState: {},
    onChange: () => {},
  };

  state = {
    visible: false,
    isUploading: false,
    error: null,
  };

  show = () => {
    this.setState({ visible: true });
  };

  dismiss = () => {
    this.setState({ visible: false });
  };

  addAttachment = async (value, size, altText) => {
    const { editorState, onChange, onCreateAttachment } = this.props;
    this.setState({ isUploading: true });
    try {
      const { src, href, alt } = await onCreateAttachment(value, true, size, altText);

      const entityKey = editorState
        .getCurrentContent()
        .createEntity('ATTACHMENT', 'MUTABLE', { src, href, size, alt })
        .getLastCreatedEntityKey();
      this.setState({ visible: false, isUploading: false });
      onChange(AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' '));
    } catch (e) {
      this.setState({
        isUploading: false,
        error:
          e.status === 403
            ? 'Sorry, you are not authorized to perform this action'
            : (e.errors && e.errors[0]) || 'Unknown error please try again.',
      });
    }
  };

  render() {
    return (
      <div className={styles.ButtonGroup}>
        <div
          role="button"
          tabIndex="0"
          onClick={this.show}
          className={styles.ButtonGroupItem}
        >
          <Icon name="image" />
        </div>
        {this.state.visible && (
          <Popup active={this.state.visible} onRequestClose={this.dismiss}>
            <Text size="larger" color="blue" className={styles.InsertImageText}>
              Insert an inline image or PDF
            </Text>
            <FileUpload
              allowResize
              allowAltText
              submitButtonText="Insert image"
              isUploading={this.state.isUploading}
              onFileSelected={this.addAttachment}
            />
            {this.state.error && <Text color="red">{this.state.error}</Text>}
          </Popup>
        )}
      </div>
    );
  }
}
