import { AUTHORIZATION_KEY } from '../../constants';

import * as Auth from './Auth';
import * as Layers from './Layers';
import * as Camino from './Camino';

export const ENDPOINT =
  process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8000/api/v1';
export const HEADERS = { Accept: 'application/json', 'Content-Type': 'application/json' };

export const makeHeaders = noAuth => {
  if (noAuth) return HEADERS;

  try {
    return {
      ...HEADERS,
      Authorization: `Bearer ${JSON.parse(
        window.localStorage.getItem(AUTHORIZATION_KEY),
      )}`,
    };
  } catch (error) {
    console.error(`Error trying to parse token`);
    return {
      ...HEADERS,
      Authorization: null,
    };
  }
};

export const AuthService = Auth;
export const LayersService = Layers;
export const CaminoService = Camino;

export const request = (...args) => {
  return window.fetch(...args);
};

export const service = async (method, uri, body) => {
  const response = await request(`${ENDPOINT}${uri}`, {
    method,
    mode: 'cors',
    headers: makeHeaders(),
    ...(body && { body: JSON.stringify(body) }),
  });

  if (!response.ok) {
    const error = new Error(response.statusText);
    error.status = response.status;
    if (response.body) {
      error.body = await response.json();
    }
    throw error;
  }

  if (method === 'delete') {
    return response;
  } else {
    return await response.json();
  }
};
