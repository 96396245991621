import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export function findEntityRanges(contentState) {
  return character => {
    const entityKey = character.getEntity();
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK';
  };
}

export function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(findEntityRanges(contentState), callback);
}

class Link extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    entityKey: PropTypes.string,
    contentState: PropTypes.object,
  };

  static defaultProps = {
    children: null,
  };

  render() {
    const { children, entityKey, contentState } = this.props;
    const { url } = contentState.getEntity(entityKey).getData();
    return (
      <span>
        <a
          href={url}
          target="_blank"
          data-inline-editor-key={entityKey}
          rel="noopener noreferrer"
        >
          {children}
        </a>
      </span>
    );
  }
}

Link.propTypes = {
  contentState: PropTypes.object.isRequired,
  entityKey: PropTypes.any.isRequired,
  children: PropTypes.node,
};

Link.defaultProps = {
  children: null,
};

export default function createLinkDecorator() {
  return {
    component: Link,
    strategy: findLinkEntities,
  };
}
