import { useEffect, useState } from 'react';

// A hook that creates a preview URL for a file (most likely an image)
// and automatically disposes of it when the file changes or component is unmounted.
const useFilePreview = file => {
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (file instanceof File) {
      setPreview(URL.createObjectURL(file));
    }

    return () => {
      if (preview) {
        URL.revokeObjectURL(preview);
      }
    };
  }, [file]);

  return preview;
};

export default useFilePreview;
