import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment-timezone';
import Text from '../Text/index';
import styles from './DateDisplay.scss';

const defaultDateString = 'LL';
const defaultTimeString = 'LL, h:mm:ss A';

const DateDisplay = ({ format, prefix, showTime, value, suffix, tag, ...rest }) => {
  const formatting = showTime ? defaultTimeString : defaultDateString;
  return (
    <Text
      className={classnames(styles.Container, { [styles.Tag]: tag })}
      as="span"
      size="md"
      {...rest}
    >
      {`${prefix} ${moment(value).format(format || formatting)} ${suffix}`}
    </Text>
  );
};

DateDisplay.defaultProps = {
  children: null,
  format: '',
  prefix: '',
  showTime: false,
  suffix: '',
  value: new Date(),
  tag: false,
};

DateDisplay.propTypes = {
  children: PropTypes.node,
  format: PropTypes.string,
  prefix: PropTypes.string,
  showTime: PropTypes.bool,
  suffix: PropTypes.string,
  value: PropTypes.any,
  tag: PropTypes.bool,
};

export default DateDisplay;
