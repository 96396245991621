import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ReactInputMask from 'react-input-mask';
import { Input } from 'antd';
import DatePicker from '../DatePicker';
import Icon from '../Icon';
import RangePicker from '../RangePicker';
import TreeSelect from '../TreeSelect';
import styles from './Filters.scss';

const currencyFormatter = {
  format: value => {
    return `$${parseFloat(String(value).replace(/,/g, '')).toLocaleString('en-US', {
      style: 'decimal',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    })}`;
  },
};

export const DateRangeFilter = ({ className, dropdownClassName, showRange, ...props }) =>
  showRange ? (
    <RangePicker
      className={classnames(styles.DateRangeFilter, className)}
      dropdownClassName={classnames(styles.DateRangeFilter__Dropdown, dropdownClassName)}
      placeholder={['Start date', 'End date']}
      {...props}
      suffixIcon={<Icon className={styles.CalendarIcon} name="calendar" size={20} />}
      format="MM/DD/YYYY"
    />
  ) : (
    <DatePicker
      className={classnames(styles.DateFilter, className)}
      dropdownClassName={classnames(styles.DateFilter__Dropdown, dropdownClassName)}
      placeholder="Select date"
      {...props}
      suffixIcon={<Icon className={styles.CalendarIcon} name="calendar" size={20} />}
      format="MM/DD/YYYY"
    />
  );

DateRangeFilter.propTypes = {
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  showRange: PropTypes.bool,
};

DateRangeFilter.defaultProps = {
  className: null,
  dropdownClassName: null,
  showRange: true,
};

export const MultiSelectFilter = ({
  className,
  data,
  dropdownClassName,
  hasChildItems,
  isLoading,
  placeholder,
  testId,
  ...props
}) => (
  <TreeSelect
    treeData={data}
    className={classnames(styles.SelectFilter, styles.MultiSelectFilter, className)}
    dropdownClassName={classnames(
      styles.SelectFilter__Dropdown,
      {
        [styles['SelectFilter__Dropdown--noChildItems']]: !hasChildItems,
      },
      dropdownClassName,
    )}
    placeholder={
      <div className={styles.Placeholder} data-testid={testId}>
        {isLoading ? 'Loading...' : placeholder}
        <Icon className={styles.Placeholder__Icon} name="dropdown" />
      </div>
    }
    treeNodeFilterProp="title"
    treeCheckable
    {...props}
    multiple
  />
);

MultiSelectFilter.propTypes = {
  testId: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  dropdownClassName: PropTypes.string,
  hasChildItems: PropTypes.bool,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
};

MultiSelectFilter.defaultProps = {
  testId: null,
  className: null,
  dropdownClassName: null,
  hasChildItems: true,
  isLoading: false,
  placeholder: null,
};

export const SingleSelectFilter = ({
  className,
  data,
  dropdownClassName,
  placeholder,
  ...props
}) => (
  <TreeSelect
    treeData={data}
    placeholder={
      <div className={styles.Placeholder}>
        {placeholder}
        <Icon className={styles.Placeholder__Icon} name="dropdown" />
      </div>
    }
    suffixIcon={<div />}
    treeNodeFilterProp="title"
    className={classnames(styles.SelectFilter, styles.SingleSelectFilter, className)}
    dropdownClassName={classnames(
      styles.SelectFilter__Dropdown,
      styles['SelectFilter__Dropdown--noChildItems'],
      dropdownClassName,
    )}
    showSearch={false}
    multiple={false}
    {...props}
  />
);

SingleSelectFilter.propTypes = {
  className: PropTypes.string,
  dropdownClassName: PropTypes.string,
  data: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
};

SingleSelectFilter.defaultProps = {
  className: null,
  dropdownClassName: null,
  placeholder: null,
};

export const SingleLineInputFilter = ({ className, multi, ...props }) => {
  const Component = multi ? Input.TextArea : Input;
  return (
    <Component
      allowClear={true}
      {...props}
      className={
        multi
          ? classnames(styles.MultiInputFilter, className)
          : classnames(styles.InputFilter, className)
      }
    />
  );
};

SingleLineInputFilter.propTypes = {
  className: PropTypes.string,
  multi: PropTypes.bool,
};

SingleLineInputFilter.defaultProps = {
  className: null,
  multi: false,
};

const toNumeric = v => {
  const number = parseFloat(v);
  return Number.isNaN(number) ? '' : number;
};

const cleanBetweenNumerics = numerics =>
  Array.isArray(numerics)
    ? numerics.filter(n => typeof n !== 'undefined' && n !== '' && !Number.isNaN(n))
    : [];

export const NumericInputFilter = ({ className, ...props }) => (
  <Input
    allowClear={true}
    {...props}
    className={classnames(styles.InputFilter, className)}
    type="number"
  />
);

NumericInputFilter.propTypes = {
  className: PropTypes.string,
};

NumericInputFilter.defaultProps = {
  className: null,
};

export const NumericInputBetweenFilter = ({ className, onChange, value, ...props }) => {
  const [data, setData] = useState(value || []);

  // Emit change back to listener
  useEffect(() => {
    // Only emit when both values are filled
    const cleanData = cleanBetweenNumerics(data);
    if (Array.isArray(cleanData) && cleanData.length === 2) {
      onChange(cleanData);
    }
  }, [data]);

  return (
    <div className={styles.InputGroup}>
      <Input
        allowClear={true}
        {...props}
        className={classnames(
          styles.InputFilter,
          styles[`InputFilter--Split`],
          className,
        )}
        placeholder="Minimum"
        onChange={e => {
          setData([toNumeric(e.target.value), data[1]]);
        }}
        value={data[0]}
        type="number"
      />
      <span className={styles.Split}>---</span>
      <Input
        allowClear={true}
        {...props}
        className={classnames(
          styles.InputFilter,
          styles[`InputFilter--Split`],
          className,
        )}
        placeholder="Maximum"
        onChange={e => {
          setData([data[0], toNumeric(e.target.value)]);
        }}
        value={data[1]}
        type="number"
      />
    </div>
  );
};

NumericInputBetweenFilter.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
};

NumericInputBetweenFilter.defaultProps = {
  className: null,
  value: [],
};

export const PhoneNumberInputFilter = ({ className, ...props }) => (
  <ReactInputMask
    {...props}
    alwaysShowMark={true}
    mask="(999) 999-9999"
    className={classnames(styles.InputFilter, className)}
  >
    {inputProps => <Input allowClear={true} {...inputProps} />}
  </ReactInputMask>
);

PhoneNumberInputFilter.propTypes = {
  className: PropTypes.string,
};

PhoneNumberInputFilter.defaultProps = {
  className: null,
};

const currencyValue = centsString => {
  const parsedCentValue = centsString ? centsString.replace(/\D/g, '') : '';
  const parsedValue = parseInt(parsedCentValue, 10);
  return Number.isNaN(parsedValue) ? '' : parsedValue;
};

// Need to allow entering 0 still
const isCurrencyValue = v => {
  return v !== '' && typeof v !== 'undefined' && v !== null && !Array.isArray(v);
};

export const CurrencyInputFilter = ({ className, onChange, value, ...props }) => {
  return (
    <Input
      allowClear={true}
      {...props}
      className={classnames(styles.InputFilter, className)}
      onChange={e => {
        onChange(currencyValue(e.target.value));
      }}
      value={isCurrencyValue(value) ? currencyFormatter.format(value / 100) : ''}
    />
  );
};

CurrencyInputFilter.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

CurrencyInputFilter.defaultProps = {
  className: null,
  value: null,
};

export const CurrencyInputBetweenFilter = ({ className, onChange, value, ...props }) => {
  const [data, setData] = useState(value || []);

  // Emit change back to listener
  useEffect(() => {
    // Only emit when both values are filled
    const cleanData = cleanBetweenNumerics(data);
    if (Array.isArray(cleanData) && cleanData.length === 2) {
      onChange(cleanData);
    }
  }, [data]);

  return (
    <div className={styles.InputGroup}>
      <Input
        allowClear={true}
        {...props}
        className={classnames(
          styles.InputFilter,
          styles[`InputFilter--Split`],
          className,
        )}
        placeholder="Minimum"
        onChange={e => {
          setData([currencyValue(e.target.value), data[1]]);
        }}
        value={
          isCurrencyValue(data[0]) ? `${currencyFormatter.format(data[0] / 100)}` : ''
        }
      />
      <span className={styles.Split}>---</span>
      <Input
        allowClear={true}
        {...props}
        className={classnames(
          styles.InputFilter,
          styles[`InputFilter--Split`],
          className,
        )}
        placeholder="Maximum"
        onChange={e => {
          setData([data[0], currencyValue(e.target.value)]);
        }}
        value={
          isCurrencyValue(data[1]) ? `${currencyFormatter.format(data[1] / 100)}` : ''
        }
      />
    </div>
  );
};

CurrencyInputBetweenFilter.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.array,
};

CurrencyInputBetweenFilter.defaultProps = {
  className: null,
  value: [],
};
