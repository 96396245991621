import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Resizable } from 'react-resizable';
import { AutoSizer } from 'react-virtualized';
import memoize from 'memoize-one';
import classnames from 'classnames';
import Paginator from '../Paginator/index';
import StaticTable from './StaticTable';
import { Sorter, Column } from './types';
import styles from './Table.scss';

const HEADER_HEIGHT = 35;
const PAGINATION_COMPONENT_HEIGHT = 48;

const columnWidthValidation = allProps => columnProps => {
  if (
    allProps.enableResizableColumnsAndHorizScroll === true &&
    typeof columnProps.width !== 'number'
  ) {
    return new Error(`Please provide absolute widths if resizable columns/horizontal
    scrolling are enabled. Relative widths (i.e. percentages) not compatible with this features.
    (You enabled it by passing "true" into the "enableResizableColumnsAndHorizScroll" prop.)`);
  }
  return null;
};

export const columnValidation = (props, ...rest) => {
  return PropTypes.arrayOf(
    PropTypes.shape({
      ...Column,
      width: columnWidthValidation(props),
    }),
  )(props, ...rest);
};

const ResizeableTitle = props => {
  const { noResize, onResize, width, minWidth, ...restProps } = props;

  if (noResize) {
    return <th {...restProps} />;
  }

  if (!width) {
    return <th {...restProps} />;
  }

  const handleClick = e => {
    e.stopPropagation(); // Stops propagation of drag to resize mousedown event
  };

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
      className={styles.ResizableBox}
      handle={
        <div className={styles.Handle} onClick={handleClick} tabIndex={0} role="button" />
      }
      minConstraints={[minWidth, Infinity]}
      maxConstraints={[Infinity, Infinity]}
    >
      <th {...restProps} />
    </Resizable>
  );
};

ResizeableTitle.propTypes = {
  noResize: PropTypes.bool,
  onResize: PropTypes.func.isRequired,
  minWidth: PropTypes.number,
  width: PropTypes.number.isRequired,
};

ResizeableTitle.defaultProps = {
  noResize: false,
  minWidth: 0,
};

export default class Table extends Component {
  static propTypes = {
    id: PropTypes.string,
    dataSource: PropTypes.array,
    enableResizableColumnsAndHorizScroll: PropTypes.bool,
    columns: columnValidation,
    sorter: Sorter,
    enablePagination: PropTypes.bool,
    paginationMeta: PropTypes.object,
    onChangePage: PropTypes.func,
    loading: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        spinning: PropTypes.bool,
        indicator: PropTypes.node,
      }),
    ]),
    locale: PropTypes.object,
    rowKey: PropTypes.func,
    onRow: PropTypes.func,
    manageHeight: PropTypes.bool,
    className: PropTypes.string,
    rowClassName: PropTypes.string,
    thinRows: PropTypes.bool,
    footer: PropTypes.any,
    paginationClassName: PropTypes.string,
  };

  static defaultProps = {
    id: null,
    dataSource: [],
    columns: [],
    enablePagination: false,
    enableResizableColumnsAndHorizScroll: true,
    paginationMeta: { current_page: 1, total_count: 1 },
    onChangePage: undefined,
    loading: false,
    locale: null,
    rowKey: undefined,
    sorter: undefined,
    onRow: undefined,
    manageHeight: true,
    className: undefined,
    rowClassName: undefined,
    thinRows: undefined,
    footer: null,
    paginationClassName: undefined,
  };

  state = {
    columns: this.props.columns,
  };

  handleResize = index => (e, { size }) => {
    this.setState(({ columns }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      return { columns: nextColumns };
    });
  };

  getResizableColumns = memoize(columns =>
    columns.map((col, index) => ({
      ...col,
      onHeaderCell: column => ({
        noResize: column.noResize,
        minWidth: column.minWidth,
        width: column.width,
        onResize: this.handleResize(index),
      }),
    })),
  );

  render() {
    const {
      id,
      dataSource,
      enableResizableColumnsAndHorizScroll,
      loading,
      locale,
      enablePagination,
      paginationMeta,
      onChangePage,
      rowKey,
      onRow,
      sorter,
      manageHeight,
      className,
      rowClassName,
      thinRows,
      footer,
      paginationClassName,
    } = this.props;

    return (
      <AutoSizer disableHeight={!manageHeight}>
        {({ width, height }) => (
          <div id={id} style={{ width, height }}>
            <div
              style={{
                width,
                height: enablePagination ? height - PAGINATION_COMPONENT_HEIGHT : height,
              }}
            >
              <StaticTable
                dataSource={dataSource}
                enableResizableColumnsAndHorizScroll={
                  enableResizableColumnsAndHorizScroll
                }
                loading={loading}
                locale={locale}
                enablePagination={enablePagination}
                rowKey={rowKey}
                onRow={onRow}
                sorter={sorter}
                className={className}
                rowClassName={rowClassName}
                thinRows={thinRows}
                columns={
                  enableResizableColumnsAndHorizScroll
                    ? this.getResizableColumns(this.state.columns)
                    : this.state.columns
                }
                components={
                  enableResizableColumnsAndHorizScroll
                    ? { header: { cell: ResizeableTitle } }
                    : undefined
                }
                scroll={{
                  ...(enableResizableColumnsAndHorizScroll && {
                    x: this.state.columns.reduce(
                      (totalWidthAcc, column) => totalWidthAcc + column.width,
                      0,
                    ),
                  }),
                  y: enablePagination
                    ? height - HEADER_HEIGHT - PAGINATION_COMPONENT_HEIGHT
                    : height - HEADER_HEIGHT,
                }}
              />
            </div>
            {footer}
            {enablePagination ? (
              <Paginator
                paginationMeta={paginationMeta}
                onChangePage={onChangePage}
                className={classnames(styles.Paginator, paginationClassName)}
              />
            ) : null}
          </div>
        )}
      </AutoSizer>
    );
  }
}
