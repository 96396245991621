import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import TextField from '../ReduxFormFields/TextField';
import useFilePreview from '../../hooks/useFilePreview';

import { RawButton as Button } from '../Button';
import Text from '../Text';
import Icon from '../Icon';
import SelectField from '../ReduxFormFields/SelectField';

import styles from './FileUpload.scss';

const SIZE_OPTIONS = [
  {
    label: 'Full Size',
    value: 'full',
  },
  {
    label: 'Small',
    value: 'small',
  },
  {
    label: 'Medium',
    value: 'medium',
  },
  {
    label: 'Large',
    value: 'large',
  },
];

const Resize = ({ value, onChange }) => (
  <div className={styles.Resize}>
    <Text size="medium" color="dark-grey" uppercase>
      Select a size
    </Text>
    <SelectField
      className={styles.Field}
      options={SIZE_OPTIONS}
      input={{ onChange, value }}
    />
  </div>
);

Resize.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const FileUpload = ({ allowResize, allowAltText, submitButtonText, isUploading, onFileSelected }) => {
  const [acceptedFile, setAcceptedFile] = useState(null);
  const [altText, setAltText] = useState(undefined);
  const preview = useFilePreview(acceptedFile);
  const [size, setSize] = useState('full');
  const onDrop = useCallback(
    files => {
      setAcceptedFile(files[0]);
    },
    [setAcceptedFile],
  );
  const onUploadAgain = useCallback(() => {
    setAltText(undefined);
    setAcceptedFile(null);
  }, [setAltText, setAcceptedFile]);
  const onSubmit = useCallback(() => {
    onFileSelected(acceptedFile, allowResize ? size : null, allowAltText ? altText : null);
  }, [acceptedFile, size, altText, onFileSelected]);
  if (acceptedFile) {
    return (
      <div className={styles.Preview}>
        <div className={styles.FileInfo}>
          <img className={styles.Image} alt={acceptedFile.name} src={preview} />
          <div className={styles.Details}>
            <div className={styles.Details}>
              <Text>{acceptedFile.name}</Text>
              <Button type="outline" icon="upload" onClick={onUploadAgain}>
                Upload again
              </Button>
            </div>
          </div>
        </div>
        {allowAltText && (
          <div className={styles.AltText}>
            <TextField label="Alt Text" placeholder="Alt text" className={styles.AltTextField} input={{
              value: altText,
              onChange: e => setAltText(e.target.value),
            }} />
          </div>
        )}
        {allowResize && <Resize value={size} onChange={setSize} />}
        <Button className={styles.Button} loading={isUploading} onClick={onSubmit}>
          {submitButtonText}
        </Button>
      </div>
    );
  }
  return (
    <Dropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps({ className: styles.Dropzone })}>
          <input {...getInputProps()} />
          <Text size="large" color="dark-grey" className={styles.TitleText}>
            <Icon name="upload" size="small" className={styles.UploadIcon} />
            Upload a file
          </Text>
          <Text size="medium">Allowed formats: .png, .jpg, .pdf</Text>
        </div>
      )}
    </Dropzone>
  );
};

FileUpload.propTypes = {
  allowAltText: PropTypes.bool,
  allowResize: PropTypes.bool,
  submitButtonText: PropTypes.string,
  isUploading: PropTypes.bool,
  onFileSelected: PropTypes.func,
};

FileUpload.defaultProps = {
  allowResize: false,
  allowAltText: false,
  submitButtonText: 'Attach file',
  isUploading: false,
  onFileSelected: null,
};

export default FileUpload;
