import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Field, { fieldDisplayPropTypes, fieldDisplayDefaultProps } from './Field';
import TimePicker from '../TimePicker';
import styles from './TimePickerField.scss';

const TIME_FORMAT = 'h:mm A';

const TimePickerField = props => {
  const { input, placeholder, disabled, allowClear, getPopupContainer } = props;
  return (
    <Field {...props}>
      <TimePicker
        onChange={date => {
          input.onChange(date ? date.toString() : null);
        }}
        value={input.value !== '' ? moment(input.value) : null}
        className={styles.TimePicker}
        popupClassName={styles.TimePicker__Popup}
        getPopupContainer={getPopupContainer}
        placeholder={placeholder}
        disabled={disabled}
        allowClear={allowClear}
        use12Hours={true}
        minuteStep={15}
        format={TIME_FORMAT}
      />
    </Field>
  );
};

TimePickerField.propTypes = {
  ...fieldDisplayPropTypes,
  input: PropTypes.shape({
    value: PropTypes.object,
    onChange: PropTypes.func,
  }).isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
};

TimePickerField.defaultProps = {
  ...fieldDisplayDefaultProps,
  placeholder: null,
  disabled: false,
  allowClear: true,
};

export default TimePickerField;
