import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dropdown as AntdDropdown } from 'antd';
import classNames from 'classnames';
import uniqueId from 'lodash/uniqueId';
import styles from './Dropdown.scss';

class Dropdown extends Component {
  static propTypes = {
    id: PropTypes.string,
    autoCloseOnClick: PropTypes.bool,
    children: PropTypes.node,
    disabled: PropTypes.bool,
    isVisible: PropTypes.bool,
    overlayClassName: PropTypes.string,
    triggerClassName: PropTypes.string,
    renderTrigger: PropTypes.func.isRequired,
    trigger: PropTypes.array,
  };

  static defaultProps = {
    id: uniqueId('dropdown_'),
    autoCloseOnClick: true,
    children: null,
    disabled: false,
    isVisible: false,
    overlayClassName: null,
    triggerClassName: null,
    trigger: ['click'],
  };

  state = {
    isVisible: this.props.isVisible,
  };

  handleMenuClick = () => {
    this.setState({ isVisible: false });
  };

  render() {
    const {
      id,
      autoCloseOnClick,
      disabled,
      children,
      renderTrigger,
      overlayClassName,
      triggerClassName,
      trigger,
      ...restProps
    } = this.props;
    const { isVisible } = this.state;
    return (
      <AntdDropdown
        id={id}
        overlay={
          autoCloseOnClick ? (
            <div role="listbox" onClick={this.handleMenuClick} tabIndex="0">
              {children}
            </div>
          ) : (
            children
          )
        }
        overlayClassName={overlayClassName}
        trigger={trigger}
        getPopupContainer={triggerNode => triggerNode.parentNode}
        className={classNames({ [styles.Disabled]: disabled })}
        disabled={disabled}
        onVisibleChange={visible => this.setState({ isVisible: visible })}
        visible={isVisible}
        {...restProps}
      >
        <div
          id={`${id}-select-trigger`}
          className={classNames(styles.TriggerContainer, triggerClassName)}
        >
          {renderTrigger(isVisible)}
        </div>
      </AntdDropdown>
    );
  }
}

export default Dropdown;
