import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Field, { fieldDisplayPropTypes, fieldDisplayDefaultProps } from './Field';
import DatePicker from '../DatePicker';
import styles from './DateField.scss';

export default function DateField(props) {
  const { input, placeholder, disabled, allowClear, getCalendarContainer } = props;
  return (
    <Field {...props}>
      <DatePicker
        onChange={input.onChange}
        getCalendarContainer={getCalendarContainer}
        value={input.value !== '' ? moment(input.value) : input.value}
        format="MM/DD/YYYY"
        className={styles.DatePicker}
        placeholder={placeholder}
        disabled={disabled}
        allowClear={allowClear}
      />
    </Field>
  );
}

DateField.propTypes = {
  ...fieldDisplayPropTypes,
  input: PropTypes.shape({
    value: PropTypes.object,
    onChange: PropTypes.func,
  }).isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
};

DateField.defaultProps = {
  ...fieldDisplayDefaultProps,
  placeholder: null,
  disabled: false,
  allowClear: true,
};
