import React from 'react';
import PropTypes from 'prop-types';
import { Tabs as AntdTabs } from 'antd';

const Tabs = ({ children, activeKey, defaultActiveKey, onChange, ...restProps }) => (
  <AntdTabs
    activeKey={activeKey}
    defaultActiveKey={defaultActiveKey}
    onChange={onChange}
    {...restProps}
  >
    {React.Children.map(children, child => {
      return (
        <AntdTabs.TabPane
          tab={child.props['data-title']}
          key={child.props['data-key']}
          title={child.props.title}
          forceRender={!!child.props['data-force']}
        >
          {child}
        </AntdTabs.TabPane>
      );
    })}
  </AntdTabs>
);

Tabs.defaultProps = {
  animated: false,
  children: null,
  activeKey: null,
  defaultActiveKey: null,
  fullWidth: true,
  onChange: undefined,
};

Tabs.propTypes = {
  activeKey: PropTypes.string,
  animated: PropTypes.bool,
  children: PropTypes.node,
  defaultActiveKey: PropTypes.string,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Tabs;
