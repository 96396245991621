import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Badge.scss';

const defaultColors = ['green', 'orange', 'red', 'grey', 'blue', 'purple', 'light-blue'];

/**
 * Component for displaying a badge
 */
class Badge extends PureComponent {
  static propTypes = {
    /** The color of the badge */
    color: PropTypes.oneOfType([PropTypes.oneOf(defaultColors), PropTypes.string]),

    /** Whether or not the badge should be filled */
    fill: PropTypes.bool,

    /** A custom CSS class to be applied to the badge */
    className: PropTypes.string,

    /** The content of the badge */
    children: PropTypes.any,
  };

  static defaultProps = {
    color: 'grey',
    fill: false,
    className: null,
    children: null,
  };

  getStyle() {
    const { color, fill } = this.props;
    return fill
      ? {
          backgroundColor: color,
          borderColor: color,
        }
      : {
          borderColor: color,
        };
  }

  render() {
    const { color, className, fill } = this.props;
    const hasDefaultColor = defaultColors.includes(color);
    return (
      <div
        className={classnames(
          styles.Badge,
          {
            [styles.Fill]: fill,
            [styles[`Badge--${color}`]]: hasDefaultColor,
          },
          className,
        )}
        style={!hasDefaultColor ? this.getStyle() : null}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Badge;
