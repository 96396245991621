import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon/index';
import Text from '../Text/index';
import styles from './Notification.scss';

class Notification extends Component {
  static propTypes = {
    type: PropTypes.oneOf(['information', 'failure', 'success']),
    message: PropTypes.any.isRequired,
    className: PropTypes.string,
    icon: PropTypes.string,
    dismissNotification: PropTypes.func.isRequired,
  };

  static defaultProps = {
    type: 'information',
    icon: null,
    className: null,
  };

  getNotificationIconName() {
    const { icon, type } = this.props;
    if (icon) {
      return icon;
    }

    switch (type) {
      case 'success':
        return 'tick_fill';
      case 'information':
        return 'info_fill';
      case 'failure':
        return 'warning_fill';
      default:
        return null;
    }
  }

  render() {
    const { type, className, message, dismissNotification } = this.props;
    const iconType = this.getNotificationIconName();
    return (
      <div className={classNames(styles[`Notification--${type}`], className)}>
        {iconType && (
          <div className={styles.NotificationIcon}>
            <Icon
              size="small"
              name={iconType}
              className={styles.NotificationIcon__Icon}
            />
          </div>
        )}
        <Text className={styles.NotificationText} as="span" color="white" size="md">
          {message}
        </Text>
        <button className={styles.DismissButton} onClick={dismissNotification}>
          <Icon size="small" name="close_menu" />
        </button>
      </div>
    );
  }
}

export default Notification;
