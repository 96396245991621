import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import { AutoSizer, Column, Table as VirtualizedTable } from 'react-virtualized';
import classNames from 'classnames';
import styles from './Table.scss';
import Paginator from '../Paginator/index';
import ItemActions from '../ItemActions/index';

const ROW_HEIGHT = 50;
const ACTION_CELL_WIDTH = 95;
const PAGINATION_COMPONENT_HEIGHT = 55;

const defaultStyleConfig = {
  Table: styles.Table,
  TableContainer: styles.TableContainer,
  Row: styles.Row,
  RowAlternative: styles['Row--Alternative'],
  Clickable: styles.Clickable,
  NoData: styles.NoData,
  HeaderRow: styles.HeaderRow,
  ActionsGroup: styles.ActionsGroup,
  Actions: styles.Actions,
  Grid: styles.Grid,
  Header: styles.Header,
  Column: styles.Column,
  ColumnHeader: styles.columnHeader,
};

export default class Table extends Component {
  static propTypes = {
    data: PropTypes.array,
    renderEditableRow: PropTypes.func,
    renderFooter: PropTypes.func,
    isEditingRowKey: PropTypes.string,
    rowHref: PropTypes.func,
    onRowClick: PropTypes.func,
    onEditRow: PropTypes.func,
    onEditRowCancel: PropTypes.func,
    onDeleteRow: PropTypes.func,
    customButtons: PropTypes.array,
    defaultStyleRowKey: PropTypes.string,
    enablePagination: PropTypes.bool,
    paginationMeta: PropTypes.object,
    onChangePage: PropTypes.func,
    styleConfig: PropTypes.object,
    noRowContent: PropTypes.node,
    children: PropTypes.node,
    isEditDisabled: PropTypes.func,
    isDeleteDisabled: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    enablePagination: false,
    renderEditableRow: null,
    renderFooter: null,
    isEditingRowKey: 'isEditing',
    defaultStyleRowKey: null,
    paginationMeta: { current_page: 1, total_count: 1 },
    onChangePage: null,
    styleConfig: {},
    onRowClick: null,
    onEditRow: null,
    onEditRowCancel: null,
    onDeleteRow: null,
    customButtons: [],
    noRowContent: 'No Results',
    children: null,
    rowHref: () => undefined,
    isEditDisabled: () => false,
    isDeleteDisabled: () => false,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      styleConfig: this.composeTableStyles(props.styleConfig),
      editRows: [],
    };
  }

  composeTableStyles(styleConfig) {
    return Object.keys(defaultStyleConfig).reduce((composedStyleConfig, key) => {
      return {
        ...composedStyleConfig,
        [key]: classNames(defaultStyleConfig[key], styleConfig[key]),
      };
    }, {});
  }

  scrollToRow(rowIndex) {
    this.table.scrollToRow(rowIndex);
  }

  onEdit = (rowData, e) => {
    const { onEditRow, renderEditableRow } = this.props;

    // If we have a editable state for this row, add it to the
    // edit row array. Otherwise, call the callback directly.
    if (renderEditableRow) {
      this.setState(state => ({
        editRows: [...state.editRows, rowData],
      }));
    } else {
      onEditRow(rowData, e);
    }
  };

  submitEdit = (rowData, editData) => {
    const { onEditRow } = this.props;
    if (onEditRow) {
      onEditRow(rowData, editData);
    }
    this.cancelEdit(rowData);
  };

  cancelEdit = rowData => {
    const { onEditRowCancel } = this.props;
    this.setState(state => ({
      editRows: state.editRows.filter(row => row !== rowData),
    }));
    if (onEditRowCancel) {
      onEditRowCancel(rowData);
    }
  };

  onDelete = (rowData, e) => {
    const { onDeleteRow } = this.props;
    if (onDeleteRow) {
      e.preventDefault();
      onDeleteRow(rowData, e);
    }
  };

  getRowStyle(rowData) {
    const alternativeRow =
      this.props.defaultStyleRowKey && !rowData[this.props.defaultStyleRowKey];
    return classNames({
      [this.state.styleConfig.RowAlternative]: alternativeRow,
      [this.state.styleConfig.Row]: !alternativeRow,
      [this.state.styleConfig.Clickable]: this.props.onRowClick,
    });
  }

  noRowsRenderer = () => {
    const { noRowContent } = this.props;
    return (
      <div className={this.state.styleConfig.NoData} role="row">
        {noRowContent}
      </div>
    );
  };

  headerRenderer = ({ columns, className, style }) => (
    <div className={classNames(className, styles.HeaderRow)} role="row" style={style}>
      {columns}
    </div>
  );

  isRowBeingEdited(rowData) {
    const { isEditingRowKey } = this.props;
    const { editRows } = this.state;

    return editRows.indexOf(rowData) !== -1 || rowData[isEditingRowKey];
  }

  rowRenderer = ({ key, columns, style, rowData }) => {
    const { renderEditableRow, onRowClick } = this.props;

    return (
      <a
        key={key}
        role="row"
        tabIndex={0}
        style={style}
        className={this.getRowStyle(rowData)}
        href={this.props.rowHref(rowData)}
        onClick={onRowClick ? e => onRowClick(rowData, e) : null}
      >
        {this.isRowBeingEdited(rowData)
          ? renderEditableRow(rowData, this.submitEdit, this.cancelEdit)
          : columns}
      </a>
    );
  };

  actionCellRenderer = ({ rowData }) => {
    const { onEditRow, onDeleteRow, customButtons } = this.props;
    const { styleConfig } = this.state;
    return (
      <ItemActions
        item={rowData}
        onEdit={onEditRow && this.onEdit}
        onDelete={onDeleteRow && this.onDelete}
        customButtons={customButtons}
        className={styleConfig.ActionsGroup}
        actionClassName={styleConfig.Actions}
        actionStyle={{ width: ACTION_CELL_WIDTH }}
        isEditDisabled={this.props.isEditDisabled}
        isDeleteDisabled={this.props.isDeleteDisabled}
      />
    );
  };

  getActionCount() {
    const { onEditRow, onDeleteRow, customButtons } = this.props;

    return (
      customButtons.length +
      [!!onEditRow, !!onDeleteRow].filter(present => present).length
    );
  }

  render() {
    const actionCount = this.getActionCount();
    const { styleConfig } = this.state;

    return (
      <AutoSizer className={styleConfig.TableContainer}>
        {({ width, height }) => (
          <div style={{ width, height }}>
            <VirtualizedTable
              width={width}
              rowHeight={ROW_HEIGHT}
              className={styleConfig.Table}
              headerHeight={ROW_HEIGHT}
              gridClassName={styleConfig.Grid}
              height={
                this.props.enablePagination
                  ? height - PAGINATION_COMPONENT_HEIGHT
                  : height
              }
              rowRenderer={this.rowRenderer}
              headerClassName={styleConfig.Header}
              rowCount={this.props.data.length}
              noRowsRenderer={this.noRowsRenderer}
              headerRowRenderer={this.headerRenderer}
              rowGetter={({ index }) => this.props.data[index]}
              ref={node => {
                this.table = node;
              }}
            >
              {this.props.data.length > 0 &&
                Children.map(this.props.children, (child, key) => (
                  <Column
                    key={key}
                    {...child.props}
                    className={styleConfig.Column}
                    headerClassName={styleConfig.ColumnHeader}
                  />
                ))}
              {actionCount > 0 && (
                <Column
                  dataKey="table_actions"
                  width={actionCount * ACTION_CELL_WIDTH}
                  cellRenderer={this.actionCellRenderer}
                  headerRenderer={() => null}
                  headerStyle={{ padding: 0 }}
                />
              )}
            </VirtualizedTable>
            {this.props.enablePagination ? (
              <Paginator
                paginationMeta={this.props.paginationMeta}
                onChangePage={this.props.onChangePage}
              />
            ) : null}
            {this.props.renderFooter && this.props.renderFooter()}
          </div>
        )}
      </AutoSizer>
    );
  }
}
