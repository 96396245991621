import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from '../Icon/index';
import Modal from '../Modal/index';
import Button from '../Button/index';
import styles from './DeleteModal.scss';

export default class DeleteModal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    message: PropTypes.node,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        component: PropTypes.func,
        type: PropTypes.string,
        icon: PropTypes.string,
        onClick: PropTypes.func,
        disabled: PropTypes.bool,
        content: PropTypes.node,
      }),
    ),
    wrapperClassName: PropTypes.string,
    buttonGroupClassName: PropTypes.string,
  };

  static defaultProps = {
    message: '',
    buttons: [],
    wrapperClassName: null,
    buttonGroupClassName: null,
  };

  getButtonContent(buttonDefinition) {
    const { content, className, component, ...buttonProps } = buttonDefinition;
    const componentProps = {
      ...buttonProps,
      key: content,
      className: classNames(className, styles.Button),
    };

    return !component ? (
      <Button
        {...buttonProps}
        key={content}
        className={classNames(className, styles.Button)}
      >
        {content}
      </Button>
    ) : (
      React.createElement(component, componentProps, content)
    );
  }

  render() {
    const {
      title,
      message,
      buttons,
      wrapperClassName,
      buttonGroupClassName,
      ...modalProps
    } = this.props;

    return (
      <Modal {...modalProps}>
        <div className={classNames(styles.Wrapper, wrapperClassName)}>
          <span className={styles.Icon}>
            <Icon name="delete" />
          </span>
          <div className={styles.Title}>{title}</div>
          {message && <p>{message}</p>}
          {buttons.length > 0 && (
            <div className={classNames(styles.ButtonGroup, buttonGroupClassName)}>
              {buttons.map(button => this.getButtonContent(button))}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}
