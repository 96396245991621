import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import IconButton from '../IconButton/index';
import styles from './Popup.scss';

const Popup = ({ active, children, className, onRequestClose }) => {
  return (
    <div
      className={classnames(
        styles.Popup,
        { [styles['Popup--active']]: active },
        className,
      )}
    >
      <div className={styles.PopupInner}>
        <div className={styles.PopupDismiss}>
          <IconButton icon="close_menu" onClick={onRequestClose} />
        </div>
        {children}
      </div>
    </div>
  );
};

Popup.defaultProps = {
  children: null,
  className: null,
};

Popup.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default Popup;
