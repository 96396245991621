import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { CSSTransitionGroup } from 'react-transition-group';
import InlineNotification from './InlineNotification';
import styles from './Notification.scss';

export default class Notification extends Component {
  static propTypes = {
    /** The time elapsed before the notification is dismissed automatically. Default of 30 seconds. */
    autoDismiss: PropTypes.number,
    /** The function called when a notification is ready to be dismissed. */
    dismissNotification: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired,
    className: PropTypes.string,
    wrapperClassName: PropTypes.string,
    type: PropTypes.oneOf(['information', 'failure', 'success']),
    icon: PropTypes.string,
    iconSize: PropTypes.string,
  };

  static defaultProps = {
    type: 'information',
    className: null,
    wrapperClassName: null,
    autoDismiss: 30000,
    icon: null,
    iconSize: 'medium',
  };

  componentDidMount() {
    this.setDismisser();
  }

  componentWillUnmount() {
    this.clearDismisser();
  }

  clearDismisser = () => {
    if (this.dismissTimeout) {
      window.clearTimeout(this.dismissTimeout);
    }
  };

  setDismisser = () => {
    const { autoDismiss } = this.props;
    if ((!autoDismiss && autoDismiss !== 0) || autoDismiss > 0) {
      this.dismissTimeout = window.setTimeout(
        this.dismissNotification,
        autoDismiss || Notification.defaultProps.autoDismiss,
      );
    }
  };

  dismissNotification = () => {
    this.props.dismissNotification();
  };

  render() {
    return (
      <CSSTransitionGroup
        transitionAppear={true}
        transitionLeaveTimeout={500}
        transitionEnterTimeout={500}
        transitionAppearTimeout={500}
        transitionName={{
          appear: styles['Transition--appear'],
          appearActive: styles['Transition--appearActive'],
        }}
      >
        <div className={classnames(styles.Wrapper, this.props.wrapperClassName)}>
          <InlineNotification
            {...this.props}
            dismissNotification={this.dismissNotification}
          />
        </div>
      </CSSTransitionGroup>
    );
  }
}
