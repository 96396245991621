import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Well.scss';

const Well = ({ className, children, padding, border }) => (
  <div
    className={classnames(
      styles.Container,
      {
        [styles[`Container--padding`]]: padding,
        [styles[`Container--border`]]: border,
      },
      className,
    )}
  >
    {children}
  </div>
);

Well.defaultProps = {
  className: null,
  padding: true,
  border: true,
};

Well.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  padding: PropTypes.bool,
  border: PropTypes.bool,
};

export default Well;
