import React from 'react';
import PropTypes from 'prop-types';
import TreeSelect from './TreeSelect';
import { Field } from '../ReduxFormFields/index';

const TreeSelectReduxFormField = props => {
  const { input, label, meta, ...rest } = props;

  const onChange = (value, ...args) => {
    // Redux form doesn't treat `undefined` as a value being cleared out.
    input.onChange(value === undefined ? null : value, ...args);
  };
  const error = meta && meta.error && (meta.touched || meta.submitFailed);

  return (
    <Field {...props} borderless fieldType="TreeSelectField">
      <TreeSelect value={input.value} onChange={onChange} error={error} {...rest} />
    </Field>
  );
};

TreeSelectReduxFormField.propTypes = {
  input: PropTypes.any.isRequired,
  meta: PropTypes.object.isRequired,
  label: PropTypes.node,
};

TreeSelectReduxFormField.defaultProps = {
  label: null,
};

export default TreeSelectReduxFormField;
