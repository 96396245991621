import { ENDPOINT, makeHeaders, request } from './';

export const auth = async () => {
  try {
    const response = await request(`${ENDPOINT}/auth`, {
      method: 'get',
      headers: makeHeaders(),
    });
    return response.status === 200;
  } catch (e) {
    console.error(e);
    return false;
  }
};
