import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon/index';
import Tooltip from '../Tooltip/index';
import styles from './IconButton.scss';

export default class IconButton extends Component {
  static propTypes = {
    as: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    icon: PropTypes.string.isRequired,
    className: PropTypes.string,
    rel: PropTypes.string,
    href: PropTypes.string,
    onClick: PropTypes.func,
    target: PropTypes.string,
    download: PropTypes.bool,
    disabled: PropTypes.bool,
    children: PropTypes.node,
    tooltip: PropTypes.string,
    bordered: PropTypes.bool,
    tooltipPosition: PropTypes.string,
    iconProps: PropTypes.object,
  };

  static defaultProps = {
    as: 'a',
    className: undefined,
    children: null,
    disabled: false,
    download: false,
    href: undefined,
    onClick: undefined,
    rel: undefined,
    target: undefined,
    tooltip: null,
    bordered: false,
    tooltipPosition: 'top',
    iconProps: {},
  };

  render() {
    const {
      as: As,
      bordered,
      className,
      children,
      disabled,
      icon,
      tooltip,
      tooltipPosition,
      onClick,
      iconProps,
      ...restProps
    } = this.props;

    const base = (
      <As
        className={classnames(
          styles.IconButton,
          {
            [styles['IconButton--disabled']]: disabled,
            [styles['IconButton--bordered']]: bordered,
            [styles['IconButton--delete']]: icon === 'delete',
          },
          className,
        )}
        onClick={disabled ? null : onClick}
        {...restProps}
      >
        <Icon name={icon} className={styles.Icon} {...iconProps} />
        {children ? <span className={styles.IconButton__Text}>{children}</span> : null}
      </As>
    );

    return tooltip ? (
      <Tooltip content={tooltip} placement={tooltipPosition}>
        {base}
      </Tooltip>
    ) : (
      base
    );
  }
}
