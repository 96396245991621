import PropTypes from 'prop-types';

export const Sorter = props => {
  if (
    props.columns.some(column => column.sorter === 'true') &&
    (props.sorter === undefined || typeof props.sorter !== 'function')
  ) {
    return new Error(`Please provide a sorter function if sorting is enabled.
    (You enabled it by passing "true" into the "enableSorting" prop.) `);
  }
  return null;
};

export const Column = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  dataIndex: PropTypes.string,
  key: PropTypes.string,
  sorter: PropTypes.oneOfType([PropTypes.bool, Sorter]),
};
