import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import range from 'lodash/range';
import FieldError from '../FieldError/index';
import InfoTooltip from '../InfoTooltip/index';
import Text from '../Text/index';
import styles from './DaysBetweenField.scss';

const DaysBetweenField = props => {
  const {
    id,
    className,
    componentLabel,
    disabled,
    errorPosition,
    min,
    max,
    input,
    label,
    labelTooltip,
    meta,
  } = props;

  const errorContent = (
    <FieldError
      error={meta && meta.touched && meta.error}
      warning={meta && meta.touched && meta.warning}
    />
  );

  const { min: currentMin, max: currentMax } = input.value;

  const handleMinChange = e => {
    const changedMin = parseInt(e.target.value, 10);
    input.onChange({
      ...input.value,
      min: changedMin,
      // Auto adjust to make valid ranges
      ...(changedMin > currentMax && {
        max: changedMin + 1,
      }),
    });
  };

  const handleMaxChange = e => {
    const changedMax = parseInt(e.target.value, 10);
    input.onChange({
      ...input.value,
      max: changedMax,
      // Auto adjust to make valid ranges
      ...(changedMax < currentMin && {
        min: changedMax - 1,
      }),
    });
  };

  const deriveOptions = (lowerBound, upperBound) => {
    const options = range(lowerBound, upperBound);
    return options.map(index => (
      <option key={index} value={index}>
        {index}
      </option>
    ));
  };

  return (
    <div
      className={classnames(
        styles.DaysBetweenField,
        {
          [styles[`DaysBetweenField--Disabled`]]: disabled,
        },
        className,
      )}
    >
      {label && (
        <label htmlFor={id} className={styles.Label}>
          <span className={styles.Label__Wrapper}>
            <span>
              {label}
              {labelTooltip && (
                <InfoTooltip
                  className={styles.Label__Tooltip}
                  color="blue"
                  content={labelTooltip}
                />
              )}
            </span>
          </span>
        </label>
      )}
      {componentLabel}
      {errorPosition === 'top' && errorContent}
      <div className={styles.DaysBetweenField__Selects}>
        <Text size="sm">
          Between
          <select className={styles.Select} onChange={handleMinChange} value={currentMin}>
            {deriveOptions(min, currentMax)}
          </select>
          day(s) and
          <select className={styles.Select} onChange={handleMaxChange} value={currentMax}>
            {deriveOptions(currentMin + 1, max + 1)}
          </select>
          day(s) in advance.
        </Text>
      </div>
      {errorPosition === 'bottom' && errorContent}
    </div>
  );
};

DaysBetweenField.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  componentLabel: PropTypes.string,
  disabled: PropTypes.bool,
  errorPosition: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
  }),
  label: PropTypes.string,
  labelTooltip: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.any,
    warning: PropTypes.bool,
  }),
  min: PropTypes.number,
  max: PropTypes.number,
};

DaysBetweenField.defaultProps = {
  id: `DaysBetweenField_${Math.random()}`,
  className: null,
  componentLabel: null,
  disabled: false,
  errorPosition: 'bottom',
  input: {
    min: 0,
    max: 14,
  },
  label: null,
  labelTooltip: null,
  meta: {},
  min: 0,
  max: 14,
};

export default DaysBetweenField;
