import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './ButtonGroup.scss';

const ButtonGroup = ({
  alignCenter,
  alignEven,
  alignRight,
  className,
  children,
  fullWidth,
  fullWidthItems,
  gap,
  style,
  vertical,
}) => {
  const renderableChildren = Array.isArray(children)
    ? children.filter(i => i)
    : [children];
  const childCount = renderableChildren.length - 1;
  return (
    <div
      style={style}
      className={classnames(
        styles.ButtonGroup,
        {
          [styles.fullWidth]: fullWidth,
          [styles.vertical]: vertical,
          [styles.alignRight]: alignRight,
          [styles.alignCenter]: alignCenter,
          [styles.alignEven]: alignEven,
        },
        className,
      )}
    >
      {React.Children.map(renderableChildren, (child, index) => (
        <div
          key={index} // eslint-disable-line
          className={classnames(styles.ButtonGroup__Item, {
            [styles.noMargin]: index === childCount,
            [styles.FullWidth]: fullWidthItems,
          })}
          style={{
            marginRight: gap,
            ...(vertical && {
              marginRight: 0,
              marginBottom: gap,
            }),
          }}
        >
          {child}
        </div>
      ))}
    </div>
  );
};

ButtonGroup.defaultProps = {
  alignCenter: false,
  alignEven: false,
  alignRight: false,
  className: '',
  fullWidth: false,
  fullWidthItems: false,
  gap: 12,
  style: null,
  vertical: false,
};

ButtonGroup.propTypes = {
  alignCenter: PropTypes.bool,
  alignEven: PropTypes.bool,
  alignRight: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  fullWidthItems: PropTypes.bool,
  gap: PropTypes.number,
  style: PropTypes.object,
  vertical: PropTypes.bool,
};

export default ButtonGroup;
