import React from 'react';
import PropTypes from 'prop-types';
import Heading from '../Heading/index';
import Text from '../Text/index';
import styles from './NumberedSettingSection.scss';

export default function NumberedSettingSection({ index, title, description, children }) {
  return (
    <div className={styles.Card}>
      <div className={styles.Index}>{index}</div>
      <div className={styles.Content}>
        <div className={styles.ContentHeading}>
          <Heading size="medium" color="blue">
            {title}
          </Heading>
          {description && (
            <Text size="large" color="dark" weight="thin" className={styles.Description}>
              {description}
            </Text>
          )}
        </div>

        {children}
      </div>
    </div>
  );
}

NumberedSettingSection.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
};

NumberedSettingSection.defaultProps = {
  description: null,
};
