import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Text from '../Text/index';
import GoogleSVG from './assets/google.svg';
import MicrosoftSVG from './assets/microsoft.svg';
import DefaultImageSvg from './assets/default.svg';
import styles from './SSOButton.scss';

const connectionToIconSrc = {
  auth0: '',
  ad: '',
  adfs: '',
  amazon: '',
  apple: '',
  github: '',
  'google-apps': '',
  'google-oauth2': GoogleSVG,
  instagram: '',
  linkedin: '',
  office365: '',
  paypal: '',
  salesforce: '',
  samlp: '',
  sharepoint: '',
  waad: '',
  windowslive: MicrosoftSVG,
  yahoo: '',
  'beverly-hills-oidc': 'http://www.beverlyhills.org/images/BHLogo.gif',
  'camino-azure-ad': MicrosoftSVG,
};

class SSOButton extends Component {
  static propTypes = {
    authorize_url: PropTypes.string.isRequired,
    connection_name: PropTypes.string.isRequired,
    SsoAction: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    isLast: PropTypes.bool,
    name: PropTypes.string.isRequired,
    organization: PropTypes.object.isRequired,
    disclaimerAccepted: PropTypes.bool,
    guestId: PropTypes.number,
    appName: PropTypes.string,
  };

  static defaultProps = {
    isLast: false,
    disclaimerAccepted: false,
    guestId: null,
    appName: null,
  };

  storeDetails = () => {
    const {
      id,
      organization,
      disclaimerAccepted,
      SsoAction,
      guestId,
      authorize_url,
      appName,
    } = this.props;

    const sso = {
      guestId,
      appName,
      SsoAction,
      login_strategy_id: id,
      slug: organization.slug,
      organization_id: organization.id,
      accepted_disclaimer: disclaimerAccepted,
      origin_location_search: window.location.search,
      origin_location_pathname: window.location.pathname,
    };

    global.localStorage.setItem('sso', JSON.stringify(sso));
    window.location = authorize_url;
  };

  render() {
    const { connection_name, id, name, isLast } = this.props;
    const imageSrc = connectionToIconSrc[connection_name] || DefaultImageSvg;

    return (
      <span
        className={classnames(
          styles.SSOButton,
          styles[`SSOButton--${connection_name}`],
          isLast && styles['SSOButton--NoMargin'],
        )}
        data-sso-id={id}
        onClick={this.storeDetails}
        role="button"
        tabIndex="0"
      >
        <div
          className={classnames(
            styles.SSOButton__ImageContainer,
            styles[`SSOButton__ImageContainer--${connection_name}`],
          )}
        >
          <img className={styles.SSOButton__Image} src={imageSrc} alt="SSO Reference" />
        </div>
        <div className={styles.SSOButton__Text}>
          <Text color="inherit" size="md" weight="medium-bold">
            Sign in with {name}
          </Text>
        </div>
      </span>
    );
  }
}

export default SSOButton;
