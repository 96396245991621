import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ButtonGroupToggle.scss';

export default class ButtonGroupToggle extends Component {
  static propTypes = {
    value: PropTypes.any,
    label: PropTypes.node,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any,
      }),
    ),
  };

  static defaultProps = {
    label: null,
    value: null,
    options: [],
    disabled: false,
  };

  onClick = value => e => {
    this.props.onChange(value, e);
  };

  render() {
    return (
      <div
        className={classNames(styles.ButtonGroupToggle, {
          [styles.Disabled]: this.props.disabled,
        })}
      >
        {this.props.label ? <div className={styles.Label}>{this.props.label}</div> : null}
        {this.props.options.map(({ value, label }, key) => (
          <button
            key={key}
            type="button"
            onClick={this.onClick(value)}
            disabled={this.props.disabled}
            className={classNames(styles.Option, {
              [styles['Option--active']]: value === this.props.value,
            })}
          >
            {label}
          </button>
        ))}
      </div>
    );
  }
}
