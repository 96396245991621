import React from 'react';

export const ContentStateConverter = {
  blockToHTML: block => {
    switch (block.type) {
      case 'atomic':
        return <figure />;
      default:
        return undefined;
    }
  },

  entityToHTML: (entity, originalText) => {
    if (entity.type === 'LINK') {
      return `<a href="${entity.data.url}" target="_blank">${originalText}</a>`;
    }

    if (entity.type === 'ATTACHMENT') {
      const altAttribute = entity.data.alt ? `alt="${entity.data.alt}"` : '';
      return `<a href="${entity.data.href}" data-attachment data-size="${entity.data.size}" target="_blank">
          <img src="${entity.data.src}" ${altAttribute}/>
        </a>`;
    }

    return undefined;
  },
};

export const HTMLConverter = {
  htmlToBlock: (nodeName, node) => {
    if (nodeName === 'a' && node.hasAttribute('data-attachment')) {
      if (node.children.length === 1) {
        const child = node.children[0];
        return {
          type: 'atomic',
          data: {
            src: child.src,
            alt: child.alt,
            href: node.href,
            size: node.getAttribute('data-size'),
          },
        };
      }
    }

    if (node && node.className) {
      switch (node.className) {
        case 'heading-1':
          return { type: 'header-one' };
        case 'heading-2':
          return { type: 'header-two' };
        case 'heading-3':
          return { type: 'header-three' };
        case 'heading-4':
          return { type: 'header-four' };
        default:
          return undefined;
      }
    }

    return undefined;
  },

  htmlToEntity: (nodeName, node, createEntity) => {
    if (nodeName === 'a' && node.hasAttribute('data-attachment')) {
      if (node.children.length === 1) {
        const child = node.children[0];
        return createEntity('ATTACHMENT', 'MUTABLE', {
          src: child.src,
          alt: child.alt,
          href: node.href,
          size: node.getAttribute('data-size'),
        });
      }
    } else if (nodeName === 'a') {
      return createEntity('LINK', 'MUTABLE', { url: node.href });
    }

    return undefined;
  },
};
