import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Text from '../Text';
import Field, { fieldDisplayDefaultProps, fieldDisplayPropTypes } from './Field';
import styles from './SingleOptionPickerField.scss';

export function Option({
  image,
  label,
  description,
  value,
  hideRadio,
  setValue,
  selected,
  className,
}) {
  const radio = hideRadio ? null : (
    <span
      className={classnames(styles.Radio, {
        [styles.Right]: !!image,
        [styles.Selected]: selected,
      })}
    />
  );

  return (
    <button
      type="button"
      className={classnames(styles.Option, { [styles.Selected]: selected }, className)}
      onClick={() => setValue(value)}
    >
      {image && <img src={image} alt={label} className={styles.Image} />}
      {/* If we don't have an image, place the radio on the left side */}
      {!image && radio}
      <span>
        <Text size="large" color="dark-charcoal">
          {label}
        </Text>
        {description && (
          <Text size="small" color="dark-grey" className={styles.Description}>
            {description}
          </Text>
        )}
      </span>
      {/* If we have an image, place the radio on the right side */}
      {!!image && radio}
    </button>
  );
}

Option.propTypes = {
  image: PropTypes.string,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.any,
  hideRadio: PropTypes.bool,
  setValue: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

Option.defaultProps = {
  image: null,
  value: null,
  description: null,
  hideRadio: false,
};

export const SingleOptionPickerField = props => {
  const { input, options, hideRadio, direction } = props;

  return (
    <Field {...props} borderless fieldType="SingleOptionPickerField">
      <div className={styles.Container}>
        <div
          className={classnames(styles.Grid, {
            [styles.Vertical]: direction === 'vertical',
          })}
        >
          {options.map(option => (
            <div key={option.value} className={styles.Cell}>
              <Option
                {...option}
                hideRadio={hideRadio}
                selected={option.value === input.value}
                setValue={value => {
                  if (input.onBlur) input.onBlur(value);
                  if (input.onChange) input.onChange(value);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </Field>
  );
};

SingleOptionPickerField.propTypes = {
  ...fieldDisplayPropTypes,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }),
  meta: PropTypes.shape({
    initial: PropTypes.any,
    error: PropTypes.string,
    touched: PropTypes.bool,
    pristine: PropTypes.bool,
  }),
  options: PropTypes.array,
  disabled: PropTypes.bool,
  hideRadio: PropTypes.bool,
  direction: PropTypes.oneOf(['vertical', 'horizontal']),
};

SingleOptionPickerField.defaultProps = {
  ...fieldDisplayDefaultProps,
  id: null,
  meta: {
    error: null,
    touched: false,
    pristine: true,
  },
  input: {
    value: null,
    onBlur: () => false,
    onChange: () => false,
  },
  options: [],
  disabled: false,
  hideRadio: false,
  direction: 'horizontal',
};

export default SingleOptionPickerField;
