import PropTypes from 'prop-types';
import classNames from 'classnames';
import React, { PureComponent } from 'react';
import { TreeSelect as AntdTreeSelect } from 'antd';
import styles from './TreeSelect.scss';
import Icon from '../Icon/index';

export default class TreeSelect extends PureComponent {
  static propTypes = {
    allowClear: PropTypes.bool,
    disabled: PropTypes.bool,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    treeData: PropTypes.array,
    onChange: PropTypes.func,
    value: PropTypes.any,
    treeNodeFilterProp: PropTypes.string,
    className: PropTypes.string,
    treeCheckable: PropTypes.bool,
    error: PropTypes.bool,
    id: PropTypes.string,
  };

  static defaultProps = {
    allowClear: true,
    disabled: false,
    placeholder: 'Please select',
    treeData: [],
    onChange: null,
    value: undefined,
    treeNodeFilterProp: 'value',
    className: null,
    treeCheckable: false,
    error: false,
    id: null,
  };

  render() {
    const {
      className,
      allowClear,
      id,
      disabled,
      placeholder,
      treeData,
      onChange,
      treeNodeFilterProp,
      treeCheckable,
      value,
      error,
      ...rest
    } = this.props;

    return (
      <div id={id} className={classNames(styles.TreeSelect, className)}>
        <AntdTreeSelect
          placeholder={placeholder}
          allowClear={allowClear}
          disabled={disabled}
          className={classNames(
            styles.TreeSelect,
            {
              [styles.Error]: error,
            },
            className,
          )}
          dropdownClassName={styles.Dropdown}
          treeData={treeData}
          onChange={onChange}
          showSearch={true}
          treeNodeFilterProp={treeNodeFilterProp}
          treeCheckable={treeCheckable}
          suffixIcon={<Icon name="dropdown" className={styles.DropdownIcon} />}
          value={value || undefined}
          {...rest}
        />
      </div>
    );
  }
}
