import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Dropdown from '../Dropdown/index';
import Icon from '../Icon/index';
import FilterItem from './FilterItem';
import { RawButton as Button } from '../Button/index';
import Text from '../Text/index';
import styles from './Filter.scss';

export default function Filter({
  filter,
  config,
  onChange,
  onReset,
  hasConfirm,
  hasActiveFilter,
  dropdownProps,
}) {
  const [visible, setVisible] = useState(false);
  const [filterState, setFilterState] = useState(filter);

  // Reset filter on change.
  useEffect(() => {
    setFilterState(filter);
    setVisible(false);
  }, [filter]);

  const filterKeys = Object.keys(config);
  return (
    <Dropdown
      visible={visible}
      renderTrigger={() => (
        <div
          className={classnames(styles.Trigger, {
            [styles.Active]: visible || hasActiveFilter,
          })}
          onClick={() => setVisible(!visible)}
          role="button"
          tabIndex="0"
        >
          <span className={styles.TriggerIcon}>
            <Icon name="filter" className={styles.FilterIcon} />
          </span>
          <Text weight="medium" color="inherit">
            Filter
          </Text>
        </div>
      )}
      {...dropdownProps}
    >
      <div className={styles.Container}>
        {filterKeys.map(key => {
          let filterValue = filterState[key];
          const filterProps = config[key];
          // Normalize the value to an array
          if (filterProps.type === 'checkbox') {
            filterValue = filterValue
              ? Array.isArray(filterValue)
                ? filterValue
                : [filterValue]
              : [];
          }
          return (
            <FilterItem
              key={key}
              {...filterProps}
              name={key}
              value={filterValue}
              onChange={(param, value) => {
                if (!hasConfirm) {
                  setVisible(false);
                  onChange(param, value);
                } else {
                  setFilterState({
                    ...filterState,
                    [param]: value,
                  });
                }
              }}
            />
          );
        })}
        {hasActiveFilter && onReset && (
          <Button type={Button.RESET} onClick={onReset} className={styles.Button}>
            Reset to default filters
          </Button>
        )}
        {hasConfirm && (
          <Button onClick={() => onChange(filterState)} className={styles.Button}>
            Apply filters
          </Button>
        )}
      </div>
    </Dropdown>
  );
}

Filter.propTypes = {
  config: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  hasConfirm: PropTypes.bool,
  hasActiveFilter: PropTypes.bool,
  dropdownProps: PropTypes.object,
};

Filter.defaultProps = {
  dropdownProps: {},
  onReset: null,
  hasConfirm: false,
  hasActiveFilter: false,
};
