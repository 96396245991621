import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon/index';
import Modal from '../Modal/index';
import Button from '../Button/index';
import Tooltip from '../Tooltip/index';
import styles from './ConfirmAction.scss';

export default class ConfirmAction extends PureComponent {
  static propTypes = {
    id: PropTypes.string,
    buttonType: PropTypes.string,
    icon: PropTypes.string,
    isOpen: PropTypes.bool.isRequired,
    heading: PropTypes.any.isRequired,
    actionsClassName: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired,
    onConfirm: PropTypes.func.isRequired,
    confirmButtonText: PropTypes.string,
    confirmTooltip: PropTypes.string,
    customButtons: PropTypes.arrayOf(
      PropTypes.shape({
        component: PropTypes.func,
        type: PropTypes.string,
        icon: PropTypes.string,
        onClick: PropTypes.func,
        disabled: PropTypes.bool,
        content: PropTypes.node,
      }),
    ),
    buttonGroupClassName: PropTypes.string,
    modalClassName: PropTypes.string,
    valid: PropTypes.bool,
    color: PropTypes.oneOf(['red', 'blue', 'green']),
    shouldCloseOnOverlayClick: PropTypes.bool,
    isConfirming: PropTypes.bool,
    wrapperClassName: PropTypes.string,
    contentLabel: PropTypes.string,
    closeButtonText: PropTypes.string,
    closeButtonType: PropTypes.string,
  };

  static defaultProps = {
    id: null,
    icon: null,
    buttonType: 'danger',
    customButtons: [],
    buttonGroupClassName: null,
    modalClassName: null,
    color: 'red',
    confirmButtonText: 'Submit',
    valid: true,
    shouldCloseOnOverlayClick: false,
    isConfirming: false,
    wrapperClassName: null,
    actionsClassName: null,
    contentLabel: 'Delete confirmation',
    confirmTooltip: null,
    closeButtonText: 'Cancel',
    closeButtonType: 'outline',
  };

  getButtonContent(buttonDefinition) {
    const { content, className, component, ...buttonProps } = buttonDefinition;
    const componentProps = {
      ...buttonProps,
      key: content,
      className: classnames(className, styles.Button),
    };

    return !component ? (
      <Button
        {...buttonProps}
        key={content}
        className={classnames(className, styles.Button)}
      >
        {content}
      </Button>
    ) : (
      React.createElement(component, componentProps, content)
    );
  }

  render() {
    const {
      id,
      icon,
      valid,
      color,
      isOpen,
      onClose,
      heading,
      children,
      onConfirm,
      buttonType,
      contentLabel,
      isConfirming,
      customButtons,
      confirmTooltip,
      modalClassName,
      actionsClassName,
      wrapperClassName,
      confirmButtonText,
      buttonGroupClassName,
      shouldCloseOnOverlayClick,
      closeButtonText,
      closeButtonType,
    } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel={contentLabel}
        className={classnames(styles.Wrapper, wrapperClassName)}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        <div
          className={classnames(styles.Modal, modalClassName, {
            [styles['Modal--blue']]: color === 'blue',
            [styles['Modal--green']]: color === 'green',
          })}
        >
          {icon && (
            <div
              className={classnames(styles.ModalIcon, {
                [styles['ModalIcon--blue']]: color === 'blue',
                [styles['ModalIcon--green']]: color === 'green',
              })}
            >
              <div className={styles.ModalIcon__Inner}>
                <Icon name={icon} />
              </div>
            </div>
          )}
          <h3>{heading}</h3>
          <div>{children}</div>
          {customButtons.length > 0 ? (
            <div className={classnames(styles.ButtonGroup, buttonGroupClassName)}>
              {customButtons.map(button => this.getButtonContent(button))}
            </div>
          ) : (
            <div className={classnames(styles.ModalActions, actionsClassName)}>
              <Button
                id={id ? `${id}-cancel-button` : null}
                className="ConfirmAction__Cancel"
                type={closeButtonType}
                onClick={onClose}
              >
                {closeButtonText}
              </Button>
              <Tooltip content={!valid ? confirmTooltip : null}>
                <Button
                  id={id ? `${id}-confirm-button` : null}
                  className="ConfirmAction__Confirm"
                  loading={isConfirming}
                  disabled={!valid}
                  type={buttonType}
                  onClick={onConfirm}
                >
                  {confirmButtonText}
                </Button>
              </Tooltip>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}
