import React, { useEffect, useState } from 'react';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Dropdown from './Dropdown';
import Text from '../Text/index';
import styles from './MultiSelectDropdown.scss';

const MenuItem = props => {
  const { label, onChange, disabled, className, defaultState, ...restProps } = props;

  const [checked, setChecked] = useState(defaultState);

  useEffect(() => {
    if (defaultState !== checked) {
      setChecked(defaultState);
    }
  }, [defaultState]);

  const toggleChecked = e => {
    e.stopPropagation();

    if (disabled) {
      return;
    }

    if (checked) {
      setChecked(false);
      onChange('remove');
    } else {
      setChecked(true);
      onChange('add');
    }
  };

  return (
    <li
      id={label}
      className={classnames(
        disabled ? styles.MenuItem__Disabled : styles.MenuItem__Enabled,
        className,
      )}
    >
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <div
        role="button"
        onClick={toggleChecked}
        className={classnames(styles.Element, { [styles.DisabledElement]: disabled })}
        {...restProps}
      >
        <div className={styles.Icon}>
          <Checkbox checked={checked} disabled={disabled} />
        </div>
        <span
          className={classnames(styles.OptionLabel, {
            [styles.CheckedText]: checked,
          })}
        >
          {label}
        </span>
      </div>
    </li>
  );
};

MenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  defaultState: PropTypes.bool,
};

MenuItem.defaultProps = {
  disabled: false,
  className: '',
  defaultState: true,
};

const MultiSelectDropdown = ({
  groups,
  options,
  onChange,
  buttonElement,
  containerClassName,
  optionClassName,
  menuClassName,
  ...restProps
}) => {
  // Normalize the old interface options into a groups format
  const renderingGroups = options
    ? [
        {
          label: null,
          options,
        },
      ]
    : groups;

  return (
    <div className={classnames(styles.Menu, containerClassName)}>
      <Dropdown renderTrigger={buttonElement} placement="bottomRight" {...restProps}>
        <div className={classnames(styles.MenuList, menuClassName)}>
          {renderingGroups.map(
            (
              { label, renderer: OptionRenderer = MenuItem, options: groupOptions },
              index,
            ) => {
              return (
                // eslint-disable-next-line
                <div key={`${label}_${index}`} className={styles.MenuGroup}>
                  {label && (
                    <Text
                      className={styles.MenuGroup__Label}
                      color="grey"
                      size="sm"
                      weight="medium-bold"
                    >
                      {label}
                    </Text>
                  )}
                  <ul className={styles.DropdownMenu__List}>
                    {groupOptions.map(
                      (option, groupOptionIndex) =>
                        option && (
                          <OptionRenderer
                            // eslint-disable-next-line
                            key={`${option.value}_${groupOptionIndex}`}
                            {...option}
                            optionClassName={optionClassName}
                            onChange={onChange({
                              label,
                              value: option.value,
                            })}
                          />
                        ),
                    )}
                  </ul>
                </div>
              );
            },
          )}
        </div>
      </Dropdown>
    </div>
  );
};

MultiSelectDropdown.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      options: PropTypes.arrayOf(PropTypes.object),
    }),
  ),
  options: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  buttonElement: PropTypes.func.isRequired,
  containerClassName: PropTypes.string,
  optionClassName: PropTypes.string,
  menuClassName: PropTypes.string,
};

MultiSelectDropdown.defaultProps = {
  groups: null,
  options: null,
  containerClassName: null,
  optionClassName: null,
  menuClassName: null,
};

export default MultiSelectDropdown;
