import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Panel.scss';

const Panel = ({ className, headerHeight: height, title, type, children, padding }) => (
  <div className={classnames(styles.Container, styles[`Container--${type}`], className)}>
    <header className={styles.Header} style={{ height }}>
      {title}
    </header>
    <div
      className={classnames(styles.Content, {
        [styles[`Content--padding`]]: padding,
      })}
    >
      {children}
    </div>
  </div>
);

Panel.defaultProps = {
  className: null,
  headerHeight: 30,
  padding: true,
  type: 'default',
};

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  headerHeight: PropTypes.number,
  padding: PropTypes.bool,
  title: PropTypes.any.isRequired,
  type: PropTypes.oneOf(['default', 'info', 'success', 'error']),
};

export default Panel;
