import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Text from '../Text/index';
import styles from './LabeledColumn.scss';

const LabeledColumn = ({
  id,
  className,
  children,
  emptyText,
  flex,
  inline,
  padding,
  title,
  titleClassName,
  titleProps,
}) => (
  <div
    id={id}
    className={classnames(styles.Column, padding && styles[`Column--padding`], className)}
    style={{ flex }}
  >
    <Text
      className={classnames(styles.Column__Title, titleClassName)}
      as="h4"
      color="grey"
      size="xxs"
      weight="medium"
      {...titleProps}
    >
      {title}
    </Text>
    <div
      className={classnames(styles.Column__Inner, {
        [styles.Column__Inline]: inline,
      })}
    >
      {children || (
        <Text size="md" color="grey">
          {emptyText}
        </Text>
      )}
    </div>
  </div>
);

LabeledColumn.defaultProps = {
  id: null,
  className: null,
  children: null,
  emptyText: 'N/A',
  flex: '1 1 auto',
  inline: false,
  padding: true,
  titleClassName: null,
  titleProps: {},
  title: null,
};

LabeledColumn.propTypes = {
  id: PropTypes.any,
  className: PropTypes.string,
  children: PropTypes.node,
  emptyText: PropTypes.string,
  flex: PropTypes.any,
  inline: PropTypes.bool,
  padding: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  titleClassName: PropTypes.string,
  titleProps: PropTypes.object,
};

export default LabeledColumn;
