import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Text from '../../Text/index';
import styles from './ErrorContent.scss';

export const UNSUPPORTED_BROWSER = 'unsupported_browser';

function getErrorContent(error) {
  switch (error) {
    case UNSUPPORTED_BROWSER:
      return (
        <Fragment>
          <Text color="grey" size="xl">
            You’re currently using Internet Explorer 10, an unsupported browser.
          </Text>
          <Text>Here are our supported browsers:</Text>
          <ul className="list-disc mb-4 pl-10">
            <li>
              <a href="https://www.microsoft.com/en-us/download/internet-explorer.aspx">
                Internet Explorer 11+
              </a>
            </li>
            <li>
              <a href="https://www.microsoft.com/en-us/windows/microsoft-edge">Edge</a>
            </li>
            <li>
              <a href="https://www.google.com/chrome/">Chrome</a>
            </li>
            <li>
              <a href="https://www.mozilla.org/en-US/firefox/">Firefox</a>
            </li>
            <li>
              <a href="https://www.apple.com/safari/">Safari</a>
            </li>
          </ul>
        </Fragment>
      );
    default:
      return (
        <Fragment>
          <Text color="grey" size="xl">
            We are unable to display this form.
          </Text>
          <Text size="md">
            A support ticket has been created and we will investigate as soon as possible.
          </Text>
        </Fragment>
      );
  }
}

export default function ErrorContent({ error }) {
  return <div className={styles.ErrorContainer}>{getErrorContent(error)}</div>;
}

ErrorContent.propTypes = {
  error: PropTypes.any.isRequired,
};
