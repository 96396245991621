import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Divider.scss';

const Divider = ({ className = '', inverse = false, ...rest }) => (
  <hr
    className={classnames(
      styles.Divider,
      {
        [styles['Divider--inverse']]: inverse,
      },
      className,
    )}
    {...rest}
  />
);

Divider.propTypes = {
  className: PropTypes.string,
  inverse: PropTypes.bool,
};

Divider.defaultProps = {
  className: null,
  inverse: false,
};

export default Divider;
