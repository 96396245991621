import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import EditablePDFView from './EditablePDFView';
import DragLayer from './DragLayer';
import styles from './Builder.scss';

const PDFView = forwardRef(({ renderItem, renderDragItemPreview, ...restProps }, ref) => {
  return (
    <div className={styles.PDFSection} ref={ref}>
      <EditablePDFView renderItem={renderItem} {...restProps} />
      <DragLayer renderDragItemPreview={renderDragItemPreview} />
    </div>
  );
});

export default PDFView;

PDFView.propTypes = {
  renderItem: PropTypes.func.isRequired,
  renderDragItemPreview: PropTypes.func,
};

PDFView.defaultProps = {
  renderDragItemPreview: null,
};
