import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Switch, Checkbox } from 'antd';
import styles from './CheckboxField.scss';

export default class CheckboxField extends Component {
  static propTypes = {
    checkboxFirst: PropTypes.bool,
    heading: PropTypes.node,
    description: PropTypes.node,
    readOnly: PropTypes.bool,
    hidden: PropTypes.bool,
    leftLabel: PropTypes.string,
    rightLabel: PropTypes.string,
    className: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    useSwitch: PropTypes.bool,
    noSpace: PropTypes.bool,
  };

  static defaultProps = {
    checkboxFirst: false,
    leftLabel: 'No',
    rightLabel: 'Yes',
    readOnly: false,
    hidden: false,
    className: null,
    checked: false,
    heading: null,
    description: null,
    id: null,
    useSwitch: true,
    noSpace: false,
  };

  onChange = (...args) => {
    if (!this.props.readOnly) {
      this.props.onChange(...args);
    }
  };

  render() {
    const {
      checkboxFirst,
      heading,
      description,
      leftLabel,
      rightLabel,
      checked,
      readOnly,
      hidden,
      className,
      id,
      useSwitch,
      noSpace,
    } = this.props;

    const headingContent = heading && <div className={styles.Heading}>{heading}</div>;
    const descriptionContent = description && (
      <p className={styles.Description}>{description}</p>
    );
    const checkbox =
      !hidden &&
      (useSwitch ? (
        <div className={styles.SwitchWrapper}>
          <Switch
            tabIndex="0"
            checkedChildren={rightLabel}
            unCheckedChildren={leftLabel}
            onChange={this.onChange}
            checked={checked}
            disabled={readOnly}
          />
        </div>
      ) : (
        <div>
          <Checkbox
            tabIndex="0"
            onChange={this.onChange}
            checked={checked}
            disabled={readOnly}
          />
        </div>
      ));

    return (
      <div
        id={id}
        className={classNames(
          className,
          styles.Container,
          noSpace && styles['Container--noSpace'],
        )}
      >
        {checkboxFirst && checkbox}
        {headingContent || descriptionContent ? (
          <div className={styles.HeadingWrapper}>
            {headingContent}
            {descriptionContent}
          </div>
        ) : null}
        {!checkboxFirst && checkbox}
      </div>
    );
  }
}
