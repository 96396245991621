import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import CheckboxField from '../CheckboxField/CheckboxField';
import InfoTooltip from '../InfoTooltip';
import Text from '../Text/index';
import Field, { fieldDisplayDefaultProps, fieldDisplayPropTypes } from './Field';
import styles from './ToggleField.scss';

export const ToggleField = props => {
  const {
    input,
    placeholder,
    placeholderTooltip,
    className,
    renderToggledContent,
    ...restProps
  } = props;

  return (
    <Field {...props} className={classnames(className, styles.TextField)}>
      <div className={styles.ToggleHeader}>
        <div className={styles.ToggleHeader__Label}>
          <Text size="large">{placeholder}</Text>
          {placeholderTooltip && (
            <InfoTooltip color="blue" content={placeholderTooltip} />
          )}
        </div>
        <CheckboxField
          checked={!!input.value}
          {...input}
          {...Field.filterProps(restProps)}
        />
      </div>
      {!!input.value && renderToggledContent && (
        <div className={styles.ToggleContent}>{renderToggledContent()}</div>
      )}
    </Field>
  );
};

ToggleField.propTypes = {
  ...fieldDisplayPropTypes,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }).isRequired,
  renderToggledContent: PropTypes.func,
  placeholder: PropTypes.node,
  placeholderToolTip: PropTypes.element,
};

ToggleField.defaultProps = {
  ...fieldDisplayDefaultProps,
  renderToggledContent: null,
  placeholder: '',
  placeholderToolTip: null,
};

export default ToggleField;
