import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon/index';
import styles from './RichTextField.scss';

export const BLOCK_TYPES = [
  { name: 'paragraph', label: 'Paragraph' },
  { name: 'header-one', label: 'Heading 1' },
  { name: 'header-two', label: 'Heading 2' },
  { name: 'header-three', label: 'Heading 3' },
  { name: 'header-four', label: 'Heading 4' },
];

const STYLE_OPTIONS = BLOCK_TYPES.map(type => ({
  value: type.name,
  label: (
    <span
      className={classnames(
        styles.StyleMenuItem__Label,
        styles[`StyleMenuItem__Label--${type.name}`],
      )}
    >
      {type.label}
    </span>
  ),
}));

export default class Menu extends Component {
  static propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    value: 'paragraph',
  };

  componentDidMount() {
    window.addEventListener('click', this.onHide);
    window.addEventListener('touchstart', this.onHide);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onHide);
    window.removeEventListener('touchstart', this.onHide);
  }

  onHide = e => {
    if (this.container && this.props.isVisible && !this.container.contains(e.target)) {
      this.props.onToggle(false);
    }
  };

  setContainer = element => {
    this.container = element;
  };

  render() {
    const { value, isVisible, onChange, onToggle } = this.props;
    const item = STYLE_OPTIONS.find(option => option.value === value) || STYLE_OPTIONS[0];
    return (
      <div ref={this.setContainer}>
        <div
          tabIndex="0"
          role="button"
          className={styles.StyleMenuTrigger}
          onClick={() => onToggle(!isVisible)}
        >
          {item.label}
          <div
            className={classnames(styles.StyleMenuTriggerIcon, {
              [styles['StyleMenuTriggerIcon--isVisible']]: isVisible,
            })}
          >
            <Icon name="dropdown" />
          </div>
        </div>
        {isVisible ? (
          <div className={styles.StyleMenu}>
            {STYLE_OPTIONS.map(option => (
              <div
                key={option.value}
                tabIndex="0"
                role="button"
                className={styles.StyleMenuItem}
                onClick={() => onChange(option.value)}
              >
                {option.label}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}
