import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactAvatar from 'react-avatar';
import classnames from 'classnames';
import styles from './Avatar.scss';

const sizeMap = {
  small: 15,
  medium: 40,
  large: 60,
  xlarge: 100,
};

/**
 * Component for displaying an avatar
 */
export default class Avatar extends PureComponent {
  static propTypes = {
    /** Whether or not the avatar is round or square */
    round: PropTypes.bool,

    /** The source of the image */
    src: PropTypes.string,

    /** The size of the image */
    size: PropTypes.oneOfType([
      PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
      PropTypes.number,
    ]),

    /** The name of the user represented by the avatar */
    name: PropTypes.string,

    /** A custom class for the avatar component */
    className: PropTypes.string,

    /** For text based avatars the size of the text as a fragment of size (size / textSizeRatio) */
    textSizeRatio: PropTypes.number,
  };

  static defaultProps = {
    src: null,
    size: 'medium',
    name: null,
    round: true,
    className: null,
    textSizeRatio: 2.5,
  };

  render() {
    const { src, name, size, className, round, textSizeRatio, ...restProps } = this.props;
    const displaySize = sizeMap[size] || size;
    return (
      <div
        className={classnames(styles.Container, className)}
        style={displaySize ? { lineHeight: `${displaySize}px` } : {}}
      >
        <ReactAvatar
          src={src}
          size={displaySize}
          name={name}
          round={round}
          textSizeRatio={textSizeRatio}
          {...restProps}
        />
      </div>
    );
  }
}
