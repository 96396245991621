import React, { createContext } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

export const SearchParamContext = createContext({
  history: {},
  location: {},
  match: {},
});

/**
 * Wrapper around react-router-dom `Route` to enable a search param integration
 * @param children
 * @param Route
 * @returns {*}
 * @constructor
 */
const SearchParamProvider = ({ children }) => (
  <Route>
    {routeProps => (
      <SearchParamContext.Provider value={routeProps}>
        {children}
      </SearchParamContext.Provider>
    )}
  </Route>
);

SearchParamProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SearchParamProvider;
