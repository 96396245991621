import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button as AntdButton } from 'antd'; // eslint-disable-line
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';
import Icon from '../Icon/index';
import styles from './Button.scss';

class Button extends Component {
  static DANGER = 'danger';
  static WARNING = 'warning';
  static OUTLINE = 'outline';
  static INVISIBLE = 'invisible';
  static DASHED = 'dashed';
  static UPLOAD = 'upload';
  static RESET = 'reset';
  static PRIMARY = 'primary';
  static CONFIRM = 'confirm';
  static LINK = 'primary';
  static ALTERNATIVE = 'alternative';
  static TOGGLED = 'toggled';
  static AS_LINK = 'as-link';

  getAntdType = () => {
    const { color, type } = this.props;
    if (type) {
      switch (type) {
        case Button.CONFIRM:
        case Button.WARNING:
        case Button.TOGGLED:
        case Button.RESET:
          return Button.PRIMARY;
        case Button.AS_LINK:
        case Button.INVISIBLE:
          return Button.OUTLINE;
        case Button.UPLOAD:
          return Button.DASHED;
        default:
          return type;
      }
    }

    switch (color) {
      case 'blue':
      case 'green':
        return Button.PRIMARY;
      case 'dashed':
        return Button.DASHED;
      case 'red':
        return Button.DANGER;
      case 'outline':
      case 'grey':
      case 'blue-grey':
        return Button.ALTERNATIVE;
      default:
        return null;
    }
  };

  getTypeClassName() {
    const { type } = this.props;
    switch (type) {
      case Button.AS_LINK:
        return styles.AsLinkButton;
      case Button.CONFIRM:
        return styles.ConfirmButton;
      case Button.DANGER:
        return styles.DangerButton;
      case Button.WARNING:
        return styles.WarningButton;
      case Button.INVISIBLE:
        return styles.InvisibleButton;
      case Button.UPLOAD:
        return styles.UploadButton;
      case Button.RESET:
        return styles.ResetButton;
      case Button.TOGGLED:
        return styles.ToggledButton;
      case Button.ALTERNATIVE:
        return styles.AlternativeButton;
      default:
        return null;
    }
  }

  onClick = e => {
    const { to, onClick, history } = this.props;
    if (onClick) onClick(e);
    if (to && history) {
      history.push(to);
    }
  };

  render() {
    const {
      forwardedRef,
      icon,
      iconDir,
      size,
      disabled,
      type,
      onClick,
      children,
      loading,
      className,
      fullWidth,
      ...restProps
    } = this.props;

    // If we don't have a click handler, default to `submit`
    const htmlType = !onClick ? 'submit' : 'button';

    return (
      <AntdButton
        ref={forwardedRef}
        size={size === 'medium' ? 'default' : size}
        loading={loading}
        htmlType={htmlType}
        disabled={disabled}
        onClick={this.onClick}
        type={this.getAntdType()}
        className={classNames(styles.Button, this.getTypeClassName(), className, {
          [styles.FullWidth]: fullWidth,
          [styles.Disabled]: !!disabled,
        })}
        {...omit(restProps, ['match', 'location', 'history', 'staticContext', 'to'])}
      >
        {!loading && !!icon && iconDir === 'left' && (
          <div
            className={classNames(styles.Icon, styles.IconLeft, {
              [styles.Outline]: type === Button.OUTLINE,
              [styles.Invisible]: type === Button.INVISIBLE,
            })}
          >
            <Icon name={icon} />
          </div>
        )}
        {children}
        {!loading && !!icon && iconDir === 'right' && (
          <div
            className={classNames(styles.Icon, styles.IconRight, {
              [styles.Outline]: type === Button.OUTLINE,
              [styles.Invisible]: type === Button.INVISIBLE,
            })}
          >
            <Icon name={icon} />
          </div>
        )}
      </AntdButton>
    );
  }
}

Button.propTypes = {
  forwardedRef: PropTypes.object,

  /** The icon to be displayed along width the button content */
  icon: PropTypes.string,
  iconDir: PropTypes.oneOf(['left', 'right']),

  /** The type of the button */
  type: PropTypes.oneOf([
    Button.DANGER,
    Button.WARNING,
    Button.OUTLINE,
    Button.INVISIBLE,
    Button.DASHED,
    Button.RESET,
    Button.UPLOAD,
    Button.PRIMARY,
    Button.CONFIRM,
    Button.LINK,
    Button.ALTERNATIVE,
    Button.TOGGLED,
    Button.AS_LINK,
  ]),

  /** The size of the button */
  size: PropTypes.oneOf(['small', 'medium', 'large']),

  /** Click event for when the button is clicked */
  onClick: PropTypes.func,

  /** The route of where the button redirects the user to */
  to: PropTypes.string,

  /** Whether or not the button is disabled */
  disabled: PropTypes.bool,

  /** The content of the button */
  children: PropTypes.node.isRequired,

  /** The router history */
  history: PropTypes.object,

  /** The class name to be applied to the button element */
  className: PropTypes.string,

  /** Whether or not to display a loading spinning inside the button content */
  loading: PropTypes.bool,

  /** The color of the button */
  color: PropTypes.oneOf([
    'red',
    'blue',
    'green',
    'grey',
    'outline',
    'dashed',
    'blue-grey',
  ]),

  fullWidth: PropTypes.bool,
};

Button.defaultProps = {
  forwardedRef: null,
  icon: null,
  iconDir: 'left',
  type: Button.PRIMARY,
  size: 'large',
  to: null,
  color: null,
  disabled: false,
  className: null,
  onClick: null,
  loading: false,
  fullWidth: false,
  history: null,
};

export const RawButton = Button;

export default withRouter(Button);
