import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CheckboxField from './CheckboxField';

export default class CheckboxReduxFormField extends Component {
  static propTypes = {
    input: PropTypes.any.isRequired,
    label: PropTypes.string,
  };

  static defaultProps = {
    label: null,
  };

  render() {
    const { input, label, ...rest } = this.props;
    return (
      <CheckboxField
        heading={label}
        checked={input.checked || input.value}
        onChange={input.onChange}
        {...rest}
      />
    );
  }
}
