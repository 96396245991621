import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Children, cloneElement } from 'react';
import Button from '../Button';
import Dropdown from '../Dropdown';
import Icon, { names } from '../Icon';
import Text from '../Text';
import styles from './Menu.scss';

export default function Menu({ children }) {
  const menuButton = Children.toArray(children).find(child => child.type === MenuButton);
  const menuItems = Children.toArray(children).filter(child => child.type === MenuItem);

  if (!menuButton) {
    throw new Error('Menu Button not found!');
  }

  const menuButtonChildren = Children.toArray(menuButton.props.children);

  return (
    <Dropdown
      trigger={['click']}
      renderTrigger={isOpen =>
        cloneElement(menuButton, {
          children: (
            <>
              {menuButtonChildren}
              {menuButton.props.showArrow && (
                <Icon
                  data-testid="menu-button-icon"
                  name="dropdown"
                  className={classNames(
                    styles.ButtonIcon,
                    menuButton.props.iconClassName,
                    {
                      [styles['Active']]: isOpen,
                      [styles['HasChildren']]: !!menuButtonChildren.length,
                    },
                  )}
                />
              )}
            </>
          ),
        })
      }
    >
      <ul className={styles.Dropdown}>{menuItems}</ul>
    </Dropdown>
  );
}

// showArrow and iconClassName are used in the main `Menu`
// component when cloning the component.
function MenuButton({ showArrow, iconClassName, ...props }) {
  return <Button {...props} key="menu-button" />;
}

MenuButton.defaultProps = {
  showArrow: true,
};

MenuButton.propTypes = {
  showArrow: PropTypes.bool,
  iconClassName: PropTypes.string,
};

// Mimic the button types so we don't have to import button when using this component
MenuButton.DANGER = Button.DANGER;
MenuButton.WARNING = Button.WARNING;
MenuButton.OUTLINE = Button.OUTLINE;
MenuButton.INVISIBLE = Button.INVISIBLE;
MenuButton.DASHED = Button.DASHED;
MenuButton.UPLOAD = Button.UPLOAD;
MenuButton.RESET = Button.RESET;
MenuButton.PRIMARY = Button.PRIMARY;
MenuButton.CONFIRM = Button.CONFIRM;
MenuButton.LINK = Button.LINK;
MenuButton.ALTERNATIVE = Button.ALTERNATIVE;
MenuButton.TOGGLED = Button.TOGGLED;
MenuButton.AS_LINK = Button.AS_LINK;

function MenuItem({ children, icon, ...props }) {
  return (
    <li
      {...props}
      tabIndex={0}
      aria-selected={false}
      role="option"
      className={styles.DropdownItem}
    >
      {!!icon && (
        <div className={styles.DropdownItemIcon}>
          <Icon name={icon} role="img" />
        </div>
      )}
      <Text color="dark-charcoal" className={styles.DropdownItemText}>
        {children}
      </Text>
    </li>
  );
}

MenuItem.propTypes = {
  icon: PropTypes.oneOf(names),
};

Menu.Button = MenuButton;
Menu.Item = MenuItem;
