import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './CheckboxSetField.scss';

class CheckboxSetField extends Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    input: PropTypes.object.isRequired,
  };

  static defaultProps = {};

  onClick = option => {
    const {
      input: { onChange },
    } = this.props;
    onChange(option.value);
  };

  render() {
    const {
      options,
      input: { value },
    } = this.props;
    return (
      <div className={styles.CheckboxSetField}>
        {options.map(option => (
          <div
            key={option.value}
            role="button"
            tabIndex="0"
            className={classnames(styles.Option, {
              [styles['Option--Selected']]: option.value === value,
            })}
            onClick={this.onClick.bind(this, option)}
          >
            {option.label}
          </div>
        ))}
      </div>
    );
  }
}

export default CheckboxSetField;
