import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import omit from 'lodash/omit';
import InfoTooltip from '../InfoTooltip';
import Text from '../Text/index';
import Tooltip from '../Tooltip/index';
import FieldError from '../FieldError';
import styles from './Field.scss';

const defaultLabelProps = {
  size: 'medium',
  weight: 'medium',
  color: 'grey',
  as: 'span',
  uppercase: true,
};

export const fieldDisplayPropTypes = {
  containerId: PropTypes.string,
  labelTooltip: PropTypes.string,
  componentLabel: PropTypes.node,
  label: PropTypes.node,
  labelProps: PropTypes.shape({
    size: PropTypes.string,
    color: PropTypes.string,
    as: PropTypes.string,
    heading: PropTypes.bool,
    uppercase: PropTypes.bool,
  }),
  heading: PropTypes.shape({
    className: PropTypes.string,
  }),
  description: PropTypes.node,
  descriptionPosition: PropTypes.oneOf(['above', 'below']),
  descriptionProps: PropTypes.object,
  fieldType: PropTypes.string,
  errorPosition: PropTypes.node,
  errorWeight: PropTypes.oneOf(['thin', 'heavy']),
  validationIcon: PropTypes.any,
  showOptionalTag: PropTypes.bool,
  disabled: PropTypes.bool,
  disabledTooltip: PropTypes.string,
  className: PropTypes.string,
  borderless: PropTypes.bool,
  showRequired: PropTypes.bool,
};

export const fieldDisplayDefaultProps = {
  containerId: null,
  labelTooltip: null,
  componentLabel: null,
  errorPosition: 'bottom',
  errorWeight: 'heavy',
  fieldType: null,
  label: null,
  labelProps: {},
  description: null,
  descriptionPosition: 'above',
  descriptionProps: {},
  validationIcon: false,
  className: null,
  disabled: false,
  disabledTooltip: null,
  showOptionalTag: false,
  borderless: false,
  showRequired: false,
};

class Field extends PureComponent {
  static propTypes = {
    ...fieldDisplayPropTypes,
    meta: PropTypes.any,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    ...fieldDisplayDefaultProps,
    meta: {
      error: null,
      touched: false,
      pristine: true,
    },
  };

  static filterProps(props, otherKeysToOmit = []) {
    return omit(props, [...otherKeysToOmit, ...Object.keys(this.propTypes)]);
  }

  render() {
    const {
      containerId,
      componentLabel,
      label,
      labelTooltip,
      labelProps,
      heading,
      description,
      descriptionPosition,
      descriptionProps,
      errorPosition,
      errorWeight,
      validationIcon,
      meta,
      className,
      showOptionalTag,
      disabled,
      disabledTooltip,
      borderless,
      showRequired,
      fieldType,
      children,
    } = this.props;

    const isTouched = meta.touched;
    const isValid = meta.valid;
    let validationStatus = null;

    if (validationIcon === true) {
      if ((isTouched && isValid === true) || (isTouched === false && isValid === true)) {
        validationStatus = 'success';
      } else if (isTouched === true && isValid === false) {
        validationStatus = 'failure';
      }
    }

    const errorContent = (
      <FieldError
        error={meta && meta.touched && meta.error}
        warning={meta && meta.touched && meta.warning}
        thin={errorWeight === 'thin'}
      />
    );

    return (
      <div
        id={containerId}
        className={classnames(
          styles.Field,
          styles[`Field--${validationStatus}`],
          {
            [styles[`Field--Disabled`]]: disabled,
          },
          className,
        )}
      >
        {(label || showOptionalTag) && (
          <label htmlFor={this.props.id}>
            <span className={classnames(styles.HeadingWrapper, heading?.className)}>
              <span className={styles.Label}>
                <Text {...{ ...defaultLabelProps, ...labelProps }}>
                  {label}
                  {showRequired && <span className={styles.Required}>*</span>}
                </Text>
                {labelTooltip && (
                  <InfoTooltip
                    className={styles.LabelTooltip}
                    color="blue"
                    content={labelTooltip}
                  />
                )}
              </span>
              {showOptionalTag && (
                <span className={styles.HeadingOptional}>Optional</span>
              )}
            </span>
            {description && descriptionPosition === 'above' ? (
              <div className={styles.Description} {...descriptionProps}>
                {description}
              </div>
            ) : null}
          </label>
        )}
        {componentLabel}
        {errorPosition === 'top' && errorContent}
        <div
          className={classnames(styles.InputContainer, {
            [styles.Error]: Boolean(meta && meta.touched && meta.error),
            [styles.Warning]: Boolean(meta && meta.touched && meta.warning),
            [styles['InputContainer--borderless']]: borderless,
            [`Field-${fieldType}`]: !!fieldType,
            [styles.Active]: meta.active,
          })}
        >
          {disabled && disabledTooltip ? (
            <Tooltip content={disabledTooltip}>{children}</Tooltip>
          ) : (
            children
          )}
        </div>
        {description && descriptionPosition === 'below' ? (
          <span className={styles.Description} {...descriptionProps}>
            {description}
          </span>
        ) : null}
        {errorPosition === 'bottom' && errorContent}
      </div>
    );
  }
}

export default Field;
