import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Text from '../Text';

import FieldError from '../FieldError';
import styles from './ColorPickerField.scss';

export const PickerItem = ({ color, value, onClick, disabled }) => (
  <div
    onClick={() => (onClick && !disabled ? onClick(color) : null)}
    className={classnames({
      [styles.PickerItem]: true,
      [styles['PickerItem--Disabled']]: disabled,
      [styles['PickerItem--Active']]: color === value,
    })}
    style={{ backgroundColor: color }}
  >
    {color === value && (
      <svg viewBox="0 0 24 24">
        <polyline className={styles.Tick} points="8 12.38 10.43 15 16 9" />
      </svg>
    )}
  </div>
);

PickerItem.propTypes = {
  disabled: PropTypes.bool,
  color: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

PickerItem.defaultProps = {
  disabled: false,
};

export const ColorPickerField = ({
  label,
  input,
  meta,
  errorPosition,
  disabled,
  showRequired,
}) => {
  const { value, onChange } = input;

  const errorContent = (
    <FieldError
      error={meta && (meta.dirty || meta.submitFailed) && meta.error}
      warning={meta && (meta.dirty || meta.submitFailed) && meta.warning}
    />
  );

  return (
    <div className={styles.ColorPicker}>
      {label && (
        <span className={styles.Label}>
          <Text size="medium" weight="medium" color="grey" as="span" uppercase>
            {label}
            {showRequired && <span className={styles.Required}>*</span>}
          </Text>
        </span>
      )}
      {errorPosition === 'top' && errorContent}
      <div className={styles.Colors}>
        <PickerItem
          value={value}
          color="#3165A8"
          onClick={onChange}
          disabled={disabled}
        />
        <PickerItem
          value={value}
          color="#EA903E"
          onClick={onChange}
          disabled={disabled}
        />
        <PickerItem
          value={value}
          color="#A2BB2E"
          onClick={onChange}
          disabled={disabled}
        />
        <PickerItem
          value={value}
          color="#E77985"
          onClick={onChange}
          disabled={disabled}
        />
        <PickerItem
          value={value}
          color="#77AEF5"
          onClick={onChange}
          disabled={disabled}
        />
        <PickerItem
          value={value}
          color="#797DE7"
          onClick={onChange}
          disabled={disabled}
        />
        <PickerItem
          value={value}
          color="#E594FF"
          onClick={onChange}
          disabled={disabled}
        />
        <PickerItem
          value={value}
          color="#8344E3"
          onClick={onChange}
          disabled={disabled}
        />
        <PickerItem
          value={value}
          color="#3A897E"
          onClick={onChange}
          disabled={disabled}
        />
        <PickerItem
          value={value}
          color="#75797F"
          onClick={onChange}
          disabled={disabled}
        />
      </div>
      {errorPosition === 'bottom' && errorContent}
    </div>
  );
};

ColorPickerField.propTypes = {
  label: PropTypes.any,
  disabled: PropTypes.bool,
  errorPosition: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    dirty: PropTypes.bool,
    initial: PropTypes.any,
    warning: PropTypes.any,
    error: PropTypes.string,
    touched: PropTypes.bool,
    pristine: PropTypes.bool,
    submitFailed: PropTypes.bool,
  }).isRequired,
};

ColorPickerField.defaultProps = {
  label: null,
  disabled: false,
  errorPosition: 'top',
};

export default ColorPickerField;
