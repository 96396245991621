import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Input as AntdInput } from 'antd';
import Icon from '../Icon/index';
import styles from './SearchField.scss';

export default function SearchField({
  value: initialValue,
  onChange,
  debounce,
  className,
  inputClassName,
  iconClassName,
  children,
  placeholder,
  clearable,
  outline,
  ...restProps
}) {
  const [value, setValue] = useState(initialValue);
  const [focused, setFocused] = useState(false);

  const unmounted = useRef(true);
  useEffect(() => {
    if (unmounted.current) {
      unmounted.current = false;
      return;
    }

    if (debounce === 0) {
      onChange(value);
      return;
    }

    const handler = setTimeout(() => {
      onChange(value);
    }, debounce);

    // eslint-disable-next-line
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <div className={classnames(styles.SearchField, className)}>
      <AntdInput
        value={value}
        onChange={({ target: { value: inputValue } }) => setValue(inputValue)}
        className={classnames(styles.Input, inputClassName, {
          [styles.OutlineInput]: outline,
          [styles.Focused]: focused,
        })}
        allowClear={clearable}
        placeholder={placeholder}
        onBlur={() => setFocused(false)}
        onFocus={() => setFocused(true)}
        {...restProps}
      />
      <div
        className={classnames(styles.SearchField__icon, iconClassName, {
          [styles.Focused]: focused,
        })}
      >
        <Icon name="search" />
      </div>
      {children}
    </div>
  );
}

SearchField.propTypes = {
  value: PropTypes.string,
  debounce: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  children: PropTypes.node,
  placeholder: PropTypes.string,
  clearable: PropTypes.bool,
  outline: PropTypes.bool,
};
SearchField.defaultProps = {
  value: '',
  debounce: 0,
  className: null,
  inputClassName: null,
  iconClassName: null,
  children: null,
  placeholder: '',
  clearable: false,
  outline: false,
};
