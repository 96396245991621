import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import Icon from '../Icon/index';
import Text from '../Text/index';
import Tooltip from '../Tooltip/index';
import styles from './Address.scss';

const formatter = {
  line1(address) {
    if (address.line1 && address.line1.length) return address.line1;
    return `${address.lat}, ${address.lng}`;
  },

  cityState(address) {
    return [address.city, address.state].filter(v => !!v).join(', ');
  },

  full(address) {
    if (isEmpty(address)) return 'Unspecified Location';
    if (!address.line1)
      return address.lat && address.lng
        ? `${address.lat}, ${address.lng}`
        : 'Unspecified Location';
    const parts = [address.line1, address.city, address.state].filter(v => !!v);
    return [parts.join(', '), address.zip].filter(v => !!v).join(' ');
  },
};

const Address = ({
  address,
  format,
  className,
  iconClassName,
  locationClassName,
  textColor,
  textSize,
  withTooltip,
}) => {
  const formattedAddress = formatter[format](address);

  const Component = (
    <div className={classnames(styles.Container, className)}>
      <Icon
        name="location_stroke"
        className={classnames(styles.Icon, iconClassName)}
        size="smaller"
      />
      <Text
        color={textColor}
        className={locationClassName}
        size={textSize}
        data-testid="formatted-address"
      >
        {formattedAddress}
      </Text>
    </div>
  );

  return withTooltip ? (
    <Tooltip content={formattedAddress} placement="top">
      {Component}
    </Tooltip>
  ) : (
    Component
  );
};

Address.formatter = formatter;

Address.propTypes = {
  address: PropTypes.object.isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  locationClassName: PropTypes.string,
  format: PropTypes.oneOf(['cityState', 'line1', 'full']),
  textColor: PropTypes.string,
  textSize: PropTypes.string,
  withTooltip: PropTypes.bool,
};

Address.defaultProps = {
  className: null,
  format: 'full',
  iconClassName: null,
  locationClassName: null,
  textColor: 'grey',
  textSize: 'sm',
  withTooltip: false,
};

export default Address;
