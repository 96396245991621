import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from '../IconButton/index';
import styles from './Attachments.scss';

export function filename(path) {
  return path.replace(/^.*(\\|\/|:)/, '').split(/[?#]/)[0];
}

export function extension(path) {
  return filename(path).split('.').pop();
}
export default class Attachment extends Component {
  static propTypes = {
    attachment: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    onDelete: null,
  };

  onDelete = e => {
    const { attachment, onDelete } = this.props;
    e.preventDefault();
    e.stopPropagation();
    onDelete(attachment);
  };

  render() {
    const { attachment, onDelete } = this.props;

    return (
      <a
        target="_blank"
        href={attachment.path}
        className={styles.Attachment}
        rel="noopener noreferrer"
      >
        <div className={styles.AttachmentType}>
          {extension(attachment.attachment_file_name || attachment.path)}
        </div>
        <div className={styles.AttachmentPath}>
          {filename(attachment.attachment_file_name || attachment.path)}
        </div>
        {onDelete && (
          <div className={styles.AttachmentActions}>
            <IconButton icon="delete" onClick={this.onDelete}>
              Delete
            </IconButton>
          </div>
        )}
      </a>
    );
  }
}
