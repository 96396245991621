import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import classnames from 'classnames';
import { RawButton as Button } from '../Button';
import Icon from '../Icon';
import Tooltip from '../Tooltip';
import Field, { fieldDisplayPropTypes, fieldDisplayDefaultProps } from './Field';
import styles from './TextField.scss';

export const TextField = props => {
  const {
    input,
    id,
    type,
    readOnly,
    placeholder,
    disabled,
    canCopy,
    icon,
    customIcon,
    copyDisplayText,
    afterCopy,
    className,
    disallowNegative,
    ...restProps
  } = props;

  const copyText = () => {
    const textField = document.createElement('textarea');
    textField.innerText = input.value;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    afterCopy();
  };

  return (
    <Field
      {...props}
      className={classnames(styles.TextField, className)}
      borderless={type === 'hidden'}
    >
      <Input
        type={type}
        id={id}
        readOnly={readOnly}
        ref={props.inputRef}
        placeholder={placeholder}
        className={classnames(styles.Input, input?.className)}
        disabled={disabled}
        suffix={
          canCopy ? (
            <Tooltip content="Copy to clipboard">
              {copyDisplayText ? (
                <Button
                  className={styles.CopyText}
                  type={Button.OUTLINE}
                  htmlType="button"
                  onClick={copyText}
                >
                  {copyDisplayText}
                </Button>
              ) : (
                <Icon className={styles.CopyIcon} name="copy" onClick={copyText} />
              )}
            </Tooltip>
          ) : null
        }
        {...input}
        onChange={e => {
          const value = e.target.value;
          // Avoid numerics from potentially going negative
          if (disallowNegative && type === 'number') {
            const processedValue =
              Math.sign(value) > 0 ? value : value === '0' ? '0' : '';
            e.target.value = processedValue;
            input.onChange(e, processedValue);
          } else {
            input.onChange(e, value);
          }
        }}
        {...Field.filterProps(restProps, ['afterCopy'])}
      />
    </Field>
  );
};

TextField.propTypes = {
  ...fieldDisplayPropTypes,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }).isRequired,
  type: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.node,
  canCopy: PropTypes.bool,
  afterCopy: PropTypes.func,
  icon: PropTypes.string,
  customIcon: PropTypes.node,
};

TextField.defaultProps = {
  ...fieldDisplayDefaultProps,
  type: 'text',
  readOnly: false,
  disabled: false,
  canCopy: false,
  placeholder: '',
  afterCopy: () => {},
  icon: null,
  customIcon: null,
};

export default TextField;
