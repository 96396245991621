import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import noop from 'lodash/noop';
import MarkdownEditor from '../MarkdownEditor/';
import Field, { fieldDisplayPropTypes, fieldDisplayDefaultProps } from './Field';
import styles from './MarkdownEditorField.scss';

export const MarkdownEditorField = props => {
  const { input, id, disabled, className, options } = props;
  const isReadOnly = !!options.readOnly;
  return (
    <Field
      {...props}
      className={classnames(className, styles.MarkdownEditorField)}
      borderless={true}
    >
      <MarkdownEditor
        id={id}
        className={classnames(styles.MarkdownEditor, input.className)}
        disabled={disabled}
        options={{
          status: false,
          minHeight: '130px',
          tabIndex: 0,
          ...options,
        }}
        value={input.value}
        onChange={!isReadOnly ? input.onChange : noop}
      />
    </Field>
  );
};

MarkdownEditorField.propTypes = {
  ...fieldDisplayPropTypes,
  input: PropTypes.shape({
    value: PropTypes.any,
    onChange: PropTypes.func,
  }).isRequired,
  disabled: PropTypes.bool,
};

MarkdownEditorField.defaultProps = {
  ...fieldDisplayDefaultProps,
  disabled: false,
};

export default MarkdownEditorField;
