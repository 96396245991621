import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Text from '../Text/index';
import styles from './PoweredByCamino.scss';

const PoweredByCamino = ({ inverse, inverseLight, watermark, centered }) => (
  <div
    className={classnames(styles.Container, {
      [styles['Container--watermark']]: watermark,
      [styles['Container--centered']]: centered,
      [styles['Container--inverse']]: inverse,
      [styles['Container--inverse-light']]: inverseLight,
    })}
  >
    <div className={styles.Icon}>
      <svg viewBox="0 0 63 90">
        <polygon points="31.49 18.01 31.49 36 63 17.99 62.98 0 31.49 18.01" />
        <polygon points="31.49 54 31.49 71.99 63 54 62.98 36 31.49 54" />
        <polygon points="31.49 36 0 17.99 0 36 31.49 54 31.49 36" />
        <polygon points="0 71.99 31.49 90 31.49 71.99 0 54 0 71.99" />
      </svg>
    </div>
    <Text className={styles.Text} size="xs">
      Powered by Camino
    </Text>
  </div>
);

PoweredByCamino.propTypes = {
  inverse: PropTypes.bool,
  inverseLight: PropTypes.bool,
  watermark: PropTypes.bool,
  centered: PropTypes.bool,
};

PoweredByCamino.defaultProps = {
  inverse: false,
  inverseLight: false,
  watermark: true,
  centered: false,
};

export default PoweredByCamino;
