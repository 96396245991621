import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Attachments.scss';
import Attachment from './Attachment';

export default class Attachments extends Component {
  static propTypes = {
    attachments: PropTypes.array,
    onDelete: PropTypes.func,
    onFetchAttachments: PropTypes.func,
  };

  static defaultProps = {
    onDelete: null,
    onFetchAttachments: null,
  };

  static defaultProps = {
    attachments: [],
  };

  onDelete = async attachment => {
    await this.props.onDelete(attachment);
    await this.props.onFetchAttachments();
  };

  render() {
    const { attachments, onDelete } = this.props;
    return (
      <div className={styles.Attachments}>
        {attachments.map((attachment, index) => (
          <Attachment
            key={index}
            attachment={attachment}
            onDelete={onDelete && this.onDelete}
          />
        ))}
      </div>
    );
  }
}
