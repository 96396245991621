import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Note.scss';

const Note = ({ className, noteClassName, fullWidth, note }) => (
  <div
    className={classnames(
      styles.Note__Block,
      {
        [styles.fullWidth]: fullWidth,
      },
      className,
    )}
  >
    <blockquote className={classnames(styles.Note, noteClassName)}>{note}</blockquote>
  </div>
);

Note.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  note: PropTypes.node,
  noteClassName: PropTypes.string,
};

Note.defaultProps = {
  className: null,
  fullWidth: false,
  note: 'N/A',
  noteClassName: null,
};

export default Note;
