import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';

export default function ProgressBar({ percent, strokeColor = '#80a845' }) {
  return <Progress percent={percent} strokeColor={strokeColor} showInfo={false} />;
}

ProgressBar.propTypes = {
  percent: PropTypes.number.isRequired,
};
