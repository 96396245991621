import React, { Component } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import range from 'lodash/range';
import Icon from '../Icon/index';
import styles from './Paginator.scss';

export default class Paginator extends Component {
  static propTypes = {
    paginationMeta: PropTypes.object.isRequired,
    onChangePage: PropTypes.func.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: null,
  };

  setPage(page) {
    const pager = this.getPager(page);

    if (page < 1 || page > pager.totalPages) return;

    this.props.onChangePage(page);
  }

  onChangePage = updated => {
    const { current_page, items_per_page } = this.props.paginationMeta;

    this.props.onChangePage({
      current_page,
      items_per_page,
      ...updated,
    });
  };

  firstPage = () => {
    this.setPage(1);
  };

  lastPage = () => {
    const { current_page } = this.props.paginationMeta;
    const pager = this.getPager(current_page);
    this.setPage(pager.totalPages);
  };

  previousPage = () => {
    const { current_page } = this.props.paginationMeta;
    const pager = this.getPager(current_page);
    this.setPage(pager.currentPage - 1);
  };

  nextPage = () => {
    const { current_page } = this.props.paginationMeta;
    const pager = this.getPager(current_page);
    this.setPage(pager.currentPage + 1);
  };

  getPager(currentPage) {
    const { total_pages, items_per_page, total_count } = this.props.paginationMeta;

    let startPage;
    let endPage;
    if (total_pages <= 5) {
      startPage = 1;
      endPage = total_pages;
    } else if (currentPage <= 2) {
      startPage = 1;
      endPage = 5;
    } else if (currentPage + 2 >= total_pages) {
      startPage = total_pages - 4;
      endPage = total_pages;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }

    const startItem = total_count === 0 ? 0 : (currentPage - 1) * items_per_page + 1;

    return {
      currentPage,
      pageSize: items_per_page,
      totalPages: total_pages,
      startPage,
      endPage,
      pages: range(startPage, endPage + 1),
      startItem,
      endItem: Math.min(startItem + items_per_page - 1, total_count),
    };
  }

  render() {
    const { current_page } = this.props.paginationMeta;
    const pager = this.getPager(current_page);

    if (!pager.pages || pager.pages.length < 1) {
      return null;
    }

    return (
      <div className={classnames(styles.Wrapper, this.props.className)}>
        <div className={styles.Pages}>
          <div className={styles.PagesText}>
            Showing {pager.startItem}-{pager.endItem} of{' '}
            {this.props.paginationMeta.total_count}
          </div>
        </div>
        <ul className={styles.Pagination}>
          <li
            className={
              pager.currentPage === 1
                ? styles['PaginationItem--Disabled']
                : styles.PaginationItem
            }
          >
            <button className={styles.Link} onClick={this.firstPage}>
              <div className={styles.Icon}>
                <Icon name="caret_first" />
              </div>
            </button>
          </li>
          <li
            className={
              pager.currentPage === 1
                ? styles['PaginationItem--Disabled']
                : styles.PaginationItem
            }
          >
            <button className={styles.Link} onClick={this.previousPage}>
              <div className={styles.Icon}>
                <Icon name="caret_left" />
              </div>
            </button>
          </li>
          {pager.pages.map(page => (
            <li key={page} className={styles.PaginationItem}>
              <button
                className={
                  pager.currentPage === page ? styles['Link--Active'] : styles.Link
                }
                onClick={() => this.setPage(page)}
              >
                {page}
              </button>
            </li>
          ))}
          <li
            className={
              pager.currentPage === pager.totalPages
                ? styles['PaginationItem--Disabled']
                : styles.PaginationItem
            }
          >
            <button className={styles.Link} onClick={this.nextPage}>
              <div className={styles.Icon}>
                <Icon name="caret_right" />
              </div>
            </button>
          </li>
          <li
            className={
              pager.currentPage === pager.totalPages
                ? styles['PaginationItem--Disabled']
                : styles.PaginationItem
            }
          >
            <button className={styles.Link} onClick={this.lastPage}>
              <div className={styles.Icon}>
                <Icon name="caret_last" />
              </div>
            </button>
          </li>
        </ul>
      </div>
    );
  }
}
