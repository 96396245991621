import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Logger from './Logger';
import 'maplibre-gl/dist/maplibre-gl.css';

Logger.bootstrap('geo_frontend', {
    replay: true
  });

ReactDOM.render(<App />, document.getElementById('root'));
