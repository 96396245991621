import React, { Component } from 'react';
import { DragSource } from 'react-dnd';
import PropTypes from 'prop-types';
import { getEmptyImage } from 'react-dnd-html5-backend';
import bowser from 'bowser';
import styles from './Enhancers.scss';

const fieldSource = {
  beginDrag(props, monitor, component) {
    const { defaultItemValues, ...restProps } = props;
    const { delta } = component;
    return {
      ...restProps,
      ...(defaultItemValues || {}),
      delta,
    };
  },
};

export const FieldDragSourceEnhancer = DragSource(
  'Field',
  fieldSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }),
);

export const DragItemSourceEnhancer = OriginalComponent =>
  FieldDragSourceEnhancer(
    class Draggable extends Component {
      static propTypes = {
        top: PropTypes.number,
        left: PropTypes.number,
        hideSourceOnDrag: PropTypes.bool,
        isDragging: PropTypes.bool,
        connectDragSource: PropTypes.func.isRequired,
        connectDragPreview: PropTypes.func,
      };

      static defaultProps = {
        top: 0,
        left: 0,
        hideSourceOnDrag: bowser.msie,
        isDragging: false,
        connectDragPreview: null,
      };

      constructor(props) {
        super(props);
        this.delta = { top: 0, left: 0 };
      }

      componentDidMount() {
        const { connectDragPreview } = this.props;
        if (connectDragPreview) {
          // Use empty image as a drag preview so browsers don't draw it
          // and we can draw whatever we want on the custom drag layer instead.
          connectDragPreview(getEmptyImage(), {
            // IE fallback: specify that we'd rather screenshot the node
            // when it already knows it's being dragged so we can hide it with CSS.
            captureDraggingState: true,
          });
        }
      }

      onMouseDown = e => {
        const element = e.target.getBoundingClientRect();
        this.delta = {
          top: e.clientY - element.top,
          left: e.clientX - element.left,
        };
      };

      getStyles() {
        const { left, top, isDragging, hideSourceOnDrag } = this.props;
        const transform = `translate3d(${left}px, ${top}px, 0)`;

        return {
          transform,
          WebkitTransform: transform,
          // IE fallback: hide the real node using CSS when dragging
          // because IE will ignore our custom "empty image" drag preview.
          opacity: isDragging && hideSourceOnDrag ? 0 : 1,
          height: isDragging && hideSourceOnDrag ? 0 : '',
        };
      }

      render() {
        const { hideSourceOnDrag, connectDragSource, isDragging } = this.props;
        if (isDragging && hideSourceOnDrag) {
          return null;
        }

        return connectDragSource(
          <div
            className={styles.DragSource}
            style={this.getStyles()}
            onMouseDown={this.onMouseDown}
            role="button"
            tabIndex="0"
          >
            <OriginalComponent {...this.props} />
          </div>,
        );
      }
    },
  );
