import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon/index';
import styles from './RichTextField.scss';

const ButtonGroup = ({ config: { options }, onChange, currentState }) => (
  <div className={styles.ButtonGroup}>
    {options.map(option => (
      <div
        key={option}
        alt={option}
        className={classnames(styles.ButtonGroupItem, {
          [styles['ButtonGroupItem--active']]: !!currentState[option],
        })}
        onClick={() => onChange(option)}
        role="button"
        tabIndex="0"
      >
        <Icon name={option} />
      </div>
    ))}
  </div>
);

ButtonGroup.propTypes = {
  config: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  currentState: PropTypes.object.isRequired,
};

export default ButtonGroup;
