import thunk from 'redux-thunk';
import { compose, combineReducers, createStore, applyMiddleware } from 'redux';
import * as reducers from './reducers';

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

export const storeCreator = (initialState = {}) => {
  return createStore(
    combineReducers(reducers),
    initialState,
    composeEnhancers(applyMiddleware(thunk)),
  );
};

export const store = createStore(
  combineReducers(reducers),
  composeEnhancers(applyMiddleware(thunk)),
);
