import { v4 as uuidv4 } from 'uuid';

export { reducer as form } from 'redux-form';

const initialLayersState = {
  organizationLayers: [],
  layer: {},
  layerVersions: [],
};

export const layersState = (state = initialLayersState, action) => {
  switch (action.type) {
    case 'SET_ORGANIZATION_LAYERS':
      return { ...state, organizationLayers: action.payload };
    case 'SET_LAYER':
      return { ...state, layer: action.payload };
    case 'SET_LAYER_VERSIONS':
      return { ...state, layerVersions: action.payload };
    default:
      return state;
  }
};

const initialNotificationState = {
  notifications: [],
};

export const notificationState = (state = initialNotificationState, action) => {
  switch (action.type) {
    case 'ADD_NOTIFICATION':
      return {
        notifications: [...state.notifications, { id: uuidv4(), ...action.payload }],
      };
    case 'CLEAR_NOTIFICATION': {
      if (Array.isArray(state.notifications)) {
        const newNotifications = [...state.notifications];
        newNotifications.pop();
        return {
          notifications: newNotifications,
        };
      }
      return initialNotificationState;
    }
    default:
      return state;
  }
};
