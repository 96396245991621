import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Checkbox } from 'antd';
import Text from '../Text/index';
import styles from './ColumnChooser.scss';

const ColumnItem = props => {
  const {
    label,
    onChange,
    disabled,
    className,
    defaultState,
    defaultSelected, // eslint-disable-line
    ...restProps
  } = props;

  const [checked, setChecked] = useState(defaultState);

  const toggleChecked = e => {
    e.stopPropagation();

    if (disabled) {
      return;
    }

    if (checked) {
      setChecked(false);
      if (onChange) {
        onChange('remove');
      }
    } else {
      setChecked(true);
      if (onChange) {
        onChange('add');
      }
    }
  };

  return (
    <div
      id={label}
      className={classnames(
        disabled ? styles.ColumnItem__Disabled : styles.ColumnItem__Enabled,
        className,
      )}
    >
      {/* eslint-disable-next-line jsx-a11y/interactive-supports-focus */}
      <div
        role="button"
        onClick={toggleChecked}
        className={classnames(styles.Element, { [styles.DisabledElement]: disabled })}
        {...restProps}
      >
        <div className={styles.Icon}>
          <Checkbox checked={checked} disabled={disabled} />
        </div>
        <span
          className={classnames(styles.OptionLabel, {
            [styles.CheckedText]: checked,
          })}
        >
          {label}
        </span>
      </div>
    </div>
  );
};

ColumnItem.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  defaultState: PropTypes.bool,
};

ColumnItem.defaultProps = {
  disabled: false,
  className: '',
  defaultState: true,
};

const ColumnChooser = ({ columnGroups, onChange }) => (
  <div className={styles.ColumnChooser}>
    {columnGroups.map(
      (
        { label, renderer: OptionRenderer = ColumnItem, options: groupOptions },
        index,
      ) => {
        return (
          // eslint-disable-next-line
          <div key={`${label}_${index}`} className={styles.Group}>
            {label && (
              <Text
                className={styles.Group__Label}
                color="grey"
                size="xs"
                weight="medium"
              >
                {label}
              </Text>
            )}
            <div className={styles.List}>
              {groupOptions.map(
                (option, groupOptionIndex) =>
                  option && (
                    <OptionRenderer
                      // eslint-disable-next-line
                      key={`${option.value}_${groupOptionIndex}`}
                      {...option}
                      onChange={onChange(option.value)}
                    />
                  ),
              )}
            </div>
          </div>
        );
      },
    )}
  </div>
);

ColumnChooser.propTypes = {
  columnGroups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ColumnChooser;
