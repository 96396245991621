import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Breadcrumb as AntdBreadcrumb } from 'antd'; // eslint-disable-line
import Icon from '../Icon/index';
import Text from '../Text/index';
import styles from './Breadcrumb.scss';

const Breadcrumb = ({ items, onClick }) => (
  <AntdBreadcrumb separator={<Icon className={styles.Separator} name="caret_right" />}>
    {items.map((item, index) => {
      const isLast = index === items.length - 1;
      const isClickable = onClick && !isLast;
      return (
        <AntdBreadcrumb.Item key={item.id}>
          <div
            className={classnames(styles.BreadcrumbItem, {
              [styles['BreadcrumbItem--last']]: isLast,
              [styles['BreadcrumbItem--clickable']]: isClickable,
            })}
            {...(isClickable && {
              onClick: () => onClick(item.id),
              role: 'button',
              tabIndex: '0',
            })}
          >
            <Text className={styles.Text} heading>
              {item.name}
            </Text>
          </div>
        </AntdBreadcrumb.Item>
      );
    })}
  </AntdBreadcrumb>
);

Breadcrumb.propTypes = {
  /** The items displayed in the breadcrumbs  */
  items: PropTypes.array,
  /** The event handler for when a breadcrumb is clicked */
  onClick: PropTypes.func,
};

Breadcrumb.defaultProps = {
  items: [],
  onClick: null,
};

export default Breadcrumb;
