import PropTypes from 'prop-types';
import { RichUtils } from 'draft-js';
import React, { Component } from 'react';
import { getSelectedBlocksType } from 'draftjs-utils';

import Menu from './StyleMenu';
import styles from './RichTextField.scss';

export default class StyleActions extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
    disabled: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    onChange: undefined,
    editorState: undefined,
  };

  state = {
    isVisible: false,
    value: 'paragraph',
  };

  componentDidMount() {
    if (this.props.editorState) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        value: getSelectedBlocksType(this.props.editorState),
      });
    }
  }

  componentWillReceiveProps(props) {
    if (props.editorState && this.props.editorState !== props.editorState) {
      this.setState({
        value: getSelectedBlocksType(props.editorState),
      });
    }
  }

  onChange = value => {
    const { editorState, onChange } = this.props;
    const newState = RichUtils.toggleBlockType(editorState, value);
    if (newState) {
      this.setState({ value, isVisible: false }, () => onChange(newState));
    }
  };

  onToggle = isVisible => {
    const { disabled } = this.props;
    if (!disabled) {
      this.setState({ isVisible });
    }
  };

  render() {
    const { isVisible, value } = this.state;
    return (
      <div className={styles.ButtonGroup}>
        <div className={styles.StyleSelector}>
          <Menu
            value={value}
            isVisible={isVisible}
            onToggle={this.onToggle}
            onChange={this.onChange}
          />
        </div>
      </div>
    );
  }
}
