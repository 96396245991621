import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../Icon/index';
import styles from './CircleIcon.scss';

const CircleIcon = ({ className, type, noPadding, iconClassName, ...props }) => (
  <div
    className={classnames(
      styles.Icon,
      styles[`Type--${type}`],
      {
        [styles.noPadding]: noPadding,
      },
      className,
    )}
  >
    <div className={classnames(styles.Icon__Inner, iconClassName)}>
      <Icon {...props} />
    </div>
  </div>
);

CircleIcon.defaultProps = {
  className: null,
  type: 'primary',
  noPadding: false,
  iconClassName: null,
};

CircleIcon.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['primary', 'error', 'success', 'default', 'inherit']),
  noPadding: PropTypes.bool,
  iconClassName: PropTypes.string,
};

export default CircleIcon;
