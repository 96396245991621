import React from 'react';
import PropTypes from 'prop-types';
import ReactInputMask from 'react-input-mask';
import classnames from 'classnames';
import { Input } from 'antd';
import Field, { fieldDisplayPropTypes, fieldDisplayDefaultProps } from './Field';
import styles from './MaskInputField.scss';

export const MaskInputField = props => {
  const { input, meta, ...restProps } = props;
  return (
    <Field {...props}>
      <ReactInputMask
        {...input}
        disabled={false}
        readOnly={false}
        {...Field.filterProps(restProps)}
      >
        {inputProps => {
          return (
            <Input
              {...inputProps}
              className={classnames(
                styles.Input,
                {
                  [styles.hasError]: meta && meta.touched && meta.error,
                  [styles.Disabled]: props.disabled,
                },
                inputProps.className,
              )}
            />
          );
        }}
      </ReactInputMask>
    </Field>
  );
};

MaskInputField.propTypes = {
  ...fieldDisplayPropTypes,
  input: PropTypes.shape({
    value: PropTypes.string,
    onChange: PropTypes.func,
  }).isRequired,
};

MaskInputField.defaultProps = fieldDisplayDefaultProps;

export default MaskInputField;
