import accepts from 'attr-accept';
import { default as _isEmail } from 'validator/lib/isEmail';

export const isEmail = email => _isEmail(email || '');

export const ZIP_REGEX = /(^[0-9]{5}(?:(-)?[0-9]{4})?$)|(^(?!.*[DFIOQU])[A-VXY][0-9][A-Z](\s)?[0-9][A-Z][0-9]$)/;

export const URL_REGEX = /\b(https?|ftp|file):\/\/[\-A-Za-z0-9+&@#\/%?=~_|!:,.;]*[\-A-Za-z0-9+&@#\/%=~_|]/; // eslint-disable-line

export const meetsLengthRequirement = input => input.length >= 10;
export const containsDigit = input => /\d/i.test(input);
export const containsTwoDigits = input => /\d\D*\d/i.test(input);
export const containsTwoLetters = input => /[a-zA-Z].*[a-zA-Z]/i.test(input);
export const containsSpecialCharacter = input => /[\W_]/i.test(input);
export const containsSpeciaCharacterNotUnderscoreNotSpace = input =>
  /(?=\S)[\W]/i.test(input);
export const caseInsensitiveEquality = (a, b) => a.toLowerCase() === b.toLowerCase();

export const validateEditUserForm = values => {
  const errors = {};

  if (!values.first_name) errors.first_name = 'Required';
  if (!values.last_name) errors.last_name = 'Required';
  if (!values.access_level) errors.access_level = 'Required';
  if (!values.group_id) errors.group_id = 'Required';

  if (!values.email) {
    errors.email = 'Required';
  } else if (values.email && !isEmail(values.email.trim())) {
    errors.email =
      'Invalid email address. Please double check your email address and try again.';
  }

  if (values.password && !values.password_confirmation) {
    errors.password_confirmation = 'Required';
  } else if (values.password_confirmation !== values.password) {
    errors.password_confirmation = 'Passwords do not match.';
  }

  return errors;
};

export const validateUserForm = values => {
  const errors = validateEditUserForm(values);

  if (!values.password) {
    errors.password = 'Required';
  } else if (!meetsLengthRequirement(values.password)) {
    errors.password = 'Password must be at least 10 characters long';
  } else if (/\s/i.test(values.password)) {
    errors.password = 'Password cannot have a space';
  } else if (!containsTwoDigits(values.password)) {
    errors.password = 'Password must contain at least two digits';
  } else if (!containsTwoLetters(values.password)) {
    errors.password = 'Password must contain at least two letters';
  } else if (!containsSpecialCharacter(values.password)) {
    errors.password = 'Password must contain at least one special character';
  }

  return errors;
};

export const fileMatchSize = (file, maxSize, minSize) => {
  return file && file.size <= maxSize && file.size >= minSize;
};

export const fileAccepted = (file, accept) =>
  file.type === 'application/x-moz-file' || accepts(file, accept);
