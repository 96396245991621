import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import classNames from 'classnames';
import Icon from '../Icon/index';
import styles from './Modal.scss';

export default class Modal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    contentLabel: PropTypes.string.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    shouldCloseOnOverlayClick: PropTypes.bool,
    headerClassName: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    className: null,
    contentClassName: null,
    shouldCloseOnOverlayClick: true,
    headerClassName: null,
  };

  render() {
    const {
      isOpen,
      contentLabel,
      onRequestClose,
      children,
      className,
      contentClassName,
      shouldCloseOnOverlayClick,
      headerClassName,
    } = this.props;

    return (
      <ReactModal
        closeTimeoutMS={300}
        isOpen={isOpen}
        contentLabel={contentLabel}
        onRequestClose={onRequestClose}
        className={classNames(styles.Base, className)}
        overlayClassName={{
          base: styles.Overlay,
          afterOpen: styles['Overlay--after'],
          beforeClose: styles['Overlay--before'],
        }}
        ariaHideApp={false}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        <div className={classNames(styles.Header, headerClassName)}>
          <button onClick={this.props.onRequestClose}>
            <Icon name="close_menu" />
          </button>
        </div>
        <div className={classNames(styles.Content, contentClassName)}>{children}</div>
      </ReactModal>
    );
  }
}
