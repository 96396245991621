import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragLayer } from 'react-dnd';
import styles from './DragLayer.scss';

// Includes some optimizations noted here: https://github.com/react-dnd/react-dnd/issues/592#issuecomment-399287474
// Relying on the suggested implementation noted in the docs lead to a large amount of renders
// that made things look pretty laggy.

let subscribedToOffsetChange = false;
let unsubscribeFromOffsetChange = null;
let dragPreviewRef = null;

const onOffsetChange = monitor => () => {
  if (!dragPreviewRef) return;

  const offset = monitor.getSourceClientOffset();
  if (!offset) return;
  const item = monitor.getItem();
  const delta = (item && item.delta) || { top: 0, left: 0 };
  const top = offset.y + delta.top;
  const left = offset.x + delta.left;

  const transform = `translate(${left}px, ${top}px)`;
  dragPreviewRef.style.transform = transform;
  dragPreviewRef.style['-webkit-transform'] = transform;
};

class CustomDragLayer extends Component {
  static propTypes = {
    item: PropTypes.object,
    itemType: PropTypes.string,
    renderDragItemPreview: PropTypes.func.isRequired,
    isDragging: PropTypes.bool,
  };
  static defaultProps = {
    item: null,
    itemType: null,
    isDragging: false,
  };

  componentDidUpdate() {
    dragPreviewRef = this.rootNode;
  }

  componentWillUnmount() {
    if (unsubscribeFromOffsetChange) {
      unsubscribeFromOffsetChange();
      subscribedToOffsetChange = false;
    }
  }

  renderItem = () => {
    const { item, itemType, renderDragItemPreview } = this.props;
    switch (itemType) {
      case 'Field': {
        return renderDragItemPreview(item);
      }
      default:
        return null;
    }
  };
  render() {
    const { isDragging } = this.props;

    if (!isDragging) {
      return null;
    }
    return (
      <div
        className={styles.Layer}
        ref={ref => {
          this.rootNode = ref;
        }}
      >
        {this.renderItem()}
      </div>
    );
  }
}

export default DragLayer(monitor => {
  if (!subscribedToOffsetChange) {
    unsubscribeFromOffsetChange = monitor.subscribeToOffsetChange(
      onOffsetChange(monitor),
    );
    subscribedToOffsetChange = true;
  }

  return {
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    isDragging: monitor.isDragging(),
  };
})(CustomDragLayer);
