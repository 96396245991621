import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Spinner.scss';

const Spinner = ({ className, containerClassName, fullView, size, inverse }) => {
  const baseSpinner = (
    <div
      className={classnames(
        styles[`Spinner--${size}`],
        inverse && styles['Spinner--inverse'],
        className,
      )}
      role="alert"
      aria-busy="true"
      aria-live="polite"
    >
      <svg width="38" height="38" viewBox="0 0 38 38">
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)" strokeWidth="2">
            <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
    </div>
  );

  if (!fullView) {
    return baseSpinner;
  }

  return (
    <div className={classnames(styles.FullViewContainer, containerClassName)}>
      {baseSpinner}
    </div>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  fullView: PropTypes.bool,
  inverse: PropTypes.bool,
  size: PropTypes.oneOf(['smaller', 'small', 'medium', 'large']),
};

Spinner.defaultProps = {
  className: null,
  containerClassName: null,
  fullView: false,
  size: 'large',
  inverse: false,
};

export default Spinner;
