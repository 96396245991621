import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash.throttle';

export default class Autoscale extends PureComponent {
  static propTypes = {
    children: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.wrapperResizeHandler = throttle(this.updateWidth, 500);
    this.state = { width: null };
  }

  componentDidMount() {
    this.updateWidth();
    window.addEventListener('resize', this.wrapperResizeHandler);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.wrapperResizeHandler);
  }

  updateWidth = () => {
    this.setState({ width: this.wrapperRef.current.getBoundingClientRect().width });
  };

  render() {
    const { children } = this.props;
    const { width } = this.state;

    return (
      <div style={{ width: '100%' }} ref={this.wrapperRef}>
        {children(width)}
      </div>
    );
  }
}
