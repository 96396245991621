import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text/index';
import styles from './TextDivider.scss';

const TextDivider = ({ children }) => (
  <div className={styles.TextDivider}>
    <div className={styles.TextDivider__Seperator} />
    <Text className={styles.TextDivider__Text} color="grey" size="sm">
      {children}
    </Text>
    <div className={styles.TextDivider__Seperator} />
  </div>
);

TextDivider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TextDivider;
